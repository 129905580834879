import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { store } from "react-notifications-component";
import { AiOutlineDelete } from "react-icons/ai";
import Switch from "@material-ui/core/Switch/Switch";

function M_JS_Inschrijving_Bewerk(props) {
    const [item, setItem] = useState({});
    const [checked, setChecked] = useState({
        confirmation: false
    });

    const handleSwitch = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    var link = config.api + "/jobfair/participations/" + props.InschrijvingID;

    function handleChange(e) {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setItem({ ...item, [name]: value });
    }

    useEffect(() => {
        function itemOphalen(getReq) {
            axios
                .get(getReq)
                .then((res) => {
                    //console.log(res.data.confirmation)
                    setChecked({ ...checked, confirmation: res.data.confirmation });
                    console.log(res.data);
                    setItem(res.data);
                })
                .catch((err) => {
                    //console.log(err)
                });
        }

        itemOphalen(link);
        // eslint-disable-next-line
    }, [link]);

    // company name
    // company desc

    function submit(e) {
        e.preventDefault();
        //console.log(values)
        let values = item;
        delete values.link;
        delete values.id;
        delete values.confirmation_date;
        delete values.jobfair;
        delete values.company;
        values["confirmation"] = checked.confirmation;

        if (!values.contact_company_name) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: "Juridische bedrijfsnaam moet ingevuld worden!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
            return;
        }
        if (!!values.zipcode) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: "Postcode moet ingevuld worden!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
            return;
        }

        axios
            .patch(config.api + "/jobfair/participations/" + props.InschrijvingID, values, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(function (response) {
                store.addNotification({
                    title: "Bewerken gelukt!",
                    message: "Het bewerken van de bedrijfs info is gelukt!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
                //props.search();
            })
            .catch(function (error) {
                store.addNotification({
                    title: "Bewerken mislukt!",
                    message: "De pagina wordt herladen!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            });
    }

    function verwijderItem() {
        if (window.confirm("Bevestig het verwijderen van " + item.contact_company_name + ".")) {
            axios
                .delete(config.api + "/jobfair/participations/" + props.InschrijvingID)
                .then(function (response) {
                    props.search();
                })
                .catch((err) => {
                    //console.log(err)
                });
        } else {
        }
    }
    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-85 xl-w-90 m-w-100 mr-v-10">
                <h2 className="t-center c-gray-700-90 mr-v-5">{item.contact_company_name} - info bewerken</h2>
                <form>
                    <label>Aanvaard:</label>
                    <Switch checked={checked.confirmation} onChange={handleSwitch} name="confirmation" />

                    <h2 className="c-gray-700-60 mr-b-5">Facturatie gegevens</h2>
                    <label>Juridische bedrijfsnaam</label>
                    <input value={item.contact_company_name} onChange={handleChange} name="contact_company_name" type="text" className="text-input pd-2 t-8" placeholder="Juridische bedrijfsnaam" />

                    <label>Website</label>
                    <input value={item.contact_website} onChange={handleChange} name="contact_website" type="text" className="text-input pd-2 t-8" placeholder="https://www.google.com" />

                    <label>Facturatie email</label>
                    <input value={item.contact_billingmail} onChange={handleChange} name="contact_billingmail" type="email" className="text-input pd-2 t-8" placeholder="Facturatie email" />

                    <label>BTW nummer</label>
                    <input value={item.contact_btw} onChange={handleChange} name="contact_btw" type="text" className="text-input pd-2 t-8" placeholder="BTW nummer" />

                    <label>Adres gegevens</label>
                    <div className="row">
                        <div className="col-9 s-col-12">
                            <input value={item.contact_street} onChange={handleChange} name="contact_street" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Straat" />
                        </div>
                        <div className="col-3 pd-l-1 s-col-12 s-pd-l-0">
                            <input value={item.contact_number} onChange={handleChange} name="contact_number" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Huisnummer (Bus)" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 s-col-12 s-pd-t-2">
                            <input value={item.contact_city} onChange={handleChange} name="contact_city" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Stad/Gemeente" />
                        </div>
                        <div className="col-2 pd-h-1 s-col-12 s-pd-h-0">
                            <input value={item.contact_zipcode} onChange={handleChange} name="contact_zipcode" type="number" className="text-input pd-2 t-8 mr-b-0" placeholder="Postcode" />
                        </div>
                        <div className="col-3 s-col-12">
                            <input value={item.contact_country} onChange={handleChange} name="contact_country" type="text" className="text-input pd-2 t-8" placeholder="Land" />
                        </div>
                    </div>
                    <h2 className="c-gray-700-60 mr-v-5">Contactpersoon gegevens</h2>

                    <label>Voornaam</label>
                    <input value={item.contact_firstname} onChange={handleChange} name="contact_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />

                    <label>Achternaam</label>
                    <input value={item.contact_lastname} onChange={handleChange} name="contact_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />

                    <label>Email</label>
                    <input value={item.contact_mail} onChange={handleChange} name="contact_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />

                    <label>Telefoonnummer</label>
                    <input value={item.contact_telephonenumber} onChange={handleChange} name="contact_telephonenumber" type="tel" className="text-input pd-2 t-8" placeholder="Telefoonnummer" />

                    <label>Gsmnummer</label>
                    <input value={item.contact_cellphonenumber} onChange={handleChange} name="contact_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="Gsmnummer" />

                    <label>Functie binnen bedrijf</label>
                    <input value={item.contact_person_function} onChange={handleChange} name="contact_person_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <h2 className="c-gray-700-60 mr-v-5">Vertegenwoordigers</h2>
                    <h3 className="c-gray-700-60 mr-v-2">Vertegenwoordiger 1</h3>

                    <label>Voornaam</label>
                    <input value={item.contact_representative1_firstname} onChange={handleChange} name="contact_representative1_firstname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Achternaam</label>
                    <input value={item.contact_representative1_lastname} onChange={handleChange} name="contact_representative1_lastname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Functie</label>
                    <input value={item.contact_representative1_function} onChange={handleChange} name="contact_representative1_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Email</label>
                    <input value={item.contact_representative1_mail} onChange={handleChange} name="contact_representative1_mail" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <h3 className="c-gray-700-60 mr-v-2">Vertegenwoordiger 2</h3>

                    <label>Voornaam</label>
                    <input value={item.contact_representative2_firstname} onChange={handleChange} name="contact_representative2_firstname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Achternaam</label>
                    <input value={item.contact_representative2_lastname} onChange={handleChange} name="contact_representative2_lastname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Functie</label>
                    <input value={item.contact_representative2_function} onChange={handleChange} name="contact_representative2_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Email</label>
                    <input value={item.contact_representative2_mail} onChange={handleChange} name="contact_representative2_mail" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <h3 className="c-gray-700-60 mr-v-2">Vertegenwoordiger 3</h3>

                    <label>Voornaam</label>
                    <input value={item.contact_representative3_firstname} onChange={handleChange} name="contact_representative3_firstname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Achternaam</label>
                    <input value={item.contact_representative3_lastname} onChange={handleChange} name="contact_representative3_lastname" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Functie</label>
                    <input value={item.contact_representative3_function} onChange={handleChange} name="contact_representative3_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Email</label>
                    <input value={item.contact_representative3_mail} onChange={handleChange} name="contact_representative3_mail" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <label>Extra info:</label>
                    <textarea onChange={handleChange} name="contact_extra" placeholder="Extra info..." className="text-input pd-2 t-8" rows="10" value={item.contact_extra}></textarea>

                    <input onClick={submit} type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
                </form>
                <div className="w-100 t-right">
                    <button onClick={verwijderItem} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white">
                        <AiOutlineDelete />
                    </button>
                </div>
            </div>
        );
}

export default M_JS_Inschrijving_Bewerk;
