import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { store } from "react-notifications-component";
function M_JS_Deelnemers_Toevoegen(props) {
    const [addParticipants, setAddParticipants] = useState([]);
    const [bedrijven, setBedrijven] = useState([]);
    const [filteredBedrijven, setFiltererdBedrijven] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios
            .get(config.api + "/participants?year=" + props.year)
            .then((result) => {
                let deelnemers = [];
                for (let deelnemer of result.data) {
                    deelnemers.push(deelnemer.company.id);
                }
                axios.get(config.api + "/companies").then((res) => {
                    let data = res.data;
                    data = data.filter((bedrijf) => bedrijf.company_role !== "base");
                    data = data.sort((a, b) => {
                        return a.company_name.toLowerCase() > b.company_name.toLowerCase();
                    });
                    data = data.filter((bedrijf) => !deelnemers.includes(bedrijf.id));
                    setBedrijven(data);
                    setFiltererdBedrijven(data);
                    setLoading(false);
                });
            })
            .catch((error) => {});
    }, []);

    function voegToe() {
        for (let x of addParticipants) {
            let data = {
                jobfair: props.jobfairId,
                company: x,
                package: "base"
            };
            axios
                .post(config.api + "/participants", data)
                .then((res) => {
                    props.load();
                })
                .catch((err) => {});
        }
        store.addNotification({
            title: "Deelnemers toegevoegd!",
            message: "Deelnemers toegevoegd aan dit Job & Stage Event!",
            type: "default",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000,
                onScreen: true
            }
        });
    }

    function filter(text) {
        setFiltererdBedrijven(bedrijven.filter((bedrijf) => bedrijf.company_name.toLowerCase().includes(text)));
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-90 m-w-100 mr-v-10 c-gray-500">
                <h2>Selecteer de bedrijven die je wilt toevoegen</h2>
                <p className="c-red t-italic">Bedrijven die al toegevoegd zijn, worden niet dubbel toegevoegd</p>
                <p>Meerde selecteren met control of shift</p>
                <input type="text" className="text-input" placeholder="Filter..." onChange={(e) => filter(e.target.value)}></input>
                <select onChange={(e) => setAddParticipants(Array.from(e.target.selectedOptions, (option) => option.value))} name="cars" id="cars" className="text-input" style={{ height: "300px" }} multiple>
                    {!loading ? (
                        filteredBedrijven.map((bedrijf) => {
                            return (
                                <option key={bedrijf.id} value={bedrijf.id} className="t-9 pd-v-2">
                                    {bedrijf.company_name}
                                </option>
                            );
                        })
                    ) : (
                        <option disabled className="t-9 pd-v-2 t-italic">
                            Laden...
                        </option>
                    )}
                </select>
                <button onClick={() => voegToe()} className="button button-m fw-700 c-white b-blue-400">
                    Toevoegen
                </button>
            </div>
        );
}

export default M_JS_Deelnemers_Toevoegen;
