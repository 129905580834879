import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";

function P_Bedrijf() {
    return (
        <div className="Bedrijf">
            <Helmet>
                <title>HR - Bedrijf</title>
            </Helmet>
            <Navigatie type="bedrijven" />
            <header className="header ">
                <div className="header-content w-100 t-center">
                    <h1 className="c-white mr-t-50 m-mr-t-30 t-35 m-t-30 s-t-20 header-title">HERMES RECRUITMENT</h1>
                    <br />
                    <a href="bedrijf/over-ons" className="button button-m header-button mr-t-10">
                        CONTACTEER ONS
                    </a>
                </div>
            </header>
            <main className="main-bedrijf box-shadow">
                <div className="container w-65 m-w-90">
                    <center>
                        <br />
                        <br />
                        <div className="mouse">
                            <div className="roll"></div>
                            <div className="rollshadow"></div>
                        </div>
                        <div className="mr-v-10 t-13 c-gray-700-60 hidden">
                            Inschrijven voor het Job & Stage Event kan{" "}
                            <a href="/j&s/inschrijven" className="button link c-blue-400 fw-700">
                                hier
                            </a>
                        </div>
                    </center>
                    <div className="row pd-t-15 pd-b-10">
                        <div className="col-5 xs-col-12 xs-t-center">
                            <img src="images/bedrijf1.svg" alt="" className="xs-w-60 flip-image" />
                        </div>
                        <div className="col-7 pd-l-15 xs-col-12 xs-pd-l-0">
                            <h1 className="c-gray-500 fw-900 mr-t-5">Diensten</h1>
                            <div className="text-block mr-t-3 c-gray-500">
                                Hermes Recruitment biedt bedrijven de mogelijkheid vacatures en stages te plaatsen alsook het posten van content op onze sociale media (Facebook, Instagram, LinkedIn). <br />
                                <a href="/bedrijf/diensten" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
                                    LEES MEER
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row pd-b-15">
                        <div className="col-7 pd-r-15 xs-hidden">
                            <h1 className="c-gray-500 fw-900 mr-t-10">Over ons</h1>
                            <div className="text-block mr-t-3 c-gray-500">
                                Hermes Recruitment is de rekruteringsafdeling van studentenvereniging Hermes Gent vzw. Wij begeleiden studenten in hun laatste bachelor- of masterjaar bij de start van hun professionele loopbaan.
                                <br />
                                <a href="bedrijf/over-ons" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
                                    CONTACTEER ONS
                                </a>
                            </div>
                        </div>
                        <div className="col-5 xs-col-12 t-center">
                            <img src="images/bedrijf2.svg" alt="" className="flip-image xs-w-60" />
                        </div>
                        <div className="col-12 hidden xs-block">
                            <h1 className="c-gray-500 fw-900 mr-t-10">Over ons</h1>
                            <div className="text-block mr-t-3 c-gray-500">
                                Hermes Recruitment is de rekruteringsafdeling van studentenvereniging Hermes Gent vzw. Wij begeleiden studenten in hun laatste bachelor- of masterjaar bij de start van hun professionele loopbaan. <br />
                                <a href="bedrijf/over-ons" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
                                    CONTACTEER ONS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <section className="eventen">
                <div className="container w-65 m-w-90">
                    <div className="row">
                        <div className="col-5 s-col-12 t-center">
                            <img src="images/jobbeurs.jpg" alt="" className="w-100  border-r-10 box-shadow" />
                        </div>
                        <div className="col-7 pd-l-15 s-pd-l-0 s-col-12">
                            <h1 className="c-gray-500 fw-900 s-mr-t-5">Job & Stage Event</h1>
                            <div className="text-block mr-t-3 c-gray-500">
                                Hermes Recruitment organiseert jaarlijks een Job & Stage Event voor studenten van de opleiding Industriële Wetenschappen aan de Universiteit Gent en gerelateerde professionele bachelors aan de Hogeschool Gent.
                                <br />
                                <a href="/bedrijf/job-en-stage-event" className="button button-m border-gray-500-30 c-gray-500 fw-700 mr-t-3">
                                    LEES MEER
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footers type="main" />
        </div>
    );
}

export default P_Bedrijf;
