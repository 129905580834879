import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import StageLijst from "../../../components/student/stage_lijst";
import React, {useState} from 'react'
import { Helmet } from "react-helmet";


function P_Paneel_Stages(props) {

  let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

  if(claims === null || claims.recruitment_privilege === false){
    window.location.replace("/");
  }else
  return (
    <div>
      <Helmet>
        <title>HR - Paneel - Stage</title>
      </Helmet>
      <Navigatie type="other" />
    

      <div className="mr-t-30"></div>
      <StageLijst isAdmin/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Paneel_Stages;
