import { AiOutlineArrowLeft } from "react-icons/ai";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import BreadCrumb from "../breadcrumb";
import { Helmet } from "react-helmet";
function Job(props) {
	let history = useHistory();
	const [item, setItem] = useState({ owner: { company_name: "" }, interests: [] });

	var link = config.api + "/vacancies/" + props.itemId;

	useEffect(() => {
		function itemOphalen(getReq) {
			axios
				.get(getReq)
				.then((res) => {
					setItem(res.data);
				})
				.catch((err) => {
					//console.log(err)
				});
		}

		itemOphalen(link);
	}, [link]);

	let inter = {
		elektromech: "Elektromechanica",
		bouw: "Bouwkunde",
		chem: "Chemie",
		infor: "Informatica",
		eict: "Elektronica-ICT",
		auto: "Automatisering",
		land: "Landmeten",
		etech: "Elektrotechniek",
	};

	function renderBreadCrumb() {
		if (props.type === "student") {
			return (
				<BreadCrumb
					prev={[
						["/student", "Home"],
						["/student/jobs", "Jobs"],
					]}
					now={item.title}
				/>
			);
		} else if (props.type === "paneel") {
			return (
				<BreadCrumb
					prev={[
						["/paneel", "Paneel"],
						["/paneel/jobs", "Jobs"],
					]}
					now={item.title}
				/>
			);
		}
	}

	let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

	return (
		<section className="mr-v-30">
			<Helmet>
				<title>{"HR - Job - " + item.title}</title>
			</Helmet>
			{(item.active && item.owner.active) || claims?.recruitment_privilege ? (
				<>
					<div className="container w-65 m-w-90">
						{renderBreadCrumb()}
						<div className="row mr-t-15">
							<div className="col-12 t-center mr-b-5">
								<img src={item.owner.company_logo} alt={item.owner.company_name} width="250px" />
							</div>
							<div className="col-3">
								<button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
									<AiOutlineArrowLeft />
								</button>
							</div>
							<div className="col-6">
								<h1 className="t-center t-20 c-gray-500">{item.title}</h1>
							</div>
							<div className="col-3"></div>
						</div>
						<br />
						<div className="t-center tags">
							{item.interests.map((interesse, index) => {
								return (
									<span key={index} className="tag b-yellow-400 t-7 mr-r-2 lh-15 pd-2 pd-v-1 border-r-5 c-gray-500 fw-700">
										{inter[interesse]}
									</span>
								);
							})}
						</div>
					</div>
					<div className="container w-50 l-w-70 m-w-90 s-w-95 mr-t-3">
						<div className="t-center">
							<a className="button button-s t-7 b-none border-gray-700-20 c-gray-700-70 fw-500 mr-b-7" href={config.base + "/student/bedrijven/" + item.owner.company_name + "/" + item.owner.id}>
								Bekijk bedrijf
							</a>
						</div>
						<div className="box pd-5 ul-fix">
							<div dangerouslySetInnerHTML={{ __html: item.advertisement_long_desc }}></div>
						</div>
					</div>
				</>
			) : (
				<div className="container w-65 m-w-90 mr-t-15">
            <h2 className="c-red t-center">Deze job of dit bedrijf is momenteel niet actief</h2>
        </div>
			)}
		</section>
	);
}

export default Job;
