import React from 'react';

function EventCard(props) {
  return (

    <div className="card card-v">
        <img className="card-image border-r-t-6" src={props.image} alt={props.title}/>
        <div className="card-text border-r-b-6 pd-h-6 pd-v-6">
            <p className="fw-700 t-9 c-gray-700">{props.title}</p>
            <p className="c-gray-500-70 t-7 ">{props.date}</p>
            {/* <p className="mr-v-7 c-gray-500 t-7">{props.text}</p> */}
            <a target={props.blank == "true" ? "_blank" : ""} href={props.link} className="button link mr-t-6 c-blue-400 fw-600 hover hover-2 hover-c-gray-300">{props.knop_text}</a>
        </div>
    </div>
  );
}


export default EventCard;
