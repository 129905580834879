import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { RiBankLine } from 'react-icons/ri';
import { FaInstagramSquare } from 'react-icons/fa'
function Footers(props) {
    if (props.type === "main") {
        return (
            <footer className="pd-5  box-shadow b-white">
                <div className="container w-70 m-w-90 s-w-100">
                    <div className="row pd-v-5 c-gray-500-80">

                        <div className="col-4 xs-col-12 t-center xs-pd-b-10 xs-t-center hidden xs-block">
                            &copy; Hermes Gent VZW.<br /><br />
                            <div className="t-14">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/HermesRecruit" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500"><FaFacebookSquare /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/hermesgentvzw" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500 pd-l-1"><FaLinkedin /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/hermesgent/" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500 pd-l-1"><FaInstagramSquare /></a>
                            </div>
                        </div>

                        <div className="col-4 xs-col-12 xs-t-center xs-pd-b-15">
                            <div className="row">
                                <div className="col-1 pd-t-2 t-left xs-col-12 xs-t-center">
                                    <AiOutlineMail />
                                </div>
                                <div className="col-11 xs-col-12 pd-v-2">
                                    <a className="button link c-gray-500-80" href="mailto:Recruitment@hermesgent.be">Recruitment@hermesgent.be</a>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-1 pd-t-2 t-left xs-col-12 xs-t-center">
                                    <HiOutlineLocationMarker />
                                </div>
                                <div className="col-11 xs-col-12 pd-b-2">
                                    Valentin Vaerwyckweg 1, 9000 Gent<br />
                                    Lokaal GSCHC.0.170
                                </div>
                            </div>

                        </div>
                        <div className="col-4 xs-col-12 t-center xs-pd-v-10 xs-t-center xs-hidden">
                            &copy; Hermes Gent VZW.<br /><br />
                            <div className="t-14 pd-t-2">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/HermesRecruit" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500"><FaFacebookSquare /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/hermesgentvzw" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500 pd-l-1"><FaLinkedin /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/hermesgent/" className="button link c-gray-500-60 hover hover-2 hover-c-gray-500 pd-l-1"><FaInstagramSquare /></a>
                            </div>
                        </div>
                        <div className="col-4 xs-col-12 t-right xs-t-center">
                            <div className="inline-block t-center xs-t-center">
                                <RiBankLine className="t-12" /><br />
                                Hermes Recruitment:<br />
                                BE49 7370 2547 5071
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}


export default Footers;
