import axios from "axios";
import { config } from "../../config";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { store } from "react-notifications-component";
function M_NieuwBedrijfAccount(props) {
    const [mail, setMail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    useEffect(() => {
        axios
            .get(config.api + "/companies/" + props.BedrijfID)
            .then((res) => {
                let infolink = res.data.contact_information;
                axios
                    .get(infolink)
                    .then((result) => {
                        let data = result.data;
                        setMail(data.contact_mail);
                        setFirstname(data.contact_firstname);
                        setLastname(data.contact_lastname);
                    })
                    .catch((error) => {});
            })
            .catch((err) => {});
    }, []);

    function makeAccount() {
        let data = {
            company: props.BedrijfID,
            email: mail,
            first_name: firstname,
            last_name: lastname
        };

        axios
            .post(config.api + "/auth/register/company", data)
            .then((response) => {
                console.log(response);
                store.addNotification({
                    title: "Succes!",
                    message: "Account is aangemaakt!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                props.reload();
            })
            .catch((error) => {
                store.addNotification({
                    title: "Oeps!",
                    message: "Account aanmaken mislukt, ofwel bestaat dit account al ofwel is het momentele account geen recruitmentaccount!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            });
    }

    return (
        <div className="">
            <Helmet>
                <title>HR - Nieuw bedrijf account</title>
            </Helmet>

            <div className="container w-90 m-w-95 s-w-98 mr-b-10">
                <h2 className="c-gray-500 mr-b-5">Account aanmaken</h2>
                <p>E-mailadres</p>
                <input onChange={(e) => setMail(e.target.value)} value={mail} type="mail" className="text-input" placeholder="E-mailadres" />

                <p>Voornaam</p>
                <input onChange={(e) => setFirstname(e.target.value)} value={firstname} type="text" className="text-input" placeholder="Voornaam" />

                <p>Achternaam</p>
                <input onChange={(e) => setLastname(e.target.value)} value={lastname} type="text" className="text-input" placeholder="Achternaam" />

                <button
                    onClick={(e) => {
                        e.preventDefault();
                        makeAccount();
                    }}
                    className="button button-m b-black c-white fw-700">
                    MAAK ACCOUNT AAN
                </button>
            </div>
        </div>
    );
}

export default M_NieuwBedrijfAccount;
