import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { config } from "../../config";
import { AiOutlineEdit } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import Modal from "../../components/modal";
import M_Stages_Nieuw from "../modals/stages/nieuw";
import M_Stages_Bewerk from "../modals/stages/bewerk";

const animatedComponents = makeAnimated();
function StageLijst(props) {
	const [items, setItems] = useState([]);

	useEffect(() => {
		dataOphalen(config.api + "/vacancies?type=I");
	}, []);

	function dataOphalen(getReq) {
		axios
			.get(getReq)
			.then((res) => {
				let data = res.data
				data = shuffleArray(data)
				data = data.filter((job) => job.owner.active || props.isAdmin)
				setItems(data);
				setLoading(1);
			})
			.catch((err) => {
				//console.log(err)
			});
	}

	function shuffleArray(array) {
		for (var i = array.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1));
			var temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array
	}

	const options = [
		{ value: "elektromech", label: "Elektromechanica" },
		{ value: "bouw", label: "Bouwkunde" },
		{ value: "chem", label: "Chemie" },
		{ value: "infor", label: "Informatica" },
		{ value: "eict", label: "Elektronica-ICT" },
		{ value: "auto", label: "Automatisering" },
		{ value: "land", label: "Landmeten" },
		{ value: "etech", label: "Elektrotechniek" },
	];

	function createIntresseParam(i) {
		let s = "";
		for (let x of i) {
			s += "&interests=" + x;
		}
		return s;
	}

	const [titel, setTitel] = useState("");
	const [intresses, setIntresses] = useState([]);
	const [loading, setLoading] = useState(0);

	function Search() {
		setLoading(0);
		var searchIntresses = [];
		for (let i = 0, len = intresses.length; i < len; i++) {
			searchIntresses.push(intresses[i].value);
		}

		if (titel === "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
			dataOphalen(config.api + "/vacancies?type=I");
		} else if (titel !== "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
			dataOphalen(config.api + "/vacancies?type=I&title=" + titel);
		} else if (titel === "" && searchIntresses.length > 0) {
			dataOphalen(config.api + "/vacancies?type=I" + createIntresseParam(searchIntresses));
		} else {
			dataOphalen(config.api + "/vacancies?type=I&title=" + titel + "&" + createIntresseParam(searchIntresses));
		}
	}

	function handleChange(event) {
		setTitel(event.target.value);
	}

	function _handleKeyDown(e) {
		if (e.key === "Enter") {
			Search();
		}
	}

	const [pag, setPag] = useState(0);
	const itemsPerPag = 20;

	function pagination(richting) {
		if (richting === "up") {
			setPag(pag + itemsPerPag);
			window.scrollTo(0, 0);
		} else if (richting === "down") {
			if (pag - itemsPerPag < 0) {
				setPag(0);
			} else {
				setPag(pag - itemsPerPag);
			}
		}
		window.scrollTo(0, 0);
	}
	function paginationRenderButtons(length) {
		let arr = [];

		if (pag / itemsPerPag > 2) {
			arr.push(
				<span onClick={() => updatePagination(0)} className="c-gray-700-70 pd-1 button">
					1 ...
				</span>
			);
		}

		for (let i = 0; i < length; i = i + itemsPerPag) {
			if (i === pag) {
				arr.push(
					<span onClick={() => updatePagination(i)} className="b-blue-400 c-white pd-1 pd-h-2 button border-r-10">
						{i / itemsPerPag + 1}
					</span>
				);
			} else if (pag - 2 * itemsPerPag <= i && i <= pag + 2 * itemsPerPag) {
				arr.push(
					<span onClick={() => updatePagination(i)} className="c-gray-700-70 pd-1 button">
						{i / itemsPerPag + 1}
					</span>
				);
			}
		}

		if (pag / itemsPerPag < Math.ceil(length / itemsPerPag) - 3) {
			arr.push(
				<span onClick={() => updatePagination(Math.ceil(length / itemsPerPag) * itemsPerPag - itemsPerPag)} className="c-gray-700-70 pd-1 button">
					... {Math.ceil(length / itemsPerPag)}
				</span>
			);
		}
		return (
			<span>
				{arr.map((item) => {
					return <span>{item}</span>;
				})}
			</span>
		);
	}
	function updatePagination(number) {
    window.scrollTo(0, 0);
		setPag(parseInt(number));
	}

	return (
		<section className="mr-b-30">
			<div className="container w-65 xl-w-80 m-w-90">
				<div className="row mr-t-15">
					<div className="col-4 s-col-12">
						{props.isAdmin ? (
							<>
								<Modal openButtonValue={<IoMdAdd />} openButtonClasses="button pd-h-4 pd-v-2 b-blue-400-10 t-9 c-blue-400 hover hover-1 hover-b-blue-400 hover-c-white mr-b-2">
									<M_Stages_Nieuw search={Search} />
								</Modal>
							</>
						) : (
							<span></span>
						)}
					</div>
					<div className="col-8 pd-l-6 s-col-12 s-pd-l-0 s-p-t-10">
						<h1 className="t-14 c-gray-500 mr-b-5">STAGES</h1>

						{itemsPerPag < items?.length ? (
							<div className="c-gray-700-70 pd-v-3 border-r-5 mr-t-4 fw-700 t-center">
								{pag !== 0 ? (
									<>
										<button onClick={() => pagination("down")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-r-3">
											&#60;
										</button>
									</>
								) : (
									""
								)}

								{paginationRenderButtons(items?.length)}
								{pag + itemsPerPag < items?.length ? (
									<>
										<button onClick={() => pagination("up")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-l-3">
											&#62;
										</button>
									</>
								) : (
									""
								)}
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-4 s-col-12 s-pd-b-5">
						<div className="box">
							<p className="c-gray-700 fw-700 pd-v-3">FILTER & ZOEKEN</p>
							<input onKeyDown={_handleKeyDown} onChange={handleChange} name="search" type="text" className="text-input border-gray-100 c-blue-400" placeholder="zoek..." />
							<p className="c-gray-700 fw-700 pd-v-2">INTERESSES</p>
							<Select onKeyDown={_handleKeyDown} onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />

							<button onClick={Search} className="button button-m b-blue-400 fw-700 c-white mr-v-6 pd-h-6">
								Zoek
							</button>
						</div>
					</div>
					<div className="col-8 pd-l-6 s-col-12 s-pd-l-0 s-p-t-10">
						{loading !== 0 ? (
							items?.map((item) => {
								if ((item.active && item.owner.active) || props.isAdmin) {
									return (
										<div className="box mr-b-1">
											<div className="row pd-v-5">
												<div className="col-3 pd-h-5 s-col-12 s-t-center">
													<a href={"stages/" + item.title.replace(/\s+/g, "-").toLowerCase() + "/" + item.id}>
														<img src={item.owner.company_logo} alt={item.owner.company_name} className="w-100 s-w-40 s-w-50 xs-w-100" />
													</a>
												</div>
												<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
													<a className="button link b-none c-none" href={"stages/" + item.title.replace(/\s+/g, "-").toLowerCase() + "/" + item.id}>
														<p className="fw-700 c-gray-700 t-9 s-pd-l-2">{item.title}</p>
														<div className="pd-t-2 text_comp pd-r-10 c-gray-500 s-pd-l-2">{item.advertisement_short_desc.length > 0 ? item.advertisement_short_desc.substring(0, 250) : "..."}</div>
													</a>
												</div>
											</div>
											{props.isAdmin ? (
												<div className="t-right">
													<Modal openButtonValue={<AiOutlineEdit />} openButtonClasses="button button-s t-14 c-gray-700-40 fw-700 mr-2 mr-b-0 b-none">
														<M_Stages_Bewerk search={Search} StageID={item.id} />
													</Modal>
												</div>
											) : (
												<span></span>
											)}
										</div>
									);
								} else {
									return <></>;
								}
							})
						) : (
							<div>
								<div className="box mr-b-1">
									<div className="row pd-v-5">
										<div className="col-3 pd-h-5 s-col-12 s-t-center">
											<div className="loading loading_img"></div>
										</div>
										<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
											<p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
										</div>
									</div>
								</div>
								<div className="box mr-b-1">
									<div className="row pd-v-5">
										<div className="col-3 pd-h-5 s-col-12 s-t-center">
											<div className="loading loading_img"></div>
										</div>
										<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
											<p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
										</div>
									</div>
								</div>
								<div className="box mr-b-1">
									<div className="row pd-v-5">
										<div className="col-3 pd-h-5 s-col-12 s-t-center">
											<div className="loading loading_img"></div>
										</div>
										<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
											<p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
										</div>
									</div>
								</div>
								<div className="box mr-b-1">
									<div className="row pd-v-5">
										<div className="col-3 pd-h-5 s-col-12 s-t-center">
											<div className="loading loading_img"></div>
										</div>
										<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
											<p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
										</div>
									</div>
								</div>
								<div className="box mr-b-1">
									<div className="row pd-v-5">
										<div className="col-3 pd-h-5 s-col-12 s-t-center">
											<div className="loading loading_img"></div>
										</div>
										<div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
											<p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
											<div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
										</div>
									</div>
								</div>
							</div>
						)}
						{itemsPerPag < items?.length ? (
							<div className="c-gray-700-70 pd-v-3 border-r-5 mr-t-4 fw-700 t-center">
								{pag !== 0 ? (
									<>
										<button onClick={() => pagination("down")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-r-3">
											&#60;
										</button>
									</>
								) : (
									""
								)}

								{paginationRenderButtons(items?.length)}
								{pag + itemsPerPag < items?.length ? (
									<>
										<button onClick={() => pagination("up")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-l-3">
											&#62;
										</button>
									</>
								) : (
									""
								)}
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</section>
	);
}

export default StageLijst;
