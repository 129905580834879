import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import Job from "../../components/student/job";
import { Helmet } from "react-helmet";


function P_Job_id(props) {

  function renderNav(){
    if(props.location.pathname.includes("student")){
      return <Navigatie type="studenten" />
    }else
    if(props.location.pathname.includes("bedrijf")){
      return <Navigatie type="bedrijven" />
    }else
    if(props.location.pathname.includes("paneel")){
      return <Navigatie type="other" />
    }
    else{
      return <Navigatie type="home" />
    }
  }

  return (
    <div>
      <Helmet>
        <title>HR - Job</title>
      </Helmet>
      {renderNav()}
      
      <Job type={props.location.pathname.includes("student") ? "student" : "paneel"} itemId={props.match.params.id}/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Job_id;
