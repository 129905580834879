import React, { useState, useEffect, useRef } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { config } from ".././config";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { CgBriefcase } from "react-icons/cg";
import { RiPencilRuler2Line } from "react-icons/ri";
import { BsCalendar } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiLogOut } from "react-icons/bi";
import { HiOutlineNewspaper } from "react-icons/hi";
import Modal from "./modal";
import M_Login from "./modals/login";

function Navigatie(props) {
    //const [menuState, updateMenuState] = useState("m-hidden");
    var menuClass = "menu-body col-9 t-right pd-r-0 m-hidden "; // + menuState;
    var menuClass2 = "menu-body col-3 t-right pd-r-0 m-hidden"; // + menuState;
    // eslint-disable-next-line
    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    const [showSidebar, setShowSidebar] = useState(false);
    const sideBarRef = useRef();
    function openMenu() {
        /*if (menuState === "m-hidden") {
			updateMenuState("");
		} else {
			updateMenuState("m-hidden");
		}*/
        setShowSidebar(true);
        //alert()
    }

    const [showAccountDropdown, setShowAccountDropdown] = useState(false);

    const accountDropdownRef = useRef();
    // on initial render, add click event listener
    useEffect(() => {
        document.body.addEventListener("click", (event) => {
            // check if element that was clicked is inside of ref'd component
            // if so no action is required from this event listener so exit
            if (accountDropdownRef?.current?.contains(event.target)) {
            } else {
                setShowAccountDropdown(false);
            }

            if (sideBarRef?.current?.contains(event.target)) {
            } else {
                setShowSidebar(false);
            }
        });
    }, []);

    function renderPaneelDropdown(type) {
        if (claims !== null && claims.recruitment_privilege) {
            return (
                <>
                    <div className="dropdown" ref={accountDropdownRef}>
                        <button onClick={() => setShowAccountDropdown(!showAccountDropdown)} className="button button-m pd-0 pd-l-4 b-none mr-0 t-7 pd-t-3 fw-600 c-black m-pd-r-6">
                            <span className="horizontal-center">
                                {claims.first_name.length > 0 && claims.first_name.length < 20 ? claims.first_name.toUpperCase() : "IK"} {showAccountDropdown ? <MdKeyboardArrowUp className="mr-l-1 icon-click" /> : <MdKeyboardArrowDown className="mr-l-1 icon-click" />}
                            </span>
                        </button>
                        {showAccountDropdown ? (
                            <ul className="m-w-100">
                                <div className="dropdown_triangle"></div>
                                <li>
                                    <a href={config.base + "/" + (type ? type + "/mijn-account" : "mijn-account")} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <FiUser className="nav-icon" /> MIJN ACCOUNT
                                        </span>
                                    </a>
                                    {/* {claims.account_type === "company" || claims.account_type === "recruit" ? (
                                        <a href={config.base + "/cv-bundel"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100">
                                            <span className="horizontal-center">
                                                <HiOutlineNewspaper className="nav-icon" /> CV BUNDEL
                                            </span>
                                        </a>
                                    ) : (
                                        ""
                                    )} */}
                                    <hr className="border-gray-700-10" />
                                    <a href={config.base + "/paneel/bedrijven"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <HiOutlineOfficeBuilding className="nav-icon" /> BEDRIJVEN
                                        </span>
                                    </a>
                                    <a href={config.base + "/paneel/jobs"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <CgBriefcase className="nav-icon" /> JOBS
                                        </span>
                                    </a>
                                    <a href={config.base + "/paneel/stage"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <RiPencilRuler2Line className="nav-icon" /> STAGES
                                        </span>
                                    </a>
                                    <a href={config.base + "/paneel/j&s"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <BsCalendar className="nav-icon" /> J&S EVENT
                                        </span>
                                    </a>
                                    <a href={config.base + "/paneel/accounts"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <HiOutlineUserGroup className="nav-icon" /> ACCOUNTS
                                        </span>
                                    </a>
                                    <hr className="border-gray-700-10" />
                                    <a href={config.base + "/logout"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 pd-v-2">
                                        <span className="horizontal-center">
                                            <BiLogOut className="nav-icon" /> LOGOUT
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                </>
            );
        } else if (claims !== null) {
            return (
                <div className="dropdown" ref={accountDropdownRef}>
                    <button onClick={() => setShowAccountDropdown(!showAccountDropdown)} className="horizontal-center button button-m pd-0 pd-l-4 b-none mr-0 t-7 pd-t-3 fw-600 c-black m-w-100 m-pd-r-6">
                        {claims.first_name.length > 0 && claims.first_name.length < 20 ? claims.first_name.toUpperCase() : "IK"} {showAccountDropdown ? <MdKeyboardArrowUp className="mr-l-1 icon-click" /> : <MdKeyboardArrowDown className="mr-l-1 icon-click" />}
                    </button>
                    {showAccountDropdown ? (
                        <ul className="m-w-100">
                            <div className="dropdown_triangle"></div>
                            <li>
                                <a href={config.base + "/" + (type ? type + "/mijn-account" : "mijn-account")} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100">
                                    <span className="horizontal-center">
                                        <FiUser className="nav-icon" /> MIJN ACCOUNT
                                    </span>
                                </a>
                                {/* {claims.account_type === "company" || claims.account_type === "recruit" ? (
                                    <a href={config.base + "/cv-bundel"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100">
                                        <span className="horizontal-center">
                                            <HiOutlineNewspaper className="nav-icon" /> CV BUNDEL
                                        </span>
                                    </a>
                                ) : (
                                    ""
                                )} */}
                                <a href={config.base + "/logout"} className="button button-m b-none t-7 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100">
                                    <span className="horizontal-center">
                                        <BiLogOut className="nav-icon" /> LOGOUT
                                    </span>
                                </a>
                            </li>
                        </ul>
                    ) : (
                        ""
                    )}
                </div>
            );
        } else {
            return (
                <Modal hideCancel={true} openButtonValue="LOGIN" boxClasses="container w-40 m-w-90 s-w-98 mr-b-10 m-mr-v-10" openButtonClasses="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 mr-l-3 pd-r-0 mr-r-0 hover hover-1 hover-c-black m-w-100 m-mr-l-0 m-pd-r-8 m-t-right">
                    <M_Login />
                </Modal>
            );
        }
    }

    function renderPaneelDropdownSidebar(type) {
        if (claims !== null && claims.recruitment_privilege) {
            return (
                <>
                    <ul className="m-w-100 pd-v-5">
                        <li>
                            <a href={config.base + "/" + (type ? type + "/mijn-account" : "mijn-account")} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                MIJN ACCOUNT
                            </a>
                            {/* {claims.account_type === "company" || claims.account_type === "recruit" ? (
								<a href={config.base + "/cv-bundel"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
									CV BUNDEL{" "}
								</a>
							) : (
								""
							)} */}
                            <a href={config.base + "/paneel/bedrijven"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                BEDRIJVEN
                            </a>
                            <a href={config.base + "/paneel/jobs"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                JOBS
                            </a>
                            <a href={config.base + "/paneel/stage"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                STAGES
                            </a>
                            <a href={config.base + "/paneel/j&s"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                J&S EVENT
                            </a>
                            <a href={config.base + "/paneel/accounts"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                ACCOUNTS
                            </a>

                            <a href={config.base + "/logout"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                LOGOUT
                            </a>
                        </li>
                    </ul>
                </>
            );
        } else if (claims !== null) {
            return (
                <>
                    <ul className="m-w-100 pd-v-5">
                        <li>
                            <a href={config.base + "/" + (type ? type + "/mijn-account" : "mijn-account")} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                MIJN ACCOUNT{" "}
                            </a>
                            {/* {claims.account_type === "company" || claims.account_type === "recruit" ? (
                                <a href={config.base + "/cv-bundel"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                    CV BUNDEL{" "}
                                </a>
                            ) : (
                                ""
                            )} */}
                            <a href={config.base + "/logout"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                LOGOUT
                            </a>
                        </li>
                    </ul>
                </>
            );
        } else {
            return (
                <a href={config.base + "/login"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black pd-t-5">
                    LOGIN
                </a>
            );
        }
    }

    if (props.type === "studenten") {
        return (
            <div className="navigation box w-100 border-r-0 b-white c-white">
                <div className="container w-65 xl-w-90 l-w-95 m-w-99">
                    <div className="row pd-v-4">
                        <div className="col-3 m-col-9 m-pd-l-8 s-pd-l-2">
                            <a href="/">
                                <img src={config.base + "/images/hermes_rec.png"} alt="" width="170px" />
                            </a>
                        </div>
                        <div className="col-3 t-right pd-t-3 pd-r-3 hidden m-block m-pd-r-8 s-pd-r-2" ref={sideBarRef}>
                            <button className="button link b-none t-10" onClick={() => openMenu()}>
                                <HiOutlineMenu />
                            </button>
                            {showSidebar ? (
                                <div className="sidebar hidden m-block b-white box-shadow w-40 s-w-100">
                                    <button onClick={() => setShowSidebar(false)} className="sidebar-close button link c-gray-500-50 b-none hover hover-2 hover-b-gray-300-20 border-r-circle">
                                        <MdClose />
                                    </button>
                                    <div className="w-100 t-center mr-t-12">
                                        <a href={config.base + "/student"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            HOME
                                        </a>
                                        <a href={config.base + "/student/job-en-stage-event"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            JOB & STAGE EVENT
                                        </a>
                                        <a href={config.base + "/student/wefynd"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            INSCHRIJVEN
                                        </a>
                                        <a href={config.base + "/student/jobs"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            JOBS
                                        </a>
                                        <a href={config.base + "/student/stages"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            STAGES
                                        </a>
                                        <a href={config.base + "/student/bedrijven"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            BEDRIJVEN
                                        </a>
                                        <a href={config.base + "/student/over-ons"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            OVER ONS
                                        </a>
                                        <hr className="border-gray-300-20 mr-t-5" />
                                        {renderPaneelDropdownSidebar("student")}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={menuClass}>
                            <a href={config.base + "/student"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                HOME
                            </a>
                            <a href={config.base + "/student/job-en-stage-event"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                JOB & STAGE EVENT
                            </a>
                            <a href={config.base + "/student/wefynd"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                INSCHRIJVEN
                            </a>
                            <a href={config.base + "/student/jobs"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                JOBS
                            </a>
                            <a href={config.base + "/student/stages"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                STAGES
                            </a>
                            <a href={config.base + "/student/bedrijven"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                BEDRIJVEN
                            </a>
                            <a href={config.base + "/student/over-ons"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                OVER ONS
                            </a>
                            {renderPaneelDropdown("student")}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (props.type === "bedrijven") {
        return (
            <div className="navigation box w-100 border-r-0 b-white c-white ">
                <div className="container w-65 xl-w-90 l-w-95 m-w-99">
                    <div className="row pd-v-4">
                        <div className="col-3 m-col-9 m-pd-l-8 s-pd-l-2">
                            <a href="/">
                                <img src={config.base + "/images/hermes_rec.png"} alt="" width="170px" />
                            </a>
                        </div>
                        <div className="col-3 t-right pd-t-3 pd-r-3 hidden m-block m-pd-r-8 s-pd-r-2" ref={sideBarRef}>
                            <button className="button link b-none t-10" onClick={() => openMenu()}>
                                <HiOutlineMenu />
                            </button>
                            {showSidebar ? (
                                <div className="sidebar hidden m-block b-white box-shadow w-40 s-w-100">
                                    <button onClick={() => setShowSidebar(false)} className="sidebar-close button link c-gray-500-50 b-none t-10">
                                        <MdClose />
                                    </button>
                                    <div className="w-100 t-center mr-t-12">
                                        <a href={config.base + "/bedrijf"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            HOME
                                        </a>
                                        <a href={config.base + "/bedrijf/diensten"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            DIENSTEN
                                        </a>
                                        <a href={config.base + "/student/job-en-stage-event"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            JOB & STAGE EVENT
                                        </a>
                                        <a href={config.base + "/bedrijf/bedrijven"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            BEDRIJVEN
                                        </a>
                                        <a href={config.base + "/bedrijf/over-ons"} className="block w-100 mr-t-6 link c-gray-500-80 hover hover-2 hover-c-black">
                                            OVER ONS
                                        </a>
                                        <hr className="border-gray-300-20 mr-t-5" />
                                        {renderPaneelDropdownSidebar("student")}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={menuClass}>
                            <a href={config.base + "/bedrijf"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                HOME
                            </a>
                            <a href={config.base + "/bedrijf/diensten"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                DIENSTEN
                            </a>
                            <a href={config.base + "/bedrijf/job-en-stage-event"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                JOB & STAGE EVENT
                            </a>
                            <a href={config.base + "/bedrijf/bedrijven"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                BEDRIJVEN
                            </a>
                            <a href={config.base + "/bedrijf/over-ons"} className="button button-m b-none t-7 pd-t-3 fw-600 c-gray-700-80 hover hover-1 hover-c-black m-w-100 m-pd-r-8">
                                OVER ONS
                            </a>
                            {renderPaneelDropdown("bedrijf")}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (props.type === "home") {
        return (
            <div className="navigation2 box w-100 border-r-0 b-white c-white">
                <div className="container w-65 xl-w-90 l-w-95 m-w-99">
                    <div className="row pd-v-8">
                        <div className="col-6 col-offset-3 t-center m-t-left m-col-9 m-mr-l-0 m-pd-l-8 s-pd-l-2">
                            <a href="/">
                                <img src={config.base + "/images/hermes_rec.png"} alt="" width="170px" />
                            </a>
                        </div>
                        <div className="col-3 t-right pd-t-3 pd-r-3 hidden m-block m-pd-r-8 s-pd-r-2" ref={sideBarRef}>
                            <button className="button link b-none t-10" onClick={() => openMenu()}>
                                <HiOutlineMenu />
                            </button>
                            {showSidebar ? (
                                <div className="sidebar hidden m-block b-white box-shadow w-40 s-w-100">
                                    <button onClick={() => setShowSidebar(false)} className="sidebar-close button link c-gray-500-50 b-none t-10">
                                        <MdClose />
                                    </button>
                                    <div className="w-100 t-center mr-t-12">{renderPaneelDropdownSidebar(false)}</div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={menuClass2}>{renderPaneelDropdown(false)}</div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === "other") {
        return (
            <div className="navigation box w-100 border-r-0 b-white c-white">
                <div className="container w-65 xl-w-90 l-w-95 m-w-99">
                    <div className="row pd-v-4">
                        <div className="col-3 m-col-9 m-pd-l-8 s-pd-l-2">
                            <a href="/">
                                <img src={config.base + "/images/hermes_rec.png"} alt="" width="170px" />
                            </a>
                        </div>
                        <div className="col-3 t-right pd-t-3 pd-r-3 hidden m-block m-pd-r-8 s-pd-r-2" ref={sideBarRef}>
                            <button className="button link b-none t-10" onClick={() => openMenu()}>
                                <HiOutlineMenu />
                            </button>
                            {showSidebar ? (
                                <div className="sidebar hidden m-block b-white box-shadow w-40 s-w-100">
                                    <button onClick={() => setShowSidebar(false)} className="sidebar-close button link c-gray-500-50 b-none t-10">
                                        <MdClose />
                                    </button>
                                    <div className="w-100 t-center mr-t-12">{renderPaneelDropdownSidebar(false)}</div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className={menuClass}>{renderPaneelDropdown(false)}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navigatie;
