import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import { config } from "../config";
import BreadCrumb from "../components/breadcrumb";
import Modal from "../components/modal";
import M_GegevensBewerken from "../components/modals/account/gegevensBewerken";

import { store } from "react-notifications-component";
import axios from "axios";

function P_MijnAccount(props) {
    const [cv, setCv] = useState();
    const [info, setInfo] = useState({});
    const [isStudent, setIsStudent] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState("");
    const [intresses, setIntresses] = useState([
        { value: "elektromech", label: "Elektromechanica", selected: false },
        { value: "bouw", label: "Bouwkunde", selected: false },
        { value: "chem", label: "Chemie", selected: false },
        { value: "infor", label: "Informatica", selected: false },
        { value: "eict", label: "Elektronica-ICT", selected: false },
        { value: "auto", label: "Automatisering", selected: false },
        { value: "land", label: "Landmeten", selected: false },
        { value: "etech", label: "Elektrotechniek", selected: false }
    ]);

    const [uploading, setUploading] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [companiesLoading, setCompaniesLoading] = useState(true);

    function renderNav() {
        if (claims.account_type === "student") {
            return <Navigatie type="studenten" />;
        } else if (claims.account_type === "company") {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="other" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Mijn Account" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Mijn Account" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Mijn Account" />;
        }
    }

    function updateItem(id, itemAttributes) {
        var index = intresses.findIndex((x) => x.value === id);
        if (index === -1) {
        } else setIntresses([...intresses.slice(0, index), Object.assign({}, intresses[index], itemAttributes), ...intresses.slice(index + 1)]);
    }

    // INTRESSES OPSLAAN
    function saveIntresses() {
        let items = [];
        for (let intresse of intresses) {
            if (intresse.selected) items.push(intresse.value);
        }
        if (items.length === 0) items = [];
        let data = { interests: items };
        axios.patch(config.api + "/students/" + claims.user_id, data).then((response) => {
            setInfo(response.data);
            store.addNotification({
                title: "Opslaan succesvol!",
                message: "Opslaan van je intresses was succesvol!",
                type: "default",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
        });
    }

    function saveCV() {
        let data = {
            public: true,
            contactable: true
        };
        axios
            .patch(config.api + "/students/" + claims.user_id, data)
            .then(() => {})
            .catch(() => {});

        let fd = new FormData();
        if (cv instanceof File) {
            //console.log(cv);
            if (!cv.type.includes("pdf")) {
                store.addNotification({
                    title: "Oeps!",
                    message: "Het geüploade bestand is geen PDF.",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                setCv(null);
                return;
            }

            fd.append("resume", cv);
            setUploading(true);

            axios
                .patch(config.api + "/students/" + claims.user_id, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(function (response) {
                    setInfo(response.data);
                    store.addNotification({
                        title: "CV opslaan succesvol!",
                        message: "Het opslaan van uw CV was succesvol!",
                        type: "default",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                    setUploading(false);
                    setCv(null);
                })
                .catch(function (error) {
                    store.addNotification({
                        title: "Opslaan mislukt!",
                        message: "Het opslaan van uw CV is mislukt.",
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                });
        }
    }

    function deleteCV() {
        let fd = new FormData();
        fd.append("resume", null);

        if (window.confirm("Weet u zeker dat u uw CV wilt verwijderen?")) {
            axios
                .patch(config.api + "/students/" + claims.user_id, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(function (response) {
                    setInfo(response.data);
                    store.addNotification({
                        title: "CV succesvol verwijderd!",
                        message: "Uw CV is succesvol verwijderd uit ons systeem!",
                        type: "default",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                })
                .catch(function (error) {
                    store.addNotification({
                        title: "Woopsie!",
                        message: "Verwijderen van CV mislukt :§",
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                });
        }
    }

    useEffect(() => {
        if (!claims.verified) {
            window.location.assign(config.base + "/valideer");
        }
        axios
            .get(config.api + "/students/" + claims.user_id)
            .then((result) => {
                let intressesSetData = intresses;
                for (let intresseData of intressesSetData) {
                    if (result.data.interests.includes(intresseData.value)) {
                        intresseData.selected = true;
                    }
                }
                if (claims.account_type === "student") {
                    setIsStudent(true);
                } else {
                    axios
                        .get(config.api + "/companies?company_owner=" + claims.user_id)
                        .then((res) => {
                            setCompanies(res.data);
                            setCompaniesLoading(false);
                        })
                        .catch(() => {});
                }
                setInfo(result.data);
                setIntresses(intressesSetData);
            })
            .catch(() => {
                axios
                    .get(config.api + "/companies?company_owner=" + claims.user_id)
                    .then((res) => {
                        setCompanies(res.data);
                        setCompaniesLoading(false);
                    })
                    .catch(() => {});
            });
    }, []);

    useEffect(() => {
        setProgressMessage("Vooruitgang is helemaal volledig! Bedankt, " + claims.first_name + "!");
        let score = 0;
        if (!!claims.first_name) {
            score = score + 20;
        }
        if (!!claims.last_name) {
            score = score + 20;
        }
        if (info.interests?.length > 0) {
            score = score + 30;
        } else {
            setProgressMessage("Selecteer uw intresse gebieden hieronder om de score te bevorderen.");
        }
        if (!!info.resume) {
            score = score + 30;
        } else {
            setProgressMessage("Upload uw CV hieronder om de score te bevorderen.");
        }
        setProgress(score);
    }, [info]);

    function changePass() {
        let data = {
            email: claims.email
        };
        if (window.confirm("We sturen u een mail met een link om uw wachtwoord te wijzigen.")) {
            axios
                .post(config.api + "/auth/reset-password/request", data)
                .then((res) => {
                    store.addNotification({
                        title: "Mail verzonden",
                        message: "De mail in verband met het wijzigen van uw wachtwoord is verzonden",
                        type: "default",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                })
                .catch(() => {});
        }
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace(config.base + "/login");
    } else
        return (
            <div className="mr-t-30">
                <Helmet>
                    <title>HR - Mijn Account</title>
                </Helmet>
                {renderNav()}
                <div className="container w-65 xl-w-75 m-w-90">
                    {renderBreadCrumb()}

                    <div className="row mr-v-10">
                        {isStudent ? (
                            // <>
                            //     <h2 className="c-gray-500 mr-b-2">Vooruitgang:</h2>
                            //     <div className="w-100 b-white box-shadow border-r-50">
                            //         <div className={"pd-v-2 gradient-1 border-r-50 t-center c-white fw-700 " + (progress > 0 ? "w-" + progress : "w-5")}>{progress}%</div>
                            //     </div>
                            //     <p className="mr-b-8 c-gray-500-80 t-italic mr-t-2">{progressMessage}</p>

                            //     <div className="col-12 box pd-8 s-pd-h-3 c-gray-500 lh-13">
                            //         <h1 className="c-gray-700-80 t-14 mr-b-8">Upload uw CV (PDF)</h1>
                            //         <div className="w-100">
                            //             {!!info.resume ? (
                            //                 <div>
                            //                     <p className="fw-400">
                            //                         U heeft reeds een CV geüpload, deze kan u{" "}
                            //                         <a href={info.resume} className="link c-blue-400 fw-700" download>
                            //                             hier
                            //                         </a>{" "}
                            //                         downloaden & bekijken.{" "}
                            //                         {/*<span className="t-italic t-7">
                            // 							(wilt u uw CV verwijderen, klik dan{" "}
                            // 							<button onClick={() => deleteCV()} className="button t-7 t-italic link b-none c-red fw-700">
                            // 								hier
                            // 							</button>
                            // 							)
                            // 						</span>*/}
                            //                     </p>
                            //                     <p className="mr-v-2 t-italic c-gray-500-60">Wilt u toch een andere uploaden, dan kan dat hieronder:</p>
                            //                 </div>
                            //             ) : (
                            //                 ""
                            //             )}
                            //             <input
                            //                 id="file-1"
                            //                 type="file"
                            //                 onChange={(e) => {
                            //                     setCv(e.target.files[0]);
                            //                 }}
                            //                 className="inputfile inputfile-1 hidden"
                            //             />
                            //             <label htmlFor="file-1" className="mr-b-3 b-black-80 hover hover-2 hover-b-black xs-w-100">
                            //                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                            //                     <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                            //                 </svg>
                            //                 <span>CV Uploaden</span>
                            //                 <span className="t-italic fw-400"> {cv ? "(" + cv.name + ")" : ""}</span>
                            //             </label>
                            //         </div>
                            //         <p className="c-gray-500-60 w-100 t-italic">Bij het uploaden van je CV geef je Hermes het recht om deze te delen met bedrijven en dat deze bedrijven contact met u mogen opnemen</p>
                            //         <button onClick={() => saveCV()} className="block button button-m b-blue-400 hover hover-2 hover-b-blue-400-80 c-white fw-700 mr-t-2 xs-w-100 xs-mr-0 xs-mr-t-5">
                            //             {uploading ? "BEZIG AAN HET UPLOADEN ..." : "UPLOADEN"}
                            //         </button>
                            //     </div>

                            //     <div className="col-12 mr-t-5 box pd-10 m-pd-5 m-pd-v-10 s-pd-h-3 c-gray-500 lh-13">
                            //         <h1 className="c-gray-700-80 t-14 mr-b-8">Intresses selecteren</h1>

                            //         {intresses.map((item, index) => {
                            //             return (
                            //                 <button key={index} onClick={() => updateItem(item.value, { selected: !item.selected })} className={"button button-m mr-r-1 mr-v-1 xs-w-100 " + (item.selected ? "b-black c-white" : "c-gray-500")}>
                            //                     {item.label}
                            //                 </button>
                            //             );
                            //         })}

                            //         <button onClick={() => saveIntresses()} className="block button button-m b-blue-400 hover hover-2 hover-b-blue-400-80 c-white fw-700 mr-t-4 xs-w-100 xs-mr-0 xs-mr-t-5">
                            //             OPSLAAN
                            //         </button>
                            //     </div>
                            // </>
                            <></>
                        ) : (
                            <>
                                {companies.length > 0 ? (
                                    <>
                                        <div className="box pd-10 m-pd-5 m-pd-v-10 s-pd-h-3 c-gray-500 lh-13 mr-b-5">
                                            <p>
                                                Voor eventuele vragen, neem gerust contact op met ons recruitmentteam via{" "}
                                                <a className="fw-700 c-black" href="mailto:recruitment@hermesgent.be">
                                                    recruitment@hermesgent.be
                                                </a>
                                            </p>
                                            {/* <h2>Dagindeling Job & Stage Event.</h2>
                                            <br />
                                            <p>
                                                <em>
                                                    Opbouw: <strong>Vanaf 9 uur</strong> (Bij aankomst ontvangt u een map met de naamkaartjes)
                                                </em>
                                            </p>
                                            <br />
                                            <p>U kunt materiaal laden en lossen via Toren 3 of 5 van het ICC, afhankelijk van de plaats van uw stand (standenkeuze volgt later). U kunt deze torens bereiken naast de inrit van de parking van het ICC te Familie van Rysselberghedreef 2, 9000 Gent. </p>
                                            <br />
                                            <p>Vervolgens kunt u uw wagen parkeren onder het Sint-Pietersplein. Er zijn door ons shuttle's voorzien naar en van het ICC.</p>
                                            <br />
                                            <em>
                                                <strong>Lunch:</strong> 12 uur - 13.30 uur
                                            </em>
                                            <p>Uw materiaal zal bewaakt worden tijdens de lunch, We raden u echter aan om waardevolle items zoals laptops bij u te houden. De lunch zal ook verlopen per zone om overrompelingen te vermijden. </p>
                                            <br />
                                            <em>
                                                <strong>Start</strong> beurs: <strong>13 uur 30</strong>
                                            </em>
                                            <p>Tijdens de beurs voorzien wij u van de nodige dranken en broodjes.</p>
                                            <br />
                                            <em>
                                                <strong>Einde</strong> beurs: <strong>18 uur</strong> (afbraak: vanaf 18 uur)
                                            </em>
                                            <br />
                                            <br />
                                            <em>
                                                <strong>Receptie:</strong> 18 uur - 19 uur
                                            </em>
                                            <br />
                                            <br />
                                            <p>
                                                Tijdens de beurs zal u beschikking hebben tot het <strong>Wifi-netwerk</strong>, het wachtwoord ontvangt u de dag zelf. Studenten zullen geen toegang hebben tot dit netwerk.
                                            </p> */}
                                        </div>
                                        <div className="box pd-10 m-pd-5 m-pd-v-10 s-pd-h-3 c-gray-500 lh-13">
                                            <h1 className="c-gray-700-80 t-14 mr-b-2">{companies.length > 1 ? "Mijn bedrijven" : "Mijn bedrijf"}</h1>
                                            {companiesLoading ? (
                                                "Loading"
                                            ) : (
                                                <div className="row">
                                                    {companies.map((company, index) => {
                                                        return (
                                                            <div className="border-gray-500-10 w-100 pd-v-2 pd-h-3 mr-t-3 border-r-5">
                                                                <div className="row horizontal-center">
                                                                    <div className="col-8">{company.company_name}</div>
                                                                    <div className="col-4">
                                                                        <a href={config.base + "/mijn-bedrijf/" + company.id} className="block button link c-blue-400 w-100 t-right">
                                                                            Bekijken & Bewerken
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        <div className="col-12 mr-t-5 box pd-10 m-pd-5 m-pd-v-10 s-pd-h-3 c-gray-500 lh-13 mr-b-15">
                            <h1 className="c-gray-700-80 t-14 mr-b-8">Uw account gegevens</h1>
                            <div className="w-100">
                                <div className="fw-500 c-gray-700-80 mr-b-2 t-10">Voornaam</div>
                                <div className="box b-gray-200-20 pd-3 t-9 w-100 c-gray-700-50 xs-t-8 border-r-2">{claims.first_name}</div>
                            </div>
                            <div className="w-100 mr-t-6">
                                <div className="fw-500 c-gray-700-80 mr-b-2 t-10">Achternaam</div>
                                <div className="box b-gray-200-20 pd-3 t-9 w-100 c-gray-700-50 xs-t-8 border-r-2">{claims.last_name}</div>
                            </div>
                            <div className="w-100 mr-t-6">
                                <div className="fw-500 c-gray-700-80 mr-b-2 t-10">E-mailadress</div>
                                <div className="box b-gray-200-20 pd-3 t-9 w-100 c-gray-700-50 xs-t-8 border-r-2">{claims.email}</div>
                            </div>
                            <Modal openButtonClasses="button button-m b-black hover hover-2 hover-b-black-80 c-white fw-700 mr-t-10 xs-w-100 xs-mr-0 xs-mr-t-5" openButtonValue="GEGEVENS BEWERKEN">
                                <M_GegevensBewerken />
                            </Modal>
                            <button className="button hidden link b-none c-red hover hover-2 hover-c-red-80 mr-t-10 mr-l-3 xs-w-100 xs-mr-0 xs-mr-t-2">ACCOUNT VERWIJDEREN</button>
                            <button onClick={() => changePass()} className="button link b-none c-gray-500-80 mr-l-5 fw-500 t-7 hover hover-2 hover-c-black xs-mr-l-0 xs-mr-t-5">
                                Mijn wachtwoord wijzigen
                            </button>
                        </div>
                    </div>
                </div>
                <Footers type="main" />
            </div>
        );
}

export default P_MijnAccount;
