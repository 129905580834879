import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { config } from "../../config";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Modal from "../modal";
import M_Bedrijf_Info_Bewerk from "../modals/bedrijf/bewerk-info";
import M_Bedrijf_Bewerk from "../modals/bedrijf/bewerk";

const animatedComponents = makeAnimated();
function BedrijvenInfo(props) {
    let history = useHistory();

    const [bedrijven, setBedrijven] = useState([]);

    useEffect(() => {
        bedrijvenOphalen(config.api + "/companies");
    }, []);

    function bedrijvenOphalen(getReq) {
        var allData = [];
        axios.get(getReq).then((res) => {
            //setBedrijven(res.data)
            // eslint-disable-next-line
            res.data.map((bedrijf) => {
                var link = bedrijf.contact_information;
                axios
                    .get(link)
                    .then((x) => {
                        bedrijf = { ...bedrijf, ...x.data };
                        allData = [...allData, bedrijf];
                        setBedrijven(allData);
                    })
                    .catch((r) => {});
            });
            setLoading(1);
        });
    }

    const options = [
        { value: "elektromech", label: "Elektromechanica" },
        { value: "bouw", label: "Bouwkunde" },
        { value: "chem", label: "Chemie" },
        { value: "infor", label: "Informatica" },
        { value: "eict", label: "Elektronica-ICT" },
        { value: "auto", label: "Automatisering" },
        { value: "land", label: "Landmeten" },
        { value: "etech", label: "Elektrotechniek" }
    ];

    function createIntresseParam(i) {
        let first = true;
        let s = "interests=";
        for (let x of i) {
            if (first) {
                s += x;
            } else {
                s += "&interests=" + x;
            }
            first = false;
        }
        return s;
    }

    const [titel, setTitel] = useState("");
    const [intresses, setIntresses] = useState([]);
    const [loading, setLoading] = useState(0);

    function Search() {
        setLoading(0);
        var searchIntresses = [];
        for (let i = 0, len = intresses.length; i < len; i++) {
            searchIntresses.push(intresses[i].value);
        }

        if (titel === "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
            bedrijvenOphalen(config.api + "/companies");
        } else if (titel !== "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
            //console.log("test")
            bedrijvenOphalen(config.api + "/companies?company_name=" + titel);
        } else if (titel === "" && searchIntresses.length > 0) {
            //console.log(config.api + "/companies?" + createIntresseParam(searchIntresses))
            bedrijvenOphalen(config.api + "/companies?" + createIntresseParam(searchIntresses));
        } else {
            bedrijvenOphalen(config.api + "/companies?company_name=" + titel + "&" + createIntresseParam(searchIntresses));
        }
    }

    function handleChange(event) {
        setTitel(event.target.value);
    }

    function _handleKeyDown(e) {
        if (e.key === "Enter") {
            Search();
        }
    }

    function changeClasses(id, max) {
        for (let i = 1; i <= max; i++) {
            if (i === id) {
                document.getElementById("table-" + i).classList.remove("hidden");
                document.getElementById("text-" + i).classList.remove("hidden");
                document.getElementById(i).classList.add("b-blue-400");
                document.getElementById(i).classList.add("c-white");
                document.getElementById(i).classList.remove("b-gray-700-10");
                document.getElementById(i).classList.remove("c-gray-700-80");
            } else {
                document.getElementById("table-" + i).classList.add("hidden");
                document.getElementById("text-" + i).classList.add("hidden");
                document.getElementById(i).classList.remove("b-blue-400");
                document.getElementById(i).classList.remove("c-white");
                document.getElementById(i).classList.add("b-gray-700-10");
                document.getElementById(i).classList.add("c-gray-700-80");
            }
        }
    }

    function updateView(e) {
        var id = parseInt(e.target.id);
        changeClasses(id, 4);
    }

    return (
        <section className="mr-b-30">
            <div className="container w-65 xl-w-80 m-w-90">
                <div className="row mr-t-15">
                    <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
                        <AiOutlineArrowLeft />
                    </button>
                </div>
                <div className="row mr-t-5">
                    <div className="col-12 pd-b-5">
                        <div className="box">
                            <p className="c-gray-700 fw-700 pd-v-3">FILTER & ZOEKEN</p>
                            <input onKeyDown={_handleKeyDown} onChange={handleChange} name="search" type="text" className="text-input border-gray-100 c-blue-400" placeholder="zoek..." />
                            <p className="c-gray-700 fw-700 pd-v-2">INTERESSES</p>
                            <Select onKeyDown={_handleKeyDown} onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />

                            <button onClick={Search} className="button button-m b-blue-400 c-white mr-v-5 fw-700">
                                Zoek
                            </button>
                        </div>
                    </div>
                    <div>
                        <button id="1" className="button button-m b-blue-400 c-white fw-700 mr-r-2" onClick={updateView}>
                            Contactpersoon gegevens
                        </button>
                        <button id="2" className="button button-m b-gray-700-10 c-gray-700-80 fw-700 mr-r-2" onClick={updateView}>
                            Facturatie gegevens
                        </button>
                        <button id="3" className="button button-m b-gray-700-10 c-gray-700-80 fw-700 mr-r-2" onClick={updateView}>
                            Bedrijfspagina
                        </button>
                        <button id="4" className="button button-m b-gray-700-10 c-gray-700-80 fw-700" onClick={updateView}>
                            Overige gegevens
                        </button>

                        <h1 id="text-1" className="t-14 c-gray-500 mr-v-5">
                            CONTACTPERSOON GEGEVENS
                        </h1>
                        <h1 id="text-2" className="t-14 c-gray-500 mr-v-5 hidden">
                            FACTURATIE GEGEVENS
                        </h1>
                        <h1 id="text-3" className="t-14 c-gray-500 mr-v-5 hidden">
                            BEDRIJFSPAGINA
                        </h1>
                        <h1 id="text-4" className="t-14 c-gray-500 mr-v-5 hidden">
                            OVERIGE GEGEVENS
                        </h1>
                    </div>
                    <div className="col-12 m-pd-t-10">
                        <table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
                            <thead className="c-gray-700-80">
                                <tr>
                                    <th>Facturatie Bedrijfsnaam</th>
                                    <th>
                                        Contact persoon <span className="c-gray-500-70">(Functie)</span>
                                    </th>
                                    <th>Contact mail</th>
                                    <th>Telefoonnummer</th>
                                    <th>Gsmnummer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading !== 0 ? (
                                    bedrijven.map((bedrijf, index) => (
                                        <tr key={bedrijf.id} className={"tr-" + (index % 2)}>
                                            <Modal className="t-left" openButtonValue={<td>{bedrijf.contact_company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
                                                <M_Bedrijf_Info_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                            </Modal>
                                            <td>
                                                {bedrijf.contact_firstname} {bedrijf.contact_lastname}
                                                <span className="c-gray-500-70"> ({bedrijf.contact_person_function})</span>
                                            </td>
                                            <td>{bedrijf.contact_mail}</td>
                                            <td>{bedrijf.contact_telephonenumber}</td>
                                            <td>{bedrijf.contact_cellphonenumber}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <table id="table-2" className="t-left bedrijf-info-table hidden box-shadow-2">
                            <thead className="c-gray-700-80">
                                <tr>
                                    <th>Facturatie Bedrijfsnaam</th>
                                    <th>Facturatie mail</th>
                                    <th>Adres</th>
                                    <th>BTW nummer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading !== 0 ? (
                                    bedrijven.map((bedrijf, index) => (
                                        <tr key={bedrijf.id} className={"tr-" + (index % 2)}>
                                            <Modal className="t-left" openButtonValue={<td>{bedrijf.contact_company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
                                                <M_Bedrijf_Info_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                            </Modal>
                                            <td>{bedrijf.contact_billingmail}</td>
                                            <td>
                                                {bedrijf.contact_street} {bedrijf.contact_number}, {bedrijf.contact_zipcode} {bedrijf.contact_city} ({bedrijf.contact_country})
                                            </td>
                                            <td>{bedrijf.contact_btw}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <table id="table-3" className="t-left bedrijf-info-table hidden box-shadow-2">
                            <thead className="c-gray-700-80">
                                <tr>
                                    <th>Bedrijf naam</th>
                                    <th>Sponsorpakket</th>
                                    <th>Eigenaar</th>
                                    <th>Intresses</th>
                                    <th>Kort</th>
                                    <th>Lang</th>
                                    <th>Website</th>
                                    <th>Logo</th>
                                    <th>Actief</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading !== 0 ? (
                                    bedrijven.map((bedrijf, index) => (
                                        <tr key={bedrijf.id} className={"tr-" + (index % 2)}>
                                            <Modal className="t-left" openButtonValue={<td>{bedrijf.company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
                                                <M_Bedrijf_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                            </Modal>
                                            <td>{bedrijf.company_role === "head" ? <span className="c-red">Hoofdsponsor</span> : bedrijf.company_role === "base" ? "Standaard" : bedrijf.company_role === "recr" ? <span className="c-blue-400">Recruitment</span> : "Niet-sponsor"}</td>
                                            <td>{bedrijf.company_owner.email}</td>
                                            <td className={bedrijf.interests.length > 0 ? "b-mint-50" : "b-red-50"}></td>
                                            <td className={bedrijf.company_short_desc ? "b-mint-50" : "b-red-50"}></td>
                                            <td className={bedrijf.company_long_desc ? "b-mint-50" : "b-red-50"}></td>
                                            <td className={bedrijf.company_url ? "b-mint-50" : "b-red-50"}></td>
                                            <td className={bedrijf.company_logo ? "b-mint-50" : "b-red-50"}></td>
                                            <td className={bedrijf.active ? "b-mint-50" : "b-red-50"}></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <table id="table-4" className="t-left bedrijf-info-table hidden box-shadow-2">
                            <thead className="c-gray-700-80">
                                <tr>
                                    <th>Facturatie Bedrijfsnaam</th>
                                    <th>Algemeen Emailadres</th>
                                    <th>Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading !== 0 ? (
                                    bedrijven.map((bedrijf, index) => (
                                        <tr key={bedrijf.id} className={"tr-" + (index % 2)}>
                                            <Modal className="t-left" openButtonValue={<td>{bedrijf.company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
                                                <M_Bedrijf_Info_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                            </Modal>
                                            <td></td>
                                            <td>
                                                <a href={bedrijf.company_url} className="button link pd-v-0 c-mint fw-500">
                                                    {bedrijf.company_url}
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                        <td className="loading loading_form"></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BedrijvenInfo;
