import EventCard from "../components/eventcard";
import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";

function P_Studenten() {
	return (
		<div className="Studenten">
			<Helmet>
				<title>HR - Student</title>
			</Helmet>
			<Navigatie type="studenten" />
			{/* <header className="header s-hidden">
				<div className="header-content w-100">
					<div className="container w-65 m-w-90">
						<div className="row mr-t-20">
							<div className="col-12">
								<div className="row">
									<h1 className="c-white t-20 header-title">AANKOMENDE EVENEMENTEN:</h1>
								</div>
								<br />

								<div className="row pd-v-1">
									<div className="col-4 xs-col-12 pd-r-4">
										<EventCard
											image="images/bijna_ingenieur.png"
											title="Ik ben (bijna) ingenieur. Wat nu?"
											date="Dinsdag 22 februari 2022 19u-20u"
											// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
											knop_text="SCHRIJF IN"
											blank="true"
											link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUQzJMQ0JBODZaUTdHTjRPVUdCOUZIS0c3Wi4u"
										/>
									</div>
									<div className="col-4 xs-col-12 pd-r-4">
										<EventCard
											image="images/schitteren_op_linkedin.png"
											title="Schitteren op LinkedIn"
											date="Dinsdag 22 februari 2022 20u30-21u30"
											// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
											knop_text="SCHRIJF IN"
											blank="true"
											link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUNFdCN0tTTkdZQ0JMWUNWNUJUSkZUOUlHUy4u"
										/>
									</div>
									<div className="col-4 xs-col-12 pd-r-4">
										<EventCard
											image="images/js_cropped.png"
											title="Job & Stage Event"
											date="Donderdag 24 februari 2022"
											// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
											knop_text="NAAR EVENT"
											blank="false"
											link="/student/job-en-stage-event"
										/>
									</div>
								</div>
							</div>
							<div className="w-100 mr-t-10">
								<center>
									<a href="/" className="button button-m event-button mr-r-4">
										BEKIJK MEER
									</a>
								</center>
							</div>
						</div>
					</div>
				</div>
			</header>*/ }
			<main className="box-shadow s-mr-t-20"> {/* main-student als class voor als header actief is */}
				<div className="container w-65 m-w-90 mr-t-20">
					{/* <div className="hidden s-block container w-60 xs-w-85">
						<div className="w-100 pd-v-5">
							<EventCard
								image="images/bijna_ingenieur.png"
								title="Ik ben (bijna) ingenieur. Wat nu?"
								date="Dinsdag 22 februari 2022 19u-20u"
								// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
								knop_text="SCHRIJF IN"
								blank="true"
								link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUQzJMQ0JBODZaUTdHTjRPVUdCOUZIS0c3Wi4u"
							/>
						</div>
						<div className="w-100 pd-v-5">
							<EventCard
								image="images/schitteren_op_linkedin.png"
								title="Schitteren op LinkedIn"
								date="Dinsdag 22 februari 2022 20u30-21u30"
								// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
								knop_text="SCHRIJF IN"
								blank="true"
								link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUNFdCN0tTTkdZQ0JMWUNWNUJUSkZUOUlHUy4u"
							/>
						</div>
						<div className="w-100 pd-v-5">
							<EventCard
								image="images/js_cropped.png"
								title="Job & Stage Event"
								date="Donderdag 24 februari 2022"
								// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
								knop_text="NAAR EVENT"
								blank="false"
								link="/student/job-en-stage-event"
							/>
						</div>
					</div>
					<center>
						<br />
						<div className="mouse">
							<div className="roll"></div>
							<div className="rollshadow"></div>
						</div>
					</center> */}
					<div className="row pd-t-15 pd-b-10">
						<div className="col-5 xs-col-12 xs-t-center">
							<img src="images/student1.svg" alt="" className="xs-w-60" />
						</div>
						<div className="col-7 pd-l-15 xs-col-12 xs-pd-l-0">
							<h1 className="c-gray-500 fw-900 mr-t-5">Jobs</h1>
							<div className="text-block mr-t-3 c-gray-500">
								Neem een kijkje tussen de openstaande vacatures van de bedrijven die deelnemen aan ons jaarlijks Job & Stage Event.
								<br />{" "}
								<a href="student/jobs" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
									BEKIJK JOBS
								</a>
							</div>
						</div>
					</div>
					<div className="row pd-b-15">
						<div className="col-7 pd-r-15 xs-hidden">
							<h1 className="c-gray-500 fw-900 mr-t-10">Stages</h1>
							<div className="text-block mr-t-3 c-gray-500">
								Naast vacatures bieden de deelnemende bedrijven ook stageplaatsen aan, kijk snel of er niets voor jou tussen zit. <br />
								<a href="student/stages" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
									BEKIJK STAGES
								</a>
							</div>
						</div>
						<div className="col-5 xs-col-12 t-center">
							<img src="images/student2.svg" alt="" className="flip-image xs-w-60" />
						</div>
						<div className="col-12 hidden xs-block">
							<h1 className="c-gray-500 fw-900 mr-t-10">Stages</h1>
							<div className="text-block mr-t-3 c-gray-500">
								Naast vacatures bieden de deelnemende bedrijven ook stageplaatsen aan, kijk snel of er niets voor jou tussen zit. <br />
								<a href="student/stages" className="button button-m border-blue-400 c-blue-400 fw-700 mr-t-3">
									BEKIJK STAGES
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
			<section className="eventen">
				<div className="container w-65 m-w-90">
					<div className="row">
						<div className="col-5 s-col-12 t-center">
							<img src="images/jobbeurs.jpg" alt="" className="w-100  border-r-10 box-shadow" />
						</div>
						<div className="col-7 pd-l-15 s-pd-l-0 s-col-12">
							<h1 className="c-gray-500 fw-900 s-mr-t-5">Job & Stage Event</h1>
							<div className="text-block mr-t-3 c-gray-500">
								Hermes Recruitment organiseert jaarlijks een Job & Stage Event voor studenten van de opleiding Industriële Wetenschappen aan de Universiteit Gent en gerelateerde professionele bachelors aan de Hogeschool Gent.
								<br />
								<a href="student/job-en-stage-event" className="button button-m border-gray-500-30 c-gray-500 fw-700 mr-t-3">
									LEES MEER
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footers type="main" />
		</div>
	);
}

export default P_Studenten;
