import axios from "axios";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { config } from "../../../config";
import Switch from "@material-ui/core/Switch/Switch";
import { store } from "react-notifications-component";
import { Editor } from "@tinymce/tinymce-react";
import { AiOutlineDelete } from "react-icons/ai";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import Modal from "../../modal";
import M_Bedrijf_Info_Bewerk from "./bewerk-info";
import M_NieuwBedrijfAccount from "../nieuwBedrijfAccount";
import AsyncSelect from "react-select/async";

const animatedComponents = makeAnimated();

function M_Bedrijf_Bewerk(props) {
    const [userOptions, setUserOptions] = useState([]);
    const [user, setUser] = useState({ value: "test", label: "test" });
    useEffect(() => {
        bedrijfOphalen();
    }, []);

    function bedrijfOphalen() {
        usersOphalen();
        axios
            .get(config.api + "/companies/" + props.BedrijfID)
            .then((res) => {
                for (var i = 0; i < options.length; i++) {
                    var obj = options[i].value;

                    if (res.data.interests.includes(obj)) {
                        optionsIncluded.push(options[i]);
                    }
                    res.data["defaultInterests"] = optionsIncluded;
                    setIntresses(optionsIncluded);
                }
                user.value = res.data.company_owner.id;
                user.label = res.data.company_owner.email;
                setUser({ value: res.data.company_owner.id, label: res.data.company_owner.email });
                setState({
                    ...state,
                    company_name: res.data.company_name,
                    company_role: res.data.company_role,
                    company_url: res.data.company_url,
                    company_short_desc: res.data.company_short_desc,
                    company_long_desc: res.data.company_long_desc,
                    active: res.data.active,
                    international: res.data.international,
                    masters_thesis: res.data.masters_thesis,
                    company_logo: res.data.company_logo
                });

                //console.log(selectedUser);
            })
            .catch((err) => {
                //console.log(err)
            });
    }

    function usersOphalen() {
        axios
            .get(config.api + "/users")
            .then((res) => {
                let data = res.data;
                data = data.filter((x) => x.account_type === "company" || x.account_type === "recruit");

                let useropt = [];
                for (let x of data) {
                    useropt.push({ value: x.id, label: x.email });
                }
                setUserOptions(useropt);
            })
            .catch((err) => {
                //console.log(err)
            });
    }

    const [bedrijf, setBedrijf] = useState({ interests: [], company_owner: { id: "" }, defaultInterests: [] });

    const options = [
        { value: "elektromech", label: "Elektromechanica" },
        { value: "bouw", label: "Bouwkunde" },
        { value: "chem", label: "Chemie" },
        { value: "infor", label: "Informatica" },
        { value: "eict", label: "Elektronica-ICT" },
        { value: "auto", label: "Automatisering" },
        { value: "land", label: "Landmeten" },
        { value: "etech", label: "Elektrotechniek" }
    ];

    var optionsIncluded = useMemo(() => {
        return [];
    }, []);

    const editorRef = useRef(null);

    const [state, setState] = React.useState({
        company_approved: false,
        active: false,
        international: false,
        masters_thesis: false,
        company_logo: ""
    });

    const handleSwitch = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [intresses, setIntresses] = useState();

    // company name
    // company desc

    function submit(e) {
        e.preventDefault();
        var PushIntr = [];
        if (intresses.length !== 0) {
            for (let i of intresses) {
                PushIntr.push(i.value);
            }
        }

        let values = {
            ...state,
            interests: PushIntr,
            company_long_desc: editorRef.current.getContent()
        };
        delete values.company_logo;
        values["company_owner"] = user.value;
        if (!values.company_owner) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: "Bedrijfs eigenaar mag niet leeg zijn!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }

        //console.log(values.company_name);
        if (values.company_name.includes("/")) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: 'Uw bedrijfsnaam mag geen "/" bevatten!',
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        let fd = new FormData();
        if (state.company_logo instanceof File) {
            fd.append("company_logo", state.company_logo);
        }

        axios
            .patch(config.api + "/companies/" + props.BedrijfID, values, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(() => {
                axios
                    .patch(config.api + "/companies/" + props.BedrijfID, fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(function (response) {
                        store.addNotification({
                            title: "Bewerken gelukt!",
                            message: "Bewerken succesvol gelukt!",
                            type: "default",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        });
                    })
                    .catch(function (error) {
                        store.addNotification({
                            title: "Bewerken mislukt!",
                            message: "Heeft u alles correct ingevuld?",
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    });
            });
    }

    function handleChange(e) {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setState({ ...state, [name]: value });
    }

    function verwijderBedrijf() {
        if (window.confirm("Bevestig het verwijderen van " + state.company_name + ".")) {
            axios
                .delete(config.api + "/companies/" + props.BedrijfID)
                .then(function (response) {
                    //window.location.assign(config.base + '/paneel/bedrijven');
                    props.search();
                })
                .catch((err) => {
                    //console.log(err)
                });
        }
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-85 xl-w-90 m-w-100 mr-v-10">
                <div className="t-center">
                    <img src={state.company_logo instanceof File ? URL.createObjectURL(state.company_logo) : state.company_logo} alt={state.company_name} width="250px" />
                </div>
                <h2 className="t-center c-gray-700-90 mr-v-5">{state.company_name} - bewerken</h2>

                <Modal openButtonValue={"Contactinfo bewerken"} openButtonClasses="button link b-none t-italic t-8 c-indigo fw-700 mr-b-3">
                    <M_Bedrijf_Info_Bewerk BedrijfID={props.BedrijfID} />
                </Modal>

                <form>
                    <label>Bedrijf actief:</label>
                    <Switch checked={state.active} onChange={handleSwitch} name="active" />

                    <label>Internationaal:</label>
                    <Switch checked={state.international} onChange={handleSwitch} name="international" />
                    <label>Master thesises:</label>
                    <Switch checked={state.masters_thesis} onChange={handleSwitch} name="masters_thesis" />
                    <br />
                    <br />

                    <label>Bedrijf naam</label>
                    <input value={state.company_name} onChange={handleChange} name="company_name" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf naam" />
                    <label>Bedrijf eigenaar</label>
                    <Modal openButtonValue="Nieuw" openButtonClasses="button button-m t-6 b-blue-400 c-white mr-l-2 pd-v-1 pd-h-1" boxClasses="container w-40 m-w-80 s-w-95 pd-t-5">
                        <M_NieuwBedrijfAccount reload={() => bedrijfOphalen()} BedrijfID={props.BedrijfID} />
                    </Modal>
                    <Select defaultValue={user} value={user} options={userOptions} isSearchable components={animatedComponents} onChange={setUser} placeholder="Bedrijf eigenaar" className="mr-b-4 mr-t-1" />
                    <label>Bedrijf rol</label>
                    <select onChange={handleChange} name="company_role" className="text-input pd-2 t-8">
                        <option value="base" selected={state.company_role === "base"}>
                            Standaard sponsor
                        </option>
                        <option value="recr" selected={state.company_role === "recr"}>
                            Recruitment
                        </option>
                        <option value="head" selected={state.company_role === "head"}>
                            Hoofdsponsor
                        </option>
                        <option value="adve" selected={state.company_role === "adve"}>
                            Niet-sponsor
                        </option>
                    </select>
                    <label>Bedrijf logo</label>
                    <br />
                    <input
                        id="file-1"
                        type="file"
                        onChange={(event) => {
                            // eslint-disable-next-line
                            setState({ ...state, ["company_logo"]: event.target.files[0] });
                        }}
                        className="inputfile inputfile-1 hidden"
                    />
                    <label for="file-1" class="mr-t-2 mr-b-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                        </svg>{" "}
                        <span>Upload logo</span>
                    </label>
                    <br />
                    <label>Bedrijf website</label>
                    <input value={state.company_url} onChange={handleChange} name="company_url" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf website" />
                    <label>Bedrijf intresses</label>
                    <Select defaultValue={optionsIncluded} className="mr-t-2" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />
                    <br />
                    <label>Korte beschrijving</label>
                    <textarea onChange={handleChange} name="company_short_desc" maxlength="250" className="text-input pd-2 t-8" rows="10" value={state.company_short_desc}></textarea>

                    <label>Lange beschrijving</label>
                    <br />
                    <br />
                    <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={state.company_long_desc}
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                            toolbar: "undo redo | formatselect | " + "bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | link image media | help",
                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            toolbar_mode: "floating"
                        }}
                    />

                    <input onClick={submit} type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
                </form>
                <div className="w-100 t-right">
                    <button onClick={verwijderBedrijf} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white">
                        <AiOutlineDelete />
                    </button>
                </div>
            </div>
        );
}

export default M_Bedrijf_Bewerk;
