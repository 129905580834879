import axios from "axios";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { config } from "../../../config";
import Switch from "@material-ui/core/Switch/Switch";
import { store } from "react-notifications-component";
import { Editor } from "@tinymce/tinymce-react";
import { AiOutlineDelete } from "react-icons/ai";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

function M_Stages_Bewerk(props) {
	const [item, setItem] = useState({});

	const [state, setState] = React.useState({
		active: false,
	});

	const editorRef = useRef(null);

	const handleSwitch = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	var link = config.api + "/vacancies/" + props.StageID;

	useEffect(() => {
		function itemOphalen(getReq) {
			axios
				.get(getReq)
				.then((res) => {
					for (var i = 0; i < options.length; i++) {
						var obj = options[i].value;

						if (res.data.interests.includes(obj)) {
							optionsIncluded.push(options[i]);
						}
						res.data["defaultInterests"] = optionsIncluded;
						setIntresses(optionsIncluded);
					}
					setItem({
						title: res.data.title,
						advertisement_short_desc: res.data.advertisement_short_desc,
						advertisement_long_desc: res.data.advertisement_long_desc,
					});
					setState({ ...state, active: res.data.active });
				})
				.catch((err) => {
					//console.log(err)
				});
		}

		itemOphalen(link);
		// eslint-disable-next-line
	}, [link]);

	function submit(e) {
		e.preventDefault();

		var PushIntr = [];
		if (intresses.length !== 0) {
			for (let i of intresses) {
				PushIntr.push(i.value);
			}
		}

		let values = {
			...item,
			type: "I",
			active: state.active,
			interests: PushIntr,
			advertisement_long_desc: editorRef.current.getContent(),
		};

		if (!values.title) {
			store.addNotification({
				title: "Bewerken mislukt!",
				message: "Stage titel kan niet leeg zijn!",
				type: "danger",
				insert: "top",
				container: "top-center",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
				},
			});
			return;
		}
		if (values.title.includes("/")) {
			store.addNotification({
				title: "Bewerken mislukt!",
				message: 'Titel mag geen "/" bevatten!',
				type: "danger",
				insert: "top",
				container: "top-center",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
				},
			});
			return;
		}

		axios
			.patch(config.api + "/vacancies/" + props.StageID, values)
			.then(function (response) {
				store.addNotification({
					title: "Bewerken gelukt!",
					message: "Aanpassingen zijn doorgevoerd!",
					type: "default",
					insert: "top",
					container: "top-center",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
					},
				});
				props.search();
			})
			.catch(function (error) {
				store.addNotification({
					title: "Bewerken mislukt!",
					message: "Het bewerken is niet gelukt!",
					type: "danger",
					insert: "top",
					container: "top-center",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
					},
				});
			});
	}

	const options = [
		{ value: "elektromech", label: "Elektromechanica" },
		{ value: "bouw", label: "Bouwkunde" },
		{ value: "chem", label: "Chemie" },
		{ value: "infor", label: "Informatica" },
		{ value: "eict", label: "Elektronica-ICT" },
		{ value: "auto", label: "Automatisering" },
		{ value: "land", label: "Landmeten" },
		{ value: "etech", label: "Elektrotechniek" },
	];
	var optionsIncluded = useMemo(() => {
		return [];
	}, []);

	const [intresses, setIntresses] = useState();

	// company name
	// company desc
	function handleChange(e) {
		const name = e.target.getAttribute("name");
		const value = e.target.value;
		setItem({ ...item, [name]: value });
	}

	function verwijderAd() {
		if (window.confirm("Bevestig het verwijderen van " + item.title + ".")) {
			axios
				.delete(config.api + "/vacancies/" + props.StageID)
				.then(function (response) {
					props.search();
				})
				.catch((err) => {
					//console.log(err)
				});
		} else {
		}
	}
	let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

	if (claims === null || claims.recruitment_privilege === false) {
		window.location.replace("/");
	} else
		return (
			<div className="container w-85 xl-w-90 m-w-100 mr-v-10">
				<h2 className="t-center c-gray-700-90 mr-v-5">STAGE BEWERKEN - {item.title}</h2>
				<form>
					<label>Actief</label>
					<Switch checked={state.active} onChange={handleSwitch} name="active" />
					<br />
					<br />
					<label>Stage titel</label>
					<input value={item.title} onChange={handleChange} name="title" type="text" className="text-input pd-2 t-8" placeholder="Stage titel" />
					<label>Stage intresses</label>
					<Select defaultValue={optionsIncluded} className="mr-t-2" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />
					<br />
					<label>Stage korte beschrijving</label>
					<textarea value={item.advertisement_short_desc} onChange={handleChange} name="advertisement_short_desc" className="text-input pd-2 t-8" rows="10"></textarea>
					<label>Stage lange beschrijving</label>
					<br />
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						initialValue={item.advertisement_long_desc}
						init={{
							height: 500,
							menubar: true,
							plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
							toolbar: "undo redo | formatselect | " + "bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | link image media | help",
							content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
							toolbar_mode: "floating",
						}}
					/>
					<input onClick={submit} type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
				</form>
				<div className="w-100 t-right">
					<button onClick={verwijderAd} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white">
						<AiOutlineDelete />
					</button>
				</div>
			</div>
		);
}

export default M_Stages_Bewerk;
