import { config } from '../config'
import React, {  useEffect } from 'react'
import { store } from 'react-notifications-component';
import { Helmet } from 'react-helmet';

function P_Logout() {


  useEffect(() => {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('token') === null || localStorage.getItem('refresh') === null || localStorage.getItem('claims') === null) {
        throw new Error("loggedin");
      }
      localStorage.clear();
      window.location.assign(config.base);
    
    }).catch((error) => {
      window.location.assign(config.base);
    });
  }, []);

  return (

    <div className="logout ">

      <Helmet>
        <title>HR - Logout</title>
      </Helmet>



      
    </div>
  );
}

export default P_Logout;
