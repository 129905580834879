import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import Bedrijf from "../../components/bedrijf/bedrijf";


function P_Bedrijf_id(props) {

  
  function renderNav(){
    if(props.location.pathname.includes("student")){
      return <Navigatie type="studenten" />
    }else
    if(props.location.pathname.includes("bedrijf")){
      return <Navigatie type="bedrijven" />
    }else
    if(props.location.pathname.includes("paneel")){
      return <Navigatie type="other" />
    }
    else{
      return <Navigatie type="home" />
    }
  }

  return (
    <div>
      {renderNav()}
      
      <Bedrijf type={props.location.pathname.includes("student") ? "/student" : props.location.pathname.includes("/bedrijf") ? "bedrijf" : "paneel"} BedrijfId={props.match.params.id}/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Bedrijf_id;
