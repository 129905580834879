import axios from "axios";
import { config } from "../../config";
import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { store } from "react-notifications-component";
function M_WachtwoordVergeten() {
    const [mail, setMail] = useState("");

    function sendMail() {
        let data = {
            email: mail
        };
        axios
            .post(config.api + "/auth/reset-password/request", data)
            .then(() => {
                store.addNotification({
                    title: "Succes!",
                    message: "Er is een mail gestuurd om uw wachtwoord te wijzigen!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            })
            .catch(() => {
                store.addNotification({
                    title: "Oeps, probeer openieuw!",
                    message: "Het e-mailadres dat u opgaf is niet gevonden!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            });
    }

    return (
        <div className="">
            <Helmet>
                <title>HR - Wachtwoord Vergeten</title>
            </Helmet>

            <div className="container w-90 m-w-95 s-w-98 mr-b-10">
                <p>Geef het E-mailadres op van uw account, dan sturen wij een mail om uw wachtwoord te resetten.</p>
                <input onChange={(e) => setMail(e.target.value)} value={mail} type="mail" className="text-input" placeholder="E-mailadres" />

                <button
                    onClick={(e) => {
                        e.preventDefault();
                        sendMail();
                    }}
                    className="button button-m b-black c-white fw-700">
                    STUUR MAIL
                </button>
            </div>
        </div>
    );
}

export default M_WachtwoordVergeten;
