import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../config";
import BreadCrumb from "../components/breadcrumb";
import ImageZoom from "../components/imageZoom";
function P_JSEvent(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Job & Stage Event" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Job & Stage Event" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Job & Stage Event" />;
        }
    }

    return (
        <div className="mr-t-30">
            <Helmet>
                <title>HR - Job & Stage Event</title>
            </Helmet>
            {renderNav()}
            <div className="container w-65 xl-w-75 m-w-90">
                {renderBreadCrumb()}
                <div className="t-center mr-t-10">
                    <img src={config.base + "/images/jse_cover.jpg"} alt="Hermes Job & Stage Event" className="w-40 border-r-10 s-w-100" />
                    <h1 className="c-gray-500 t-25 mr-t-10">Job & Stage Event</h1>

                    <br />
                    <center>
                        <div className="mouse mr-v-5">
                            <div className="roll"></div>
                            <div className="rollshadow"></div>
                        </div>
                    </center>
                </div>
                <div className="lh-13 mr-t-15 mr-b-15">
                    <p>
                        Hermes Recruitment nodigt u uit op de jaarlijks Hermes Job & Stage Event. Deze zal fysiek plaatsvinden in het ICC, wij zullen 130+ bedrijven hebben die voor u zullen klaarstaan. De volgende editie van het Job & Stage Event is op <b>6/03/2025</b>
                    </p>
                    <h2 className="mr-t-6 mr-b-3">Welke studenten kunnen hieraan deelnemen?</h2>
                    <ul className="pd-l-4">
                        <li>Studenten die op zoek zijn naar een bedrijf om hun bachelor/master-stage bij te doen</li>
                        <li>Studenten die op zoek zijn naar een eerste werkplek</li>
                        <li>Studenten die nog niet afstuderen maar wel al eens een kijkje willen nemen naar de mogelijkheden binnen hun branche en hun eerste contacten wensen te leggen binnen de bedrijfswereld.</li>
                    </ul>
                    <br />
                    <p>
                        <strong className="c-blue-4OO">Studenten</strong> kunnen zich inschrijven via{" "}
                        <a href={config.base + "/student/wefynd"} className="link c-blue-400">
                            hier
                        </a>
                        .
                    </p>
                    <br />
                    Wij richten ons voornamelijk op de studenten van de opleiding Industrieel Ingenieur aan de Universiteit Gent (afdeling Gent en Kortrijk) alsook de studenten van de gerelateerde professionele bachelors aan de Hogeschool Gent.
                    <br />
                    <h2 className="mr-t-6 mr-b-3">Vragen of ondervindt u een probleem?</h2>
                    U kunt ons altijd contacteren op een van onderstaande manieren, wij proberen u zo snel mogelijk verder te helpen:
                    <br />
                    <br />
                    Mail ons :{" "}
                    <a href="mailto:recruitment@hermesgent.be" className="button link c-blue-400">
                        recruitment@hermesgent.be
                    </a>
                    <br />
                    Stuur ons een bericht via{" "}
                    <a href="https://www.facebook.com/HermesRecruit" className="button link c-blue-400">
                        Facebook (Hermes Recruitment)
                    </a>
                    <br />
                    <br />
                    <h2>We bieden volgende richtingen aan:</h2>
                    <br />
                    <div className="jslist mr-t-2">
                        <ul>
                            <li>
                                <b>Universiteit Gent</b>
                                <ul>
                                    <li>
                                        Industrieel Wetenschappen:
                                        <ul>
                                            <li>
                                                Elektronica
                                                <ul>
                                                    <li>Elektronica</li>
                                                    <li>ICT</li>
                                                    <li>Ingebedde systemen</li>
                                                </ul>
                                            </li>
                                            <li>
                                                Elektromechanica
                                                <ul>
                                                    <li>Elektromechanica</li>
                                                    <li>Elektrotechniek</li>
                                                    <li>Automatisatie</li>
                                                </ul>
                                            </li>
                                            <li>Informatica</li>
                                            <li>Chemie</li>
                                            <li>
                                                Bouwkunde
                                                <ul>
                                                    <li>Bouwkunde</li>
                                                    <li>Landmeten</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="mr-t-4">
                                <b>Hogeschool Gent</b>
                                <ul>
                                    <li>Toegepaste informatica</li>
                                    <li>Chemie</li>
                                    <li>Elektromechanica</li>
                                    <li>Landmeten</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <h2 className="mr-t-6 mr-b-3">Grondplan</h2>
					<h3 className="fw-400 c-gray-500-80">Gelijkvloers</h3>
					<ImageZoom src={config.base + "/images/grondplan_v_0.png"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-90 s-w-98 mr-b-10 m-mr-v-10" />
					<h3 className="fw-400 c-gray-500-80">Verdieping 1</h3>
					<ImageZoom src={config.base + "/images/grondplan_v_1.png"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-90 s-w-98 mr-b-10 m-mr-v-10" />
					<h3 className="fw-400 c-gray-500-80">Verdieping 2</h3>
					<ImageZoom src={config.base + "/images/grondplan_v_2.png"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-90 s-w-98 mr-b-10 m-mr-v-10" />
 */}
                    <h2 className="mr-t-6 mr-b-3">Waar?</h2>
                    <p>Het Job & Stage Event gaat door in het ICC in Gent.</p>
                    <div class="mapouter mr-t-4 mr-b-15">
                        <div class="gmap_canvas">
                            <iframe className="border-r-10" width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Gent%20ICC&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_JSEvent;
