import Navigatie from '../../components/navigaties'
import Footers from '../../components/footers'
import { Helmet } from 'react-helmet';
import { config } from '../../config'
import React, {useState, useEffect} from "react";
import axios from 'axios';


function P_Paneel() {

    const [bedrijven, setBedrijven] = useState([])
    const [advertenties, setAdvertenties] = useState([])
    

        useEffect(() => {
            bedrijvenOphalen(config.api + "/companies")
            advertentiesOphalen(config.api + "/vacancies")
        }, [])

        function bedrijvenOphalen(getReq){
            axios.get(getReq)
            .then(res => {
                setBedrijven(res.data)
                
            })
            .catch(err => {
                //console.log(err)
            })
        }

        function advertentiesOphalen(getReq){
            axios.get(getReq)
            .then(res => {
                setAdvertenties(res.data)
                    
            })
            .catch(err => {
                //console.log(err)
            })
        }

        var jobs = 0
        var stage = 0

        for(let ad of advertenties){
            if(ad.type === "J"){
                jobs += 1
            }else 
            if(ad.type === "I"){
                stage += 1
            }
        }
        
    let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

  if(claims === null || claims.recruitment_privilege === false){
    window.location.replace("/");
  }else
  return (
    <div className="Home ">
      <Helmet>
        <title>HR - Paneel</title>
      </Helmet>
      <Navigatie type="other"/>

      <div className="mr-v-30">
        <div className="container w-65 xl-w-80 m-w-90">
            <h1 className="t-center c-gray-700-90">PANEEL</h1>
            <div className="row mr-t-10">
                <div className="col-4 pd-r-2 m-col-12 m-pd-v-0 m-pd-h-0 m-mr-t-2">
                    <div className="box pd-10 t-center">
                        <h2 className="c-gray-700-90">BEDRIJVEN</h2><br/>
                        <span className="t-30 c-blue-400 fw-700">{bedrijven.length}</span>
                    </div>
                </div>
                <div className="col-4 pd-h-2 m-col-12 m-pd-v-0 m-pd-h-0 m-mr-t-2">
                    <div className="box pd-10 t-center">
                        <h2 className="c-gray-700-90">JOBS</h2><br/>
                        <span className="t-30 c-yellow-700 fw-700">{jobs}</span>
                    </div>
                </div>
                <div className="col-4 pd-l-2 m-col-12 m-pd-v-0 m-pd-h-0 m-mr-t-2">
                    <div className="box pd-10 t-center">
                        <h2 className="c-gray-700-90">STAGE</h2><br/>
                        <span className="t-30 c-yellow-700 fw-700">{stage}</span>
                    </div>
                </div>
            </div>
          </div>
      </div>
      
    <Footers type="main"/>
    </div>
  );
}

export default P_Paneel;
