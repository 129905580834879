import React, {useRef, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai'

function ImageZoom(props) {

    const modalRef = useRef()

    const [condition, setCondition] = useState(false);

    const closeModal = e => {
        if(modalRef.current === e.target){
            updateConditions()

        }
    }


    const updateConditions = () =>{
        setCondition(!condition);
    }

  return (
    <>
        {condition ? (
            <>
                <img onClick={() => updateConditions()} src={props.src} className={props.smallclass}/>
                <div className="modal-main" ref={modalRef} onClick={closeModal}>
                    <div className={props.boxclass}>
                        <div id="modal" className="box t-left modal-box">
                            <button className="modal-close mr-4 c-gray-700-60" onClick={() => updateConditions()}><AiOutlineClose/></button>
                            <div className="modal-content">
                                <img src={props.src} className={props.bigclass}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <img onClick={() => updateConditions()} src={props.src} className={props.smallclass}/>
        )}
    </>
    
  );
}


export default ImageZoom;
