import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import BreadCrumb from "../components/breadcrumb";
import { config } from "../config";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "../components/modal";
import M_MijnBedrijf_Bewerken from "../components/modals/account/mijnBedrijfBewerken";
import M_MijnBedrijf_Info_Bewerk from "../components/modals/account/mijnBedrijfInfoBewerken";
import M_MijnBedrijf_Job_Stage_Nieuw from "../components/modals/account/nieuweJobStage";
import M_MijnBedrijf_Job_Stage_Bewerk from "../components/modals/account/bewerkjobstage";
function P_MijnBedrijf(props) {
    const [company, setCompany] = useState({});
    const [jobs, setJobs] = useState([]);
    const [stages, setStages] = useState([]);
    const [loadingCompany, setLoadingCompany] = useState(true);
    const [loadingJS, setLoadingJS] = useState(true);

    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return (
                <BreadCrumb
                    prev={[
                        ["/student", "Home"],
                        ["/mijn-account", "Mijn account"]
                    ]}
                    now="Mijn bedrijf"
                />
            );
        } else if (props.location.pathname.includes("bedrijf")) {
            return (
                <BreadCrumb
                    prev={[
                        ["/bedrijf", "Home"],
                        ["/bedrijf/mijn-account", "Mijn account"]
                    ]}
                    now="Mijn bedrijf"
                />
            );
        } else {
            return (
                <BreadCrumb
                    prev={[
                        ["/", "Home"],
                        ["/mijn-account", "Mijn account"]
                    ]}
                    now="Mijn bedrijf"
                />
            );
        }
    }

    useEffect(() => {
        load();
    }, []);

    function load() {
        let uuid = props.match.params.uuid;
        axios
            .get(config.api + "/companies/" + uuid)
            .then((res) => {
                let data = res.data;
                if (data.company_owner?.id !== claims.user_id) {
                    window.location.replace(config.base + "/mijn-account");
                } else {
                    setCompany(res.data);
                    setLoadingCompany(false);
                    axios
                        .get(config.api + "/vacancies?owner=" + uuid)
                        .then((result) => {
                            let jobsData = [];
                            let stagesData = [];
                            for (let x of result.data) {
                                if (x.type === "I") {
                                    stagesData = [...stagesData, x];
                                    setStages(stagesData);
                                } else if (x.type === "J") {
                                    jobsData = [...jobsData, x];
                                    setJobs(jobsData);
                                }
                            }
                            setLoadingJS(false);
                        })
                        .catch(() => {});
                }
            })
            .catch(() => {});
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace(config.base + "/login");
    } else
        return (
            <div className="About mr-t-30">
                <Helmet>
                    <title>HR - Mijn Bedrijf</title>
                </Helmet>
                {renderNav()}
                {!loadingCompany ? (
                    <div className="container w-65 xl-w-75 m-w-90 mr-b-30">
                        {renderBreadCrumb()}
                        <div className="mr-t-5">
                            <div className="w-100 mr-v-10 t-center">
                                <img src={company?.company_logo} width="250px" />
                            </div>

                            <Modal openButtonValue="Mijn bedrijfspagina bewerken" openButtonClasses="button button-m b-black c-white hover hover-2 hover-b-black-80 fw-700 mr-r-2 s-w-100">
                                <M_MijnBedrijf_Bewerken BedrijfID={props.match.params.uuid} reload={() => load()} />
                            </Modal>
                            <Modal openButtonValue="Contactgegevens bewerken" openButtonClasses="button button-m b-black c-white hover hover-2 hover-b-black-80 fw-700 mr-r-2 s-w-100 s-mr-t-2">
                                <M_MijnBedrijf_Info_Bewerk BedrijfID={props.match.params.uuid} reload={() => load()} />
                            </Modal>
                        </div>
                        {company.active ? "" : <p className="mr-t-5 c-red">Uw bedrijf is momenteel niet actief, waardoor uw jobs & stages niet online staan.</p>}
                        <div className="row mr-t-5">
                            <div className="col-6 s-col-12 pd-r-5 s-pd-r-0">
                                <div className="box pd-5">
                                    <div className="flex horizontal-center">
                                        <h2 className="c-gray-500 flex-grow">JOBS</h2>

                                        <Modal openButtonValue="Nieuw" openButtonClasses="button link b-none c-gray-500-60 block pd-h-2 pd-v-1 hover hover-2 hover-b-gray-200-30 border-r-5">
                                            <M_MijnBedrijf_Job_Stage_Nieuw type="J" owner={company.id} reload={() => load()} />
                                        </Modal>
                                    </div>
                                    {!loadingJS ? (
                                        <>
                                            {jobs.map((job) => {
                                                return (
                                                    <div key={job.id} className="pd-3 border-gray-200-50 border-r-5 mr-t-2 row">
                                                        <div className="w-100 flex c-gray-500 horizontal-center">
                                                            <a target="_blank" className="button link b-none c-blue-400 flex-grow" href={config.base + "/student/jobs/" + job.title.replace(/\s+/g, "-").toLowerCase() + "/" + job.id}>
                                                                <div className={"indicator mr-r-1 " + (job.active ? "b-mint" : "b-red")}></div>
                                                                {job.title}
                                                            </a>

                                                            <Modal openButtonValue="Bewerk" openButtonClasses="button link b-none c-gray-500-60 block pd-h-2 pd-v-1 hover hover-2 hover-b-gray-200-30 border-r-5">
                                                                <M_MijnBedrijf_Job_Stage_Bewerk type="J" uuid={job.id} reload={() => load()} />
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="col-6 s-col-12 pd-l-5 s-pd-l-0 s-pd-t-5">
                                <div className="box pd-5">
                                    <div className="w-100 flex horizontal-center">
                                        <h2 className="c-gray-500 flex-grow">STAGES</h2>

                                        <Modal openButtonValue="Nieuw" openButtonClasses="button link b-none c-gray-500-60 block pd-h-2 pd-v-1 hover hover-2 hover-b-gray-200-30 border-r-5">
                                            <M_MijnBedrijf_Job_Stage_Nieuw type="I" owner={company.id} reload={() => load()} />
                                        </Modal>
                                    </div>
                                    {!loadingJS ? (
                                        <>
                                            {stages.map((stage) => {
                                                return (
                                                    <div key={stage.id} className="pd-3 border-gray-200-50 border-r-5 mr-t-2 row">
                                                        <div className="w-100 flex c-gray-500">
                                                            <a target="_blank" className="button link b-none c-blue-400 flex-grow" href={config.base + "/student/stages/" + stage.title.replace(/\s+/g, "-").toLowerCase() + "/" + stage.id}>
                                                                <div className={"indicator mr-r-1 " + (stage.active ? "b-mint" : "b-red")}></div>
                                                                {stage.title}
                                                            </a>

                                                            <Modal openButtonValue="Bewerk" openButtonClasses="button link b-none c-gray-500-60 block pd-h-2 pd-v-1 hover hover-2 hover-b-gray-200-30 border-r-5">
                                                                <M_MijnBedrijf_Job_Stage_Bewerk type="I" uuid={stage.id} reload={() => load()} />
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <Footers type="main" />
            </div>
        );
}

export default P_MijnBedrijf;
