import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { config } from "../../config";
import { AiOutlineEdit } from "react-icons/ai";
import { RiContactsBook2Line } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import Modal from "../../components/modal";
import M_Bedrijf_Nieuw from "../modals/bedrijf/nieuw";
import M_Bedrijf_Bewerk from "../modals/bedrijf/bewerk";
import M_Bedrijf_Info_Bewerk from "../modals/bedrijf/bewerk-info";

const animatedComponents = makeAnimated();
function BedrijfLijst(props) {
    const [bedrijven, setBedrijven] = useState([]);

    useEffect(() => {
        bedrijvenOphalen(config.api + "/companies");
    }, []);

    function bedrijvenOphalen(getReq) {
        axios
            .get(getReq)
            .then((res) => {
                let push = [];
                console.log(res.data);
                res.data.map((item) => {
                    if (props.isAdmin) {
                        if (activeFilter == 0 || (activeFilter == 1 && !item.active) || (activeFilter == 2 && item.active)) {
                            if (roleFilter == 0 || roleFilter == item.company_role) {
                                push.push(item);
                            }
                        }
                    } else {
                        if (item.active) push.push(item);
                    }
                });
                setBedrijven(push);
                setPag(0);
                setLoading(1);
            })
            .catch((err) => {
                //console.log(err)
            });
    }

    const options = [
        { value: "elektromech", label: "Elektromechanica" },
        { value: "bouw", label: "Bouwkunde" },
        { value: "chem", label: "Chemie" },
        { value: "infor", label: "Informatica" },
        { value: "eict", label: "Elektronica-ICT" },
        { value: "auto", label: "Automatisering" },
        { value: "land", label: "Landmeten" },
        { value: "etech", label: "Elektrotechniek" }
    ];

    function createIntresseParam(i) {
        let first = true;
        let s = "interests=";
        for (let x of i) {
            if (first) {
                s += x;
            } else {
                s += "&interests=" + x;
            }
            first = false;
        }
        return s;
    }

    const [titel, setTitel] = useState("");
    const [intresses, setIntresses] = useState([]);
    const [loading, setLoading] = useState(0);

    function Search() {
        setLoading(0);
        var searchIntresses = [];
        for (let i = 0, len = intresses.length; i < len; i++) {
            searchIntresses.push(intresses[i].value);
        }

        if (titel === "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
            bedrijvenOphalen(config.api + "/companies");
        } else if (titel !== "" && (searchIntresses.length === 0 || searchIntresses.length === undefined)) {
            //console.log("test")
            bedrijvenOphalen(config.api + "/companies?company_name=" + titel);
        } else if (titel === "" && searchIntresses.length > 0) {
            //console.log(config.api + "/companies?" + createIntresseParam(searchIntresses))
            bedrijvenOphalen(config.api + "/companies?" + createIntresseParam(searchIntresses));
        } else {
            bedrijvenOphalen(config.api + "/companies?company_name=" + titel + "&" + createIntresseParam(searchIntresses));
        }
    }

    function handleChange(event) {
        setTitel(event.target.value);
    }

    function _handleKeyDown(e) {
        if (e.key === "Enter") {
            Search();
        }
    }

    const [pag, setPag] = useState(0);
    const itemsPerPag = 20;

    function pagination(richting) {
        if (richting === "up") {
            setPag(pag + itemsPerPag);
            window.scrollTo(0, 0);
        } else if (richting === "down") {
            if (pag - itemsPerPag < 0) {
                setPag(0);
            } else {
                setPag(pag - itemsPerPag);
            }
        }
        window.scrollTo(0, 0);
    }
    function paginationRenderButtons(length) {
        let arr = [];

        if (pag / itemsPerPag > 2) {
            arr.push(
                <span onClick={() => updatePagination(0)} className="c-gray-700-70 pd-1 button">
                    1 ...
                </span>
            );
        }

        for (let i = 0; i < length; i = i + itemsPerPag) {
            if (i === pag) {
                arr.push(
                    <span onClick={() => updatePagination(i)} className="b-blue-400 c-white pd-1 pd-h-2 button border-r-10">
                        {i / itemsPerPag + 1}
                    </span>
                );
            } else if (pag - 2 * itemsPerPag <= i && i <= pag + 2 * itemsPerPag) {
                arr.push(
                    <span onClick={() => updatePagination(i)} className="c-gray-700-70 pd-1 button">
                        {i / itemsPerPag + 1}
                    </span>
                );
            }
        }

        if (pag / itemsPerPag < Math.ceil(length / itemsPerPag) - 3) {
            arr.push(
                <span onClick={() => updatePagination(Math.ceil(length / itemsPerPag) * itemsPerPag - itemsPerPag)} className="c-gray-700-70 pd-1 button">
                    ... {Math.ceil(length / itemsPerPag)}
                </span>
            );
        }
        return (
            <span>
                {arr.map((item) => {
                    return <span>{item}</span>;
                })}
            </span>
        );
    }
    function updatePagination(number) {
        window.scrollTo(0, 0);
        setPag(parseInt(number));
    }

    const [activeFilter, setActiveFilter] = useState(0);
    const [roleFilter, setRoleFilter] = useState(0);

    useEffect(() => {
        Search();
    }, [activeFilter, roleFilter]);

    return (
        <section className="mr-b-30">
            <div className="container w-65 xl-w-80 m-w-90">
                <div className="row mr-t-10">
                    <div className="col-4 s-col-12">
                        {props.isAdmin ? (
                            <>
                                <Modal openButtonValue={<IoMdAdd />} openButtonClasses="button pd-h-4 pd-v-2 b-blue-400-10 t-9 c-blue-400 hover hover-1 hover-b-blue-400 hover-c-white mr-b-2">
                                    <M_Bedrijf_Nieuw search={Search} />
                                </Modal>
                                <a href={config.base + "/paneel/bedrijven-info"} className="button pd-h-4 pd-v-2 b-blue-400-10 t-9 c-blue-400 hover hover-1 hover-b-blue-400 hover-c-white fw-500 mr-b-2 mr-l-2">
                                    BEDRIJVEN INFO
                                </a>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="col-8 pd-l-6 s-col-12 s-pd-l-0 m-p-t-10">
                        <h1 className="t-14 c-gray-500 mr-b-5">BEDRIJVEN {props.isAdmin ? <>: {bedrijven.length}</> : <></>}</h1>

                        {itemsPerPag < bedrijven.length ? (
                            <div className="c-gray-700-70 pd-v-3 border-r-5 mr-t-4 fw-700 t-center">
                                {pag !== 0 ? (
                                    <>
                                        <button onClick={() => pagination("down")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-r-3">
                                            &#60;
                                        </button>
                                    </>
                                ) : (
                                    ""
                                )}

                                {paginationRenderButtons(bedrijven.length)}
                                {pag + itemsPerPag < bedrijven.length ? (
                                    <>
                                        <button onClick={() => pagination("up")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-l-3">
                                            &#62;
                                        </button>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 s-col-12 m-pd-b-5">
                        <div className="box">
                            <p className="c-gray-700 fw-700 pd-v-3">FILTER & ZOEKEN</p>
                            <input onKeyDown={_handleKeyDown} onChange={handleChange} name="search" type="text" className="text-input border-gray-100 c-blue-400" placeholder="zoek..." />
                            <p className="c-gray-700 fw-700 pd-v-2">INTERESSES</p>
                            <Select onKeyDown={_handleKeyDown} onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />

                            <button onClick={Search} className="button button-m b-blue-400 fw-700 c-white mr-v-6 pd-h-6">
                                ZOEK
                            </button>
                        </div>
                        {props.isAdmin ? (
                            <div className="box mr-t-3">
                                <h4 className="pd-v-3">PANEEL FILTERS:</h4>
                                <p className="c-gray-500 t-7">Filter op actief:</p>
                                <select onChange={(e) => setActiveFilter(e.target.value)} className="text-input border-gray-100 c-blue-400">
                                    <option value="0">Alle bedrijven</option>
                                    <option value="1">Niet actieve bedrijven</option>
                                    <option value="2">Actieve bedrijven</option>
                                </select>

                                <p className="c-gray-500 t-7">Filter op rol:</p>
                                <select onChange={(e) => setRoleFilter(e.target.value)} className="text-input border-gray-100 c-blue-400">
                                    <option value="0">Alle bedrijven</option>
                                    <option value="base">Standaard sponsors</option>
                                    <option value="recr">Recruitment</option>
                                    <option value="head">Hoofdsponsors</option>
                                    <option value="adve">Niet-sponsors</option>
                                </select>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-8 pd-l-6 s-col-12 s-pd-l-0 s-p-t-10">
                        {loading !== 0 ? (
                            bedrijven.slice(pag, pag + itemsPerPag).map((bedrijf) => {
                                if (bedrijf.active || props.isAdmin) {
                                    return (
                                        <div className="box mr-b-1" key={bedrijf.id}>
                                            <div className="row pd-v-5">
                                                <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                                    <a href={"bedrijven/" + bedrijf.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + bedrijf.id}>
                                                        <img src={bedrijf.company_logo} alt={bedrijf.company_name} className="w-100 s-w-40" />
                                                    </a>
                                                </div>
                                                <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                                    <a className="button link c-none b-none" href={"bedrijven/" + bedrijf.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + bedrijf.id}>
                                                        <p className="fw-700 c-gray-700 t-9 s-pd-l-2">{bedrijf.company_name}</p>
                                                        <div className="pd-t-2 text_comp pd-r-10 c-gray-500 s-pd-l-2">{bedrijf.company_short_desc.length > 0 ? bedrijf.company_short_desc.substring(0, 250) : "..."}</div>
                                                    </a>
                                                    {props.isAdmin ? (
                                                        <>
                                                            <div className="t-right">
                                                                <Modal className="t-left" openButtonValue={<RiContactsBook2Line />} openButtonClasses="button link t-14 c-gray-700-40 fw-700 mr-b-0 b-none">
                                                                    <M_Bedrijf_Info_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                                                </Modal>
                                                                <Modal className="t-left" openButtonValue={<AiOutlineEdit />} openButtonClasses="button link b-none t-14 c-gray-700-40 fw-700 mr-2 mr-b-0">
                                                                    <M_Bedrijf_Bewerk search={Search} BedrijfID={bedrijf.id} />
                                                                </Modal>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return <></>;
                                }
                            })
                        ) : (
                            <div>
                                <div className="box mr-b-1">
                                    <div className="row pd-v-5">
                                        <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                            <div className="loading loading_img"></div>
                                        </div>
                                        <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                            <p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box mr-b-1">
                                    <div className="row pd-v-5">
                                        <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                            <div className="loading loading_img"></div>
                                        </div>
                                        <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                            <p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box mr-b-1">
                                    <div className="row pd-v-5">
                                        <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                            <div className="loading loading_img"></div>
                                        </div>
                                        <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                            <p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box mr-b-1">
                                    <div className="row pd-v-5">
                                        <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                            <div className="loading loading_img"></div>
                                        </div>
                                        <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                            <p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box mr-b-1">
                                    <div className="row pd-v-5">
                                        <div className="col-3 pd-h-5 s-col-12 s-t-center">
                                            <div className="loading loading_img"></div>
                                        </div>
                                        <div className="col-9 pd-l-10  s-col-12 s-pd-l-0 s-pd-t-10">
                                            <p className="fw-700 t-9 s-pd-l-2 loading loading_title"></p>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                            <div className="pd-r-10 s-pd-l-2 loading loading_p"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {itemsPerPag < bedrijven.length ? (
                            <div className="c-gray-700-70 pd-v-3 border-r-5 mr-t-4 fw-700 t-center">
                                {pag !== 0 ? (
                                    <>
                                        <button onClick={() => pagination("down")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-r-3">
                                            &#60;
                                        </button>
                                    </>
                                ) : (
                                    ""
                                )}

                                {paginationRenderButtons(bedrijven.length)}
                                {pag + itemsPerPag < bedrijven.length ? (
                                    <>
                                        <button onClick={() => pagination("up")} className="button link b-none c-gray-700-30 fw-700 t-10 pd-l-3">
                                            &#62;
                                        </button>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BedrijfLijst;
