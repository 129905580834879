import axios from "axios";
import { config } from "../config";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

import { store } from "react-notifications-component";
function P_ResetWachtwoord(props) {
    useEffect(() => {}, []);

    const [showpass, setShowPass] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [newPass2, setNewPass2] = useState("");

    function togglePassVisible(e, now) {
        e.preventDefault();
        setShowPass(!now);
    }

    function resetPass() {
        if (newPass !== newPass2) {
            store.addNotification({
                title: "Oeps!",
                message: "De opgegeven wachtwoorden zijn niet gelijk!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        let token = props.match.params.token;
        let data = {
            token: token,
            password: newPass,
            password2: newPass2
        };

        axios
            .patch(config.api + "/auth/reset-password", data)
            .then(() => {
                store.addNotification({
                    title: "Succes!",
                    message: "Wachtwoord succesvol gewijzigd, U wordt doorgestuurd om opnieuw in te loggen!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    new Promise((resolve, reject) => {
                        if (localStorage.getItem("token") === null || localStorage.getItem("refresh") === null || localStorage.getItem("claims") === null) {
                            throw new Error("loggedin");
                        }
                        localStorage.clear();
                    }).catch((error) => {});
                    window.location.assign(config.base + "/login");
                }, 5000);
            })
            .catch(() => {
                store.addNotification({
                    title: "Oeps!",
                    message: "Wachtwoord wijzigen mislukt, de link is waarschijnlijk vervallen!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            });
    }

    return (
        <div className="login h-100-vh">
            <Helmet>
                <title>HR - Wijzig Wachtwoord</title>
            </Helmet>

            <div className="container w-40 l-w-60 m-w-80 s-w-98 pd-v-25 m-pd-v-10">
                <h1 className="t-center c-white mr-b-10">WACHTWOORD WIJZIGEN</h1>
                <div className="box border-r-20 pd-10 m-pd-5">
                    <label htmlFor="pass">Nieuw wachtwoord:</label> <br />
                    <div className="w-100 relative">
                        <span data-title={!showpass ? "Toon wachtwoord" : "Verstop wachtwoord"} className="button link b-none input-icon t-12 c-gray-300" onClick={(e) => togglePassVisible(e, showpass)}>
                            {!showpass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </span>
                    </div>
                    <input value={newPass} onChange={(e) => setNewPass(e.target.value)} type={showpass ? "text" : "password"} name="pass" id="pass" className="text-input relative" placeholder="************" />
                    <label htmlFor="pass">Herhaal nieuw wachtwoord:</label> <br />
                    <div className="w-100 relative">
                        <span data-title={!showpass ? "Toon wachtwoord" : "Verstop wachtwoord"} className="button link b-none input-icon t-12 c-gray-300" onClick={(e) => togglePassVisible(e, showpass)}>
                            {!showpass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </span>
                    </div>
                    <input value={newPass2} onChange={(e) => setNewPass2(e.target.value)} type={showpass ? "text" : "password"} name="pass" id="pass" className="text-input relative" placeholder="************" />
                    <button onClick={() => resetPass()} className="button button-m b-black c-white fw-700">
                        AANPASSEN
                    </button>
                </div>
            </div>
        </div>
    );
}

export default P_ResetWachtwoord;
