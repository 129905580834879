import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { config } from "../../config";

function P_JS_Inschrijven_Voltooid() {
    let history = useHistory();

    return (
        <div className="login">
            <Helmet>
                <title>HR - J&S - Inschrijven - Gelukt</title>
            </Helmet>

            <div className="row">
                <div className="box container w-75 m-w-95 mr-v-20 pd-10 h-100-vh">
                    <div className="container w-100 mr-t-8">
                        <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
                            <AiOutlineArrowLeft />
                        </button>
                    </div>
                    <div className="t-center mr-b-10 mr-t-5">
                        <img src={config.base + "/images/hermes_rec.png"} alt="Hermes Recruitment" className="hr-login-logo" />
                        <br />
                        <h1 className="pd-t-5 fw-900 c-black-60 t-12">INSCHRIJVEN VOOR JOB & STAGE EVENT SUCCESVOL GELUKT</h1>
                    </div>
                    <div className="container w-70 m-w-90 t-10">
                        <p>Bedankt voor uw inschrijving, wij contacteren u in de loop van de volgende dagen.</p>
                        <br />
                        <p className="c-red">
                            (Let op: deze inschrijvingsform is geen zekerheid tot deelname aan ons Job & Stage Event 2025, <b>pas na bevestiging door ons</b> bent u zeker van een plaats op onze beurs)
                        </p>
                        <div className="t-center mr-t-10">
                            <a href="/" className="button button-m b-white c-blue-400 border-blue-400 border-r-8 fw-700 hover hover-1 hover-b-blue-400 hover-c-white t-9">
                                BEKIJK ONZE WEBSITE
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default P_JS_Inschrijven_Voltooid;
