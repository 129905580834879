import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { store } from "react-notifications-component";

function M_MijnBedrijf_Info_Bewerk(props) {
    const [bedrijf, setBedrijf] = useState({ interests: [] });
    const [bedrijfInfo, setBedrijfInfo] = useState({});

    var link = config.api + "/companies/" + props.BedrijfID;

    useEffect(() => {
        function bedrijfOphalen(getReq) {
            axios
                .get(getReq)
                .then((res) => {
                    setBedrijf(res.data);
                })
                .catch((err) => {
                    //console.log(err)
                });
        }

        bedrijfOphalen(link);
        // eslint-disable-next-line
    }, [link]);

    useEffect(() => {
        function bedrijfInfoOphalen(getReq) {
            axios
                .get(getReq)
                .then((res) => {
                    setBedrijfInfo(res.data);
                    //console.log(bedrijfInfo)
                })
                .catch((err) => {
                    //console.log(err)
                });
        }

        bedrijfInfoOphalen(bedrijf.contact_information);
        // eslint-disable-next-line
    }, [bedrijf.contact_information]);

    function handleChange(e) {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setBedrijfInfo({ ...bedrijfInfo, [name]: value });
    }

    // company name
    // company desc

    function submit(e) {
        e.preventDefault();
        let values = bedrijfInfo;
        delete values.link;
        delete values.id;
        //console.log(values)

        axios
            .patch(config.api + "/companies/" + bedrijf.id + "/contact", values, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(function (response) {
                store.addNotification({
                    title: "Bewerken gelukt!",
                    message: "Het bewerken van de bedrijfs info is gelukt!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
                props.reload();
            })
            .catch(function (error) {
                store.addNotification({
                    title: "Bewerken mislukt!",
                    message: "De pagina wordt herladen!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            });
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-85 xl-w-90 m-w-100 mr-v-10">
                <div className="t-center">
                    <img src={bedrijf.company_logo} alt={bedrijf.company_name} width="250px" />
                </div>
                <h2 className="t-center c-gray-700-80 mr-v-5">{bedrijf.company_name} - info bewerken</h2>
                <form>
                    <h2 className="c-gray-700-60 mr-b-5">Facturatie gegevens</h2>

                    <label>Facturatie bedrijfsnaam</label>
                    <input value={bedrijfInfo.contact_company_name} onChange={handleChange} name="contact_company_name" type="text" className="text-input pd-2 t-8" placeholder="Facturatie bedrijfsnaam" />

                    <label>Facturatie email</label>
                    <input value={bedrijfInfo.contact_billingmail} onChange={handleChange} name="contact_billingmail" type="email" className="text-input pd-2 t-8" placeholder="Facturatie email" />

                    <label>BTW nummer</label>
                    <input value={bedrijfInfo.contact_btw} onChange={handleChange} name="contact_btw" type="text" className="text-input pd-2 t-8" placeholder="BTW nummer" />

                    <label>Adres gegevens</label>
                    <div className="row">
                        <div className="col-9 s-col-12">
                            <input value={bedrijfInfo.contact_street} onChange={handleChange} name="contact_street" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Straat" />
                        </div>
                        <div className="col-3 pd-l-1 s-col-12 s-pd-l-0">
                            <input value={bedrijfInfo.contact_number} onChange={handleChange} name="contact_number" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Huisnummer (Bus)" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 s-col-12 s-pd-t-2">
                            <input value={bedrijfInfo.contact_city} onChange={handleChange} name="contact_city" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Stad/Gemeente" />
                        </div>
                        <div className="col-2 pd-h-1 s-col-12 s-pd-h-0">
                            <input value={bedrijfInfo.contact_zipcode} onChange={handleChange} name="contact_zipcode" type="number" className="text-input pd-2 t-8 mr-b-0" placeholder="Postcode" />
                        </div>
                        <div className="col-3 s-col-12 ">
                            <input value={bedrijfInfo.contact_country} onChange={handleChange} name="contact_country" type="text" className="text-input pd-2 t-8" placeholder="Land" />
                        </div>
                    </div>

                    <h2 className="c-gray-700-60 mr-v-5">Contactpersoon gegevens</h2>

                    <label>Voornaam</label>
                    <input value={bedrijfInfo.contact_firstname} onChange={handleChange} name="contact_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />

                    <label>Achternaam</label>
                    <input value={bedrijfInfo.contact_lastname} onChange={handleChange} name="contact_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />

                    <label>Email</label>
                    <input value={bedrijfInfo.contact_mail} onChange={handleChange} name="contact_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />

                    <label>Telefoonnummer</label>
                    <input value={bedrijfInfo.contact_telephonenumber} onChange={handleChange} name="contact_telephonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 0 000 00 00" />

                    <label>Gsmnummer</label>
                    <input value={bedrijfInfo.contact_cellphonenumber} onChange={handleChange} name="contact_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                    <label>Functie binnen bedrijf</label>
                    <input value={bedrijfInfo.contact_person_function} onChange={handleChange} name="contact_person_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                    <h2 className="c-gray-700-60 mr-b-5 mr-t-4">Vertegenwoordigers</h2>
                    <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 1</h3>

                    <div className="row">
                        <div className="col-6 s-col-12">
                            <label>Voornaam</label>
                            <input value={bedrijfInfo.contact_representative1_firstname} onChange={handleChange} name="contact_representative1_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />
                        </div>
                        <div className="col-6 pd-l-1 s-col-12 s-pd-l-0">
                            <label>Achternaam</label>
                            <input value={bedrijfInfo.contact_representative1_lastname} onChange={handleChange} name="contact_representative1_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 s-col-12">
                            <label>E-mailadres</label>
                            <input value={bedrijfInfo.contact_representative1_mail} onChange={handleChange} name="contact_representative1_mail" type="email" className="text-input pd-2 t-8" placeholder="E-mailadres" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Telefoonnummer</label>
                            <input value={bedrijfInfo.contact_representative1_cellphonenumber} onChange={handleChange} name="contact_representative1_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="Telefoonnummer" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Functie binnen bedrijf</label>
                            <input value={bedrijfInfo.contact_representative1_function} onChange={handleChange} name="contact_representative1_function" type="tel" className="text-input pd-2 t-8" placeholder="Functie binnen bedrijf" />
                        </div>
                    </div>

                    <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 2</h3>

                    <div className="row">
                        <div className="col-6 s-col-12">
                            <label>Voornaam</label>
                            <input value={bedrijfInfo.contact_representative2_firstname} onChange={handleChange} name="contact_representative2_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />
                        </div>
                        <div className="col-6 pd-l-1 s-col-12 s-pd-l-0">
                            <label>Achternaam</label>
                            <input value={bedrijfInfo.contact_representative2_lastname} onChange={handleChange} name="contact_representative2_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 s-col-12">
                            <label>E-mailadres</label>
                            <input value={bedrijfInfo.contact_representative2_mail} onChange={handleChange} name="contact_representative2_mail" type="email" className="text-input pd-2 t-8" placeholder="E-mailadres" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Telefoonnummer</label>
                            <input value={bedrijfInfo.contact_representative2_cellphonenumber} onChange={handleChange} name="contact_representative2_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="Telefoonnummer" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Functie binnen bedrijf</label>
                            <input value={bedrijfInfo.contact_representative2_function} onChange={handleChange} name="contact_representative2_function" type="tel" className="text-input pd-2 t-8" placeholder="Functie binnen bedrijf" />
                        </div>
                    </div>

                    <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 3</h3>
                    <div className="row">
                        <div className="col-6 s-col-12">
                            <label>Voornaam</label>
                            <input value={bedrijfInfo.contact_representative3_firstname} onChange={handleChange} name="contact_representative3_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />
                        </div>
                        <div className="col-6 pd-l-1 s-col-12 s-pd-l-0">
                            <label>Achternaam</label>
                            <input value={bedrijfInfo.contact_representative3_lastname} onChange={handleChange} name="contact_representative3_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 s-col-12">
                            <label>E-mailadres</label>
                            <input value={bedrijfInfo.contact_representative3_mail} onChange={handleChange} name="contact_representative3_mail" type="email" className="text-input pd-2 t-8" placeholder="E-mailadres" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Telefoonnummer</label>
                            <input value={bedrijfInfo.contact_representative3_cellphonenumber} onChange={handleChange} name="contact_representative3_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="Telefoonnummer" />
                        </div>
                        <div className="col-4 pd-l-1 s-col-12 s-pd-l-1">
                            <label>Functie binnen bedrijf</label>
                            <input value={bedrijfInfo.contact_representative3_function} onChange={handleChange} name="contact_representative3_function" type="tel" className="text-input pd-2 t-8" placeholder="Functie binnen bedrijf" />
                        </div>
                    </div>

                    <label>Extra informatie:</label>
                    <textarea onChange={handleChange} name="contact_extra" className="text-input pd-2 t-8 textaraea" rows="10" value={bedrijfInfo.contact_extra}></textarea>

                    <input onClick={submit} type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
                </form>
            </div>
        );
}

export default M_MijnBedrijf_Info_Bewerk;
