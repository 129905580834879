import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { config } from "../../config";
import axios from "axios";
import ImageZoom from "../../components/imageZoom";
import { store } from "react-notifications-component";
//import { ImageGroup, Image } from 'react-fullscreen-image'

function P_JS_Standplaats(props) {
    const [standplaatsen, setStandplaatsen] = useState([]);
    const [participant, setParticipant] = useState(null);
    const [message, setMessage] = useState("");
    const [keuze, setKeuze] = useState();
    const [packet, setPacket] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let id = props.match.params.id;
        if (!!id) {
            axios
                .get(config.api + "/participants/" + id)
                .then((response) => {
                    setPacket(response.data.package);
                    setLoading(false);
                    if (response.data.booth) {
                        setMessage(
                            <div className="b-blue-400 c-white fw-500 pd-4 border-r-5">
                                {response.data.company.company_name}, uw standplaats nummer is goedgekeurd en is <span className="fw-700">{response.data.booth?.number}</span>!
                            </div>
                        );
                    } else {
                        setParticipant(response.data);

                        axios.get(config.api + "/booths?booth_class=" + response.data.package + "&empty=true").then((res) => {
                            let standen = res.data;
                            standen.sort((a, b) => {
                                return a.number - b.number;
                            });
                            setStandplaatsen(res.data);
                        });
                    }
                })
                .catch((error) => {
                    setMessage(<div className="b-red c-white fw-700 pd-3 border-r-5 mr-b-10">Unieke link niet gevonden in ons systeem, controleer de code of neem contact met ons op!</div>);
                });
        } else {
            window.location.assign(config.base);
        }
    }, []);

    function vraagStandAan() {
        let id = props.match.params.id;
        let data = {
            booth: keuze
        };
        axios
            .patch(config.api + "/participants/" + id + "/booth", data)
            .then(() => {
                window.location.assign(config.base + "/j&s/inschrijven/standplaats/" + id);
            })
            .catch((err) => {
                store.addNotification({
                    title: "Standplaats kiezen mislukt!",
                    message: "Iemand heeft in de tussentijd jouw standplaats ingenomen, we herladen de pagina zodat u opnieuw kunt proberen!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 6000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 6000);
            });
    }

    return (
        <div className="">
            <Helmet>
                <title>HR - J&S - Inschrijven - Standplaats</title>
            </Helmet>

            <div className="row">
                {loading ? (
                    <></>
                ) : (
                    <div className="box container w-75 m-w-95 mr-v-10 border-r-10 pd-0">
                        {participant ? <h2 className="t-center mr-b-4 c-gray-500 mr-5">STANDPLAATS KIEZEN - {participant?.company.company_name}</h2> : <></>}
                        {!!message ? message : <p className="c-red fw-700 mr-h-5">Let op, uw plaats wordt niet gereserveerd tot dat je op de indien knop hebt gedrukt.</p>}
                        {participant ? (
                            <p className="mr-t-3 c-gray-500 t-italic mr-5 mr-b-2">
                                Maak een keuze: <span className="c-blue-400">(staat uw gewenste stand er niet meer tussen, dan is deze reeds ingenomen.)</span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {standplaatsen.length > 0 ? (
                            <div className="mr-h-4 mr-b-5">
                                {standplaatsen.map((standplaats) => {
                                    return (
                                        <button onClick={() => setKeuze(standplaats.id)} className={"button fw-700 pd-2 mr-1 " + (keuze == standplaats.id ? "b-blue-400 c-white" : "b-gray-100 c-gray-500")}>
                                            {standplaats.number}
                                        </button>
                                    );
                                })}
                            </div>
                        ) : (
                            ""
                        )}

                        {keuze ? (
                            <button onClick={() => vraagStandAan()} className="button button-m b-blue-400 c-white fw-700 mr-t-5 block mr-5">
                                Keuze indienen
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </div>
            {packet ? (
                <div className="container w-75 m-w-95 c-gray-500">
                    <h2>
                        GRONDPLAN <span className="c-gray-500 t-italic t-8 fw-400">Klik op de foto om deze uit te vergroten.</span>
                    </h2>
                    <p className="c-gray-500">Alle standen zijn dit jaar op gelijkvloers</p>
                    <div className="row mr-v-4">
                        {(packet == "head" || packet == "base") && (
                            <>
                                <div className="col-3 m-col-6 m-pd-h-3 s-col-12 m-mr-t-5">
                                    <p className="t-center mr-b-2">Volledig grondplan</p>
                                    <ImageZoom src={config.base + "/images/jse2024/JSE2024_grondplan_volledig.svg"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-70 m-w-90 s-w-98 mr-b-10 m-mr-v-10" />
                                </div>
                                <div className="col-4 m-col-6 pd-l-3 m-pd-h-3 s-col-12 m-mr-t-5">
                                    <p className="t-center mr-b-2">Opgesplitst (deel 1)</p>
                                    <ImageZoom src={config.base + "/images/jse2024/JSE2024_grondplan_1.svg"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-50 m-w-90 s-w-98 mr-b-10 m-mr-v-10" />
                                </div>
                            </>
                        )}

                        {packet == "base" && (
                            <div className="col-5 pd-h-3 m-col-12 s-col-12 m-mr-t-5">
                                <p className="t-center mr-b-2">Opgesplitst (deel 2)</p>
                                <ImageZoom src={config.base + "/images/jse2024/JSE2024_grondplan_2.svg"} smallclass="w-100 border-r-5 box-shadow-2 button" bigclass="w-100" boxclass="container w-80 m-w-90 s-w-98 mr-b-10 m-mr-v-10" />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default P_JS_Standplaats;
