import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../config";
import BreadCrumb from "../components/breadcrumb";
import ImageZoom from "../components/imageZoom";
function P_Currvit(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Wefynd" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Wefynd" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Wefynd" />;
        }
    }

    return (
        <div className="mr-t-30">
            <Helmet>
                <title>HR - Wefynd</title>
            </Helmet>
            {renderNav()}
            <div className="container w-65 xl-w-75 m-w-90">
                {renderBreadCrumb()}
                <div className="lh-13 mr-t-15 mr-b-15">
                    <h1 className="mr-b-5">Inschrijven Job en Stage event</h1>
                    <p>
                        We zijn verheugd om de samenwerking met <strong className="c-blue-400">We fynd</strong> aan te kondigen. Meer informatie volgt binnenkort.
                    </p>
                    <br />
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Currvit;
