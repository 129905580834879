import axios from 'axios';
import React, { useState, useEffect, useMemo, useRef } from "react";
import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../../../config"
import { useForm } from 'react-hook-form'
import Switch from '@material-ui/core/Switch/Switch';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';
import { Editor } from '@tinymce/tinymce-react';
import { AiOutlineDelete } from 'react-icons/ai'

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

function P_Paneel_Bedrijf_Bewerk(props) {

    let history = useHistory();
    const [users, setUsers] = useState([])
    useEffect(() => {
        ophalen(config.api + "/users")
    }, [])

    function ophalen(getReq) {
        axios.get(getReq)
            .then(res => {
                setUsers(res.data)
            })
            .catch(err => {
                //console.log(err)
            })
    }

    const [bedrijf, setBedrijf] = useState({ 'interests': [], 'company_owner': { 'id': '' }, 'defaultInterests': [] })
    var link = config.api + "/companies/" + props.match.params.id

    const options = [
        { value: 'elektromech', label: 'Elektromechanica' },
        { value: 'bouw', label: 'Bouwkunde' },
        { value: 'chem', label: 'Chemie' },
        { value: 'infor', label: 'Informatica' },
        { value: 'eict', label: 'Elektronica-ICT' },
        { value: 'auto', label: 'Automatisering' },
        { value: 'land', label: 'Landmeten' },
        { value: 'etech', label: 'Elektrotechniek' },
    ]

    var optionsIncluded = useMemo(() => {
        return []
    }, [])


    const editorRef = useRef(null);

    const [state, setState] = React.useState({
        company_approved: false,
        active: false,
        international: false,
        masters_thesis: false,
        company_logo: "",
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [intresses, setIntresses] = useState()

    useEffect(() => {
        function bedrijfOphalen(getReq) {
            axios.get(getReq)
                .then(res => {
                    for (var i = 0; i < options.length; i++) {
                        var obj = options[i].value

                        if (res.data.interests.includes(obj)) {

                            optionsIncluded.push(options[i])
                            
                        }
                        res.data['defaultInterests'] = optionsIncluded
                        setIntresses(optionsIncluded)
                    }
                    setBedrijf(res.data)
                    setState({
                        ...state,
                        'active': res.data.active,
                        'international': res.data.international,
                        'masters_thesis': res.data.masters_thesis,
                        'company_logo': res.data.company_logo,
                    });
                })
                .catch(err => {
                    //console.log(err)
                })


        }

        bedrijfOphalen(link)
    // eslint-disable-next-line
    }, [link])






    const { register, handleSubmit } = useForm();

    // company name
    // company desc

    function onSubmit(values) {
        Object.keys(values).forEach(key => {
            if ((values[key] === '' || values[key] === null)) {
                if(key !== "company_short_desc"){
                    delete values[key];
                }
            }
        });

        var PushIntr = []
        if(intresses.length !== 0){
            for (let i of intresses) {
                PushIntr.push(i.value)
            }
        }

        values['interests'] = PushIntr
        
        values['active'] = state.active
        values['international'] = state.international
        values['masters_thesis'] = state.masters_thesis

        values['company_long_desc'] = editorRef.current.getContent()

        let fd = new FormData();
        if (state.company_logo instanceof File) {
            fd.append('company_logo', state.company_logo);
        }
        axios.patch(config.api + '/companies/' + bedrijf.id, values, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(() => {
            axios.patch(config.api + '/companies/' + bedrijf.id, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then(function (response) {
                store.addNotification({
                    title: "Bewerken gelukt!",
                    message: "De pagina wordt herladen!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 200)
            })
            .catch(function (error) {
                store.addNotification({
                    title: "Bewerken mislukt!",
                    message: "De pagina wordt herladen!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            });
        })}

        function verwijderBedrijf(){
            if (window.confirm('Bevestig het verwijderen van ' + bedrijf.company_name + '.'))
            {
                axios.delete(config.api + '/companies/' + bedrijf.id).then(function (response) {
                    window.location.assign(config.base + '/paneel/bedrijven');
                })
                .catch(err => {
                    //console.log(err)
                })
            }
            else
            {
                
            }
          }

    let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

    if(claims === null || claims.recruitment_privilege === false){
        window.location.replace("/");
    }else         
    return (
        <div>
            <Helmet>
                <title>HR - Paneel - Bedrijf bewerken</title>
            </Helmet>
            <Navigatie type="other" />

            <div className="mr-v-30 container w-65 xl-w-80 m-w-90">
                <div className="box pd-5">
                    <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black"><AiOutlineArrowLeft /></button>
                    <div className="t-center">
                        <img src={state.company_logo instanceof File ? URL.createObjectURL(state.company_logo) : state.company_logo} alt={bedrijf.company_name} width="250px" />
                    </div>
                    <h2 className="t-center c-gray-700-90 mr-v-5">{bedrijf.company_name} - bewerken</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className="container w-85 xl-w-90 m-w-100">
                        <label>Bedrijf actief:</label>
                        <Switch

                            checked={state.active}
                            onChange={handleChange}
                            name="active"
                        />


                        <label>Internationaal:</label>
                        <Switch

                            checked={state.international}
                            onChange={handleChange}
                            name="international"
                        />
                        <label>Master thesises:</label>
                        <Switch

                            checked={state.masters_thesis}
                            onChange={handleChange}
                            name="masters_thesis"
                        /><br /><br />

                        <label>Bedrijf naam</label>
                        <input defaultValue={bedrijf.company_name} {...register("company_name")} name="company_name" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf naam" />
                        <label>Bedrijf eigenaar</label>
                        <select {...register("company_owner")} name="company_owner" className="text-input pd-2 t-8">
                            {
                                users.map(
                                    user => {
                                        if (user.id === bedrijf.company_owner.id) {
                                            return <option selected key={user.id} value={user.id}>{user.email}</option>
                                        } else {
                                            return <option key={user.id} value={user.id}>{user.email}</option>
                                        }
                                    }
                                )
                            }
                        </select>
                        <label>Bedrijf rol</label>
                        <select {...register("company_role")} name="company_role" className="text-input pd-2 t-8">
                            <option value="base" selected={bedrijf.company_role === "base"}>Gewone sponsor</option>
                            <option value="recr" selected={bedrijf.company_role === "recr"}>Recruitment sponsor</option>
                            <option value="head" selected={bedrijf.company_role === "head"}>Hoofdsponsor</option>
                            <option value="adve" selected={bedrijf.company_role === "adve"}>Adverteerder</option>
                        </select>
                        <label>Bedrijf logo</label>
                        <input type="file" onChange={(event) => {
                            // eslint-disable-next-line
                            setState({ ...state, ['company_logo']: event.target.files[0] });
                        }} className="text-input pd-2 t-8" />
                        <label>Bedrijf website</label>
                        <input defaultValue={bedrijf.company_url} {...register("company_url")} name="company_url" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf website" />
                        <label>Bedrijf intresses</label>
                        <Select defaultValue={optionsIncluded} className="mr-t-2" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />
                        <br />
                        <label>Korte beschrijving</label>
                        <textarea {...register("company_short_desc")} maxlength="250" className="text-input pd-2 t-8" rows="10" defaultValue={bedrijf.company_short_desc}></textarea>


                        <label>Lange beschrijving</label><br /><br />
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={bedrijf.company_long_desc}
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | link image media | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                toolbar_mode: "floating"
                            }}
                        />

                        <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
                    </form>
                   <div className="w-100 t-right">
                        <button onClick={verwijderBedrijf} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white"><AiOutlineDelete/></button>
                   </div>
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Paneel_Bedrijf_Bewerk;
