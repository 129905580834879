//import axios from 'axios';
import { config } from "../config";
import React, { useState, useEffect } from "react";
import Footers from "../components/footers";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { store } from "react-notifications-component";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";

function P_Registrate() {
    let history = useHistory();
    const [showpass, setShowPass] = useState(false);

    const [data, setData] = useState({
        email: "",
        pass: "",
        pass2: "",
        voornaam: "",
        naam: ""
    });

    const [policy, setPolicy] = useState(false);
    const [footage, setFootage] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("claims")) {
            window.location.assign(config.base);
        }
    }, []);

    function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    }

    function showError(msg) {
        store.addNotification({
            title: "Oops! er ging iets mis!",
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function submit(e) {
        e.preventDefault();
        if (!validateEmail(data.email)) {
            showError("Ingevoerd E-mailadress is niet geldig, of je hebt het veld niet ingevuld.");
            return;
        }

        if (data.pass.length <= 6) {
            showError("Het ingegeven wachtwoord is te kort of niet ingevuld!");
            return;
        }
        if (data.pass != data.pass2) {
            showError("De twee wachtwoorden zijn niet dezelfde!");
            return;
        }
        if (!policy) {
            showError("U moet akkoord gaan met de privacy policy!");
            return;
        }
        if (!footage) {
            showError("U moet akkoord zijn met de voorwaarde dat wij beelden mogen maken.");
            return;
        }

        let pushData = {
            email: data.email,
            password: data.pass,
            password2: data.pass2,
            first_name: data.voornaam,
            last_name: data.naam,
            policy: true,
            footage: true
        };

        axios
            .post(config.api + "/auth/register/user", pushData)
            .then((result) => {
                window.location.assign(config.base + "/valideer");
            })
            .catch((error) => {
                showError("Oeps, er ging toch iets fout, waarschijnlijk bestaat er al een account met dit email");
            });
    }

    function togglePassVisible(e, now) {
        e.preventDefault();
        setShowPass(!now);
    }

    return (
        <div className="login h-100-vh">
            <Helmet>
                <title>HR - Registreer</title>
            </Helmet>

            <div className="container w-60 m-w-90 s-w-98 pd-v-15">
                <div className="box border-r-20">
                    <div className="container w-85 m-w-90 mr-t-8">
                        <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
                            <AiOutlineArrowLeft />
                        </button>
                    </div>
                    <div className="t-center mr-b-10 mr-t-2">
                        <img src="images/hermes_rec.png" alt="Hermes Recruitment" className="hr-login-logo" />
                        <br />
                    </div>

                    <form>
                        <div className="container w-85 m-w-90">
                            <p className="c-gray-500-80 t-center fw-700 t-14">Registreer uw account:</p>
                            <p className="c-blue-400 mr-t-4">Registratie via deze weg is enkel bedoeld voor studenten.</p>
                            <br />
                            <label htmlFor="voornaam">Voornaam:</label>
                            <input onChange={(e) => handle(e)} type="text" name="vn" id="voornaam" value={data.voornaam} className="text-input" placeholder="Voornaam" />
                            <label htmlFor="naam">Naam:</label>
                            <input onChange={(e) => handle(e)} type="text" name="n" id="naam" value={data.naam} className="text-input" placeholder="Naam" />
                            <label htmlFor="email">E-mailadres:</label>
                            <input onChange={(e) => handle(e)} type="text" name="email" id="email" value={data.email} className="text-input" placeholder="E-mailadres" />
                            <label htmlFor="pass">Wachtwoord:</label> <br />
                            <div className="w-100 relative">
                                <span data-title={!showpass ? "Toon wachtwoord" : "Verstop wachtwoord"} className="button link b-none input-icon t-12 c-gray-300" onClick={(e) => togglePassVisible(e, showpass)}>
                                    {!showpass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </span>
                            </div>
                            <input onChange={(e) => handle(e)} type={showpass ? "text" : "password"} name="pass" id="pass" value={data.pass} placeholder="**********" className="text-input relative" />
                            <label htmlFor="pass2">Wachtwoord herhalen:</label> <br />
                            <div className="w-100 relative">
                                <span data-title={!showpass ? "Toon wachtwoord" : "Verstop wachtwoord"} className="button link b-none input-icon t-12 c-gray-300" onClick={(e) => togglePassVisible(e, showpass)}>
                                    {!showpass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </span>
                            </div>
                            <input onChange={(e) => handle(e)} type={showpass ? "text" : "password"} name="pass2" id="pass2" value={data.pass2} placeholder="**********" className="text-input relative" />
                            <input type="checkbox" id="policy" onChange={() => setPolicy(!policy)} />{" "}
                            <label className="c-gray-500 mr-l-1" htmlFor="policy">
                                Ik heb de{" "}
                                <a href={"https://hermesgent.be/static/Privacy_policy.pdf"} target="_blank" className="button link c-blue-400">
                                    privacy policy
                                </a>{" "}
                                gelezen en ga akkoord
                            </label>
                            <br />
                            <input type="checkbox" id="footage" onChange={() => setFootage(!footage)} />{" "}
                            <label className="c-gray-500 mr-l-1" htmlFor="footage">
                                Ik ga akkoord dat op het Job & Stage Event beelden (foto/video) van mij kunnen gemaakt worden
                            </label>
                            <div className="row mr-b-10 mr-t-5">
                                <div className="col-6 xs-col-12">
                                    <button onClick={(e) => submit(e)} className="button button-m b-hr-blue pd-h-8 c-white fw-700">
                                        REGISTREER
                                    </button>
                                </div>
                                <div className="col-6 t-right xs-col-12 xs-t-left xs-mr-t-5">
                                    <a href="/login" className="button link c-hr-yellow pd-t-2">
                                        LOGIN
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default P_Registrate;
