import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import EventCard from "../components/eventcard";
import { config } from "../config";
function P_Webinars() {
	return (
		<div>
			<Helmet>
				<title>HR - Webinars</title>
			</Helmet>
			<div className="box w-100 pd-v-8 t-center">
                <a href={config.base} ><img src={config.base + "/images/hermes_rec.png"} width="180px"/></a>
            </div>
			<section className="mr-t-10 mr-b-20">
				<div className="container w-65 xl-w-80 m-w-90">
					<h1 className="t-center c-gray-500 t-25 mr-t-10 mr-b-3">WEBINARS</h1>
					<div className="row mr-t-8">
						<div className="col-6 s-col-12 pd-r-6 s-pd-r-0">
							<EventCard
								image="images/bijna_ingenieur.png"
								title="Ik ben (bijna) ingenieur. Wat nu?"
								date="Dinsdag 22 februari 2022 19u-20u"
								// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
								knop_text="SCHRIJF IN"
								blank="true"
								link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUQzJMQ0JBODZaUTdHTjRPVUdCOUZIS0c3Wi4u"
							/>
						</div>
						<div className="col-6 s-col-12 pd-l-6 s-pd-l-0 s-pd-t-6">
							<EventCard
								image="images/schitteren_op_linkedin.png"
								title="Schitteren op LinkedIn"
								date="Dinsdag 22 februari 2022 20u30-21u30"
								// text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto aperiam at veritatis in error et vero assumenda nemo omnis aliquid, illum, excepturi, deserunt quam eum illo delectus eligendi fugit natus?"
								knop_text="SCHRIJF IN"
								blank="true"
								link="https://forms.office.com/pages/responsepage.aspx?id=vKY8zvhorUaFJNat4-82CDeghGYXcGBDlqqsJYOZooRUNFdCN0tTTkdZQ0JMWUNWNUJUSkZUOUlHUy4u"
							/>
						</div>
					</div>
				</div>
			</section>
			<Footers type="main" />
		</div>
	);
}

export default P_Webinars;
