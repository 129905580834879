import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import { Helmet } from "react-helmet";
import React from "react";
import BedrijfLijst from "../../components/bedrijf/bedrijf_lijst";
import BreadCrumb from "../../components/breadcrumb";

function P_Bedrijven(props) {


  function renderNav(){
    if(props.location.pathname.includes("student")){
      return <Navigatie type="studenten" />
    }else
    if(props.location.pathname.includes("bedrijf")){
      return <Navigatie type="bedrijven" />
    }else{
      return <Navigatie type="home" />
    }
  }
  function renderBreadCrumb(){
    if(props.location.pathname.includes("student")){
      return <BreadCrumb 
              prev={[["/student", "Home"]]}
              now="Bedrijven"
              />
    }else
    if(props.location.pathname.includes("bedrijf")){
      return <BreadCrumb 
              prev={[["/bedrijf", "Home"]]}
              now="Bedrijven"
              />
    }
    else{
      return <BreadCrumb 
              prev={[["/bedrijf", "Home"]]}
              now="Bedrijven"
              />
    }
  }


  return (
    <div className="Bedrijf">
      <Helmet>
        <title>HR - Bedrijven</title>
      </Helmet>
      {renderNav()}

      <div className="mr-t-30 container w-65 xl-w-80 m-w-90">
        {renderBreadCrumb()}
      </div>
      
      <BedrijfLijst/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Bedrijven;
