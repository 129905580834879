import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import BreadCrumb from "../components/breadcrumb";

function P_About(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Over ons" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Over ons" />;
        } else {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="over ons" />;
        }
    }

    return (
        <div className="About mr-t-30">
            <Helmet>
                <title>HR - Over ons</title>
            </Helmet>
            {renderNav()}
            <div className="container w-65 l-w-75 m-w-90 mr-b-25">
                {renderBreadCrumb()}
                <h1 className="t-center c-gray-500 t-25 mr-t-10">ONS TEAM</h1>
                <div className="row mr-t-15">
                    <div className="col-3 col-offset-1 l-mr-l-0 l-col-4 s-col-12 pd-r-10 s-pd-r-0 t-center">
                        <img style={{ objectFit: "cover" }} src="../images/anais.jpeg" alt="" className="w-100 border-r-circle mr-b-3 l-w-70" />
                        <h2 className="t-10">Anaïs D'hulster</h2>
                        <h3 className="fw-300">Recruitment</h3>
                        <a className="button link fw-300 c-blue-400" href="tel:+32 493 75 17 49">
                            +32 493 75 17 49
                        </a>
                        <a className="button link fw-300 c-blue-400 block" href="mailto:recruitment@hermesgent.be">
                            recruitment@hermesgent.be
                        </a>
                    </div>

                    <div className="col-3  l-mr-l-0 l-col-4 s-col-12 pd-r-10 s-pd-r-0 t-center">
                        <img style={{ objectFit: "cover" }} src="../images/stefanie.jpg" alt="" className="w-100 border-r-circle mr-b-3 l-w-70" />
                        <h2 className="t-10">Stéfanie Verfaille</h2>
                        <h3 className="fw-300">Recruitment</h3>
                        <a className="button link fw-300 c-blue-400" href="tel:+32 471 10 36 76">
                            +32 471 10 36 76
                        </a>
                        <a className="button link fw-300 c-blue-400 block" href="mailto:recruitment@hermesgent.be">
                            recruitment@hermesgent.be
                        </a>
                    </div>

                    <div className="col-3 l-col-4 s-col-12 pd-r-10 s-pd-r-0 t-center s-pd-t-12">
                        <img style={{ objectFit: "cover" }} src="../images/nicholas.jpg" alt="" className="w-100 border-r-circle mr-b-3 l-w-70" />
                        <h2 className="t-10">Nicholas Goga</h2>
                        <h3 className="fw-300">Websiteverantwoordelijke</h3>
                        <a className="button link fw-300 c-blue-400" href="tel:+32 476 62 14 78">
                            +32 476 62 14 78
                        </a>
                        <a className="button link fw-300 c-blue-400 block" href="mailto:ict@hermesgent.be">
                            ict@hermesgent.be
                        </a>
                    </div>
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_About;
