import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
function P_Home() {
    return (
        <div className="Home ">
            <Helmet>
                <title>HR - Home</title>
            </Helmet>
            <Navigatie type="home" />
            <div className="home-main mr-t-25">
                <div className="row">
                    <a className="home-links xs-t-15" href="student">
                        STUDENT
                    </a>
                    <a className="home-links home-link-2 xs-t-15" href="bedrijf">
                        BEDRIJF
                    </a>
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Home;
