import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import { Helmet } from "react-helmet";
import { RiNewspaperLine } from 'react-icons/ri'
import { CgWorkAlt } from 'react-icons/cg'
import { BsPhone } from 'react-icons/bs'
import BreadCrumb from "../../components/breadcrumb"
function P_Diensten() {

  


  return (
    <div>
      <Helmet>
        <title>HR - Diensten</title>
      </Helmet>
      <Navigatie type="bedrijven" />
      <section className="mr-v-30">
        <div className="container w-65 xl-w-80 m-w-90">
         <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Diensten"/>
          <h1 className="t-center c-gray-500 t-25 mr-t-10 mr-b-3">
            ONZE BESCHIKBARE DIENSTEN
          </h1>
          <p className="t-center c-gray-700-70 mr-b-15">Neem gerust contact op met onze recruitments als je meer informatie wilt op: <a href="mailto:recruitment@hermesgent.be" className="button link c-blue-400 fw-700">recruitment@hermesgent.be</a></p>
          <div className="row box">
            <div className="col-4 m-col-12 pd-r-2 m-pd-r-0 m-pd-t-3">
              <div className="pd-5 t-center">
                <RiNewspaperLine className="t-30 c-yellow-400"/>
                <p className="pd-h-2 pd-v-5 c-gray-700-70">Meer info over CV’s van studenten binnenkort.</p>
              </div>
            </div>
            <div className="col-4 m-col-12 pd-r-2 m-pd-r-0 m-pd-t-3">
              <div className="pd-5 t-center">
                <CgWorkAlt className="t-30 c-yellow-400"/>
                <p className="pd-h-3 pd-v-5 c-gray-700-70">De mogelijkheid om vacatures te uploaden zodat ze op onze sites getoond kunnen worden.</p>
              </div>
            </div>
            <div className="col-4 m-col-12 pd-r-2 m-pd-r-0 m-pd-t-3">
              <div className="pd-5 t-center">
                <BsPhone className="t-30 c-yellow-400"/>
                <p className="pd-h-2 pd-v-5 c-gray-700-70">Adverteer uw bedrijf op onze Facebook/Instagram-pagina en bereik onze studenten.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footers type="main" />
    </div>
  );
}

export default P_Diensten;
