import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { useForm } from "react-hook-form";
import Modal from "../../modal";
import M_Bedrijf_Bewerk from "../bedrijf/bewerk";
function M_JS_Bedrijf_Aanmaken(props) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        //console.log(props.InschrijvingData)
        ophalenUsers(config.api + "/users");
    }, []);

    function ophalenUsers(getReq) {
        axios.get(getReq).then((res) => {
            setUsers(res.data);
        });
    }

    const [message, setMessage] = useState(null);

    function onSubmit(values) {
        //console.log(values)
        let InschrijvingID = props.InschrijvingData.id;
        //console.log(InschrijvingID);
        let contact_data = props.InschrijvingData;
        delete contact_data["booth"];
        delete contact_data["company"];
        delete contact_data["confirmation"];
        delete contact_data["confirmation_date"];
        let website = contact_data["contact_website"];
        delete contact_data["contact_website"];
        delete contact_data["id"];
        delete contact_data["jobfair"];
        delete contact_data["link"];
        delete contact_data["package"];
        delete contact_data["application_date"];
        let bedrijf_data = { company_url: website };
        //console.log(contact_data);
        axios
            .post(config.api + "/companies", values)
            .then(function (response) {
                //console.log(response.data.id);
                let company_id = response.data.id;
                //props.search()

                axios.patch(config.api + "/companies/" + response.data.id, bedrijf_data).then(function (response) {
                    axios.patch(config.api + "/companies/" + response.data.id + "/contact", contact_data).then(function (response) {
                        axios.patch(config.api + "/jobfair/participations/" + InschrijvingID, { company: company_id }).then(function (response) {
                            setMessage(
                                <>
                                    <p className="c-gray-700-70">
                                        Aanmaken succesvol gelukt! bewerk het bedrijf
                                        <Modal openButtonValue={"hier"} openButtonClasses="button link fw-700 c-blue-400 b-none pd-l-2">
                                            <M_Bedrijf_Bewerk BedrijfID={response.data.id} />
                                        </Modal>
                                        .
                                    </p>
                                </>
                            );
                        });
                    });
                });
            })
            .catch(function (error) {
                setMessage(<p className="c-red">Aanmaken is helaas mislukt, zorg dat alles correct is ingevuld.</p>);
            });
    }

    // company name
    // company desc

    const { register, handleSubmit } = useForm();

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-90 m-w-100 mr-v-10">
                {message}
                <h2 className="t-center c-gray-700-90 mr-v-5">BEDRIJF TOEVOEGEN</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input defaultValue={props.naam} {...register("company_name")} name="company_name" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf naam" />
                    <select {...register("company_owner")} name="company_owner" className="text-input pd-2 t-8">
                        <option selected disabled hidden>
                            Kies een account...
                        </option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id} label={user.email}>
                                {user.email}
                            </option>
                        ))}
                    </select>
                    <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="AANMAKEN" />
                </form>
            </div>
        );
}

export default M_JS_Bedrijf_Aanmaken;
