import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import React, {useState} from "react";
import BedrijfLijst from "../../../components/bedrijf/bedrijf_lijst";

function P_Paneel_Bedrijven(props) {

  let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

  if(claims === null || claims.recruitment_privilege === false){
    window.location.replace("/");
  }else
    return (
    <div>
      <Helmet>
        <title>HR - Paneel - Bedrijven</title>
      </Helmet>
      <Navigatie type="other" />

        <div className="mr-t-30"></div>
      
      <BedrijfLijst isAdmin/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Paneel_Bedrijven;
