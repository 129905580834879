import axios from 'axios';
import React, {useState, useEffect, useMemo, useRef} from "react";
import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../../../config"
import { useForm } from 'react-hook-form'
import Switch from '@material-ui/core/Switch/Switch';
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';
import { Editor } from '@tinymce/tinymce-react';
import { AiOutlineDelete } from 'react-icons/ai'

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

function P_Paneel_Stage_Bewerken(props) {
  let history = useHistory();
    const [item, setItem] = useState({})

    const [state, setState] = React.useState({
        active: false
    });

    const editorRef = useRef(null);


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    var link = config.api + "/vacancies/" + props.match.params.id

    useEffect(() => {
        function itemOphalen(getReq){
            axios.get(getReq)
            .then(res => {

                  for (var i = 0; i < options.length; i++) {
                    var obj = options[i].value

                    if (res.data.interests.includes(obj)) {

                        optionsIncluded.push(options[i])
                        
                    }
                    res.data['defaultInterests'] = optionsIncluded
                    setIntresses(optionsIncluded)
                }

                setItem(res.data)
                setState({ ...state, 
                    'active': res.data.active
                });
            })
            .catch(err => {
                //console.log(err)
            })
        }

        itemOphalen(link)
        // eslint-disable-next-line
    }, [link])


    function onSubmit(values) {
        values["type"] = "I"
        Object.keys(values).forEach(key => {
            if (values[key] === '' || values[key] == null) {
              if(key !== "desc"){
                delete values[key];
              }
            }
          });
        values["active"] = state.active

        var PushIntr = []
        if(intresses.length !== 0){
            for (let i of intresses) {
                PushIntr.push(i.value)
            }
        }

        values['interests'] = PushIntr
        values['ad_long_desc'] = editorRef.current.getContent()

        //console.log(values);
        axios.patch(config.api + '/vacancies/' + props.match.params.id, values)
        .then(function (response){
          store.addNotification({
              title: "Bewerken gelukt!",
              message: "De pagina wordt herladen!",
              type: "default",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });
            setTimeout(() => {
              window.location.reload(false);
            }, 2000)
      })
      .catch(function (error){
          store.addNotification({
              title: "Bewerken mislukt!",
              message: "Het bewerken is niet gelukt!",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });

      })  
        
    }
    function verwijderAd(){
      if (window.confirm('Bevestig het verwijderen van ' + item.title + '.'))
      {
          axios.delete(config.api + '/vacancies/' + item.id).then(function (response) {
              window.location.assign(config.base + '/paneel/stage');
          })
          .catch(err => {
              //console.log(err)
          })
      }
      else
      {
          
      }
    }

    
    const options = [
      { value: 'elektromech', label: 'Elektromechanica' },
      { value: 'bouw', label: 'Bouwkunde' },
      { value: 'chem', label: 'Chemie' },
      { value: 'infor', label: 'Informatica' },
      { value: 'eict', label: 'Elektronica-ICT' },
      { value: 'auto', label: 'Automatisering' },
      { value: 'land', label: 'Landmeten' },
      { value: 'etech', label: 'Elektrotechniek' },
   ]
   var optionsIncluded = useMemo(() => {
    return []
  }, [])

  const [intresses, setIntresses] = useState()


    // company name
    // company desc


    const { register, handleSubmit } = useForm();

  let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

  if(claims === null || claims.recruitment_privilege === false){
    window.location.replace("/");
  }else
  return (
    <div>
      <Helmet>
        <title>HR - Paneel - Stage Bewerken</title>
      </Helmet>
      <Navigatie type="other" />

        <div className="mr-v-30 container w-65 xl-w-80 m-w-90">
            <div className="box pd-5">
            <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black"><AiOutlineArrowLeft/></button>
                <h2 className="t-center c-gray-700-90 mr-v-5">STAGE BEWERKEN - {item.title}</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="container w-85 xl-w-90 m-w-100">
                <label>Actief</label>
                        <Switch
                                checked={state.active}
                                onChange={handleChange}
                                name="active"
                        /><br/><br/>
                    <label>Stage titel</label>
                    <input defaultValue={item.title} {...register("title")} name="title" type="text" className="text-input pd-2 t-8" placeholder="Job titel"/>
                    <label>Stage intresses</label>
                    <Select defaultValue={optionsIncluded} className="mr-t-2" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />
                    <br />
                    <label>Stage beschrijving</label>
                    <textarea  defaultValue={item.advertisement_short_desc} {...register("advertisement_short_desc")} className="text-input pd-2 t-8" rows="10"></textarea>
                    <label>Stage lange beschrijving</label><br/>
                    <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={item.advertisement_long_desc}
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | link image media | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                toolbar_mode: "floating"
                            }}
                        />
                    <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN"/>
                </form>
                <div className="w-100 t-right">
                    <button onClick={verwijderAd} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white"><AiOutlineDelete/></button>
                </div>
            </div>
        </div>
      
      
      <Footers type="main" />
    </div>
  );
}


export default P_Paneel_Stage_Bewerken;
