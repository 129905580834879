import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import JobsLijst from "../../components/student/jobs_lijst";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../components/breadcrumb";

function P_Jobs(props) {



  return (
    <div>
      <Helmet>
        <title>HR - Jobs</title>
      </Helmet>
      <Navigatie type="studenten" />
    

      <div className="mr-t-30 container w-65 xl-w-80 m-w-90">
      <BreadCrumb prev={[["/student", "Home"]]} now="Jobs"/>
      </div>

      <JobsLijst/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Jobs;
