import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { useForm } from "react-hook-form";
import Modal from "../../modal";
import { store } from "react-notifications-component";
//import M_Jobs_Bewerk from "./bewerk"
import M_MijnBedrijf_Job_Stage_Bewerk from "./bewerkjobstage";
function M_MijnBedrijf_Job_Stage_Nieuw(props) {
    const [message, setMessage] = useState(null);

    function onSubmit(values) {
        values["type"] = props.type;
        values["owner"] = props.owner;

        if (values.title.includes("/")) {
            store.addNotification({
                title: "Aanmaken mislukt!",
                message: 'De titel mag geen "/" bevatten!',
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }

        axios
            .post(config.api + "/vacancies", values)
            .then(function (response) {
                props.reload();
                setMessage(
                    <>
                        <p className="c-gray-700-70">
                            Aanmaken succesvol gelukt! bewerk de verdere info
                            <Modal openButtonValue={"hier"} openButtonClasses="button link fw-700 c-blue-400 b-none pd-l-1">
                                <M_MijnBedrijf_Job_Stage_Bewerk reload={props.reload} uuid={response.data.id} type={props.type} />
                            </Modal>
                            .
                        </p>
                    </>
                );
            })
            .catch(function (error) {
                setMessage(<p className="c-red">Aanmaken is helaas mislukt, zorg dat alles correct is ingevuld.</p>);
            });
    }

    // company name
    // company desc

    const { register, handleSubmit } = useForm();

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-90 m-w-100 mr-v-10">
                {message}
                <h2 className="t-center c-gray-700-90 mr-v-5">{props.type === "J" ? "JOB" : props.type === "I" ? "STAGE" : ""} TOEVOEGEN</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Titel</label>
                    <input {...register("title")} name="title" type="text" className="text-input pd-2 t-8" placeholder="Titel" />
                    <label>Korte beschrijving</label>
                    <textarea {...register("advertisement_short_desc")} className="text-input pd-2 t-8 textarea" rows="6" maxLength="250"></textarea>
                    <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="AANMAKEN" />
                </form>
            </div>
        );
}

export default M_MijnBedrijf_Job_Stage_Nieuw;
