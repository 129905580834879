import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import axios from "axios";
import Modal from "../../../components/modal";
import M_JS_Deelnemers_Toevoegen from "../../../components/modals/j&s/deelnemersToevoegen";
import M_JS_Deelnemers_Bewerken from "../../../components/modals/j&s/deelnemerBewerken";
import { store } from "react-notifications-component";
import { set } from "react-hook-form";

function P_Paneel_JS_Deelnemers(props) {
    const [items, setItems] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [loading, setLoading] = useState(false);

    const [packageFilter, setPackagefilter] = useState(0);
    const [companyFilter, setCompanyFilter] = useState("");
    const [standFilter, setStandFilter] = useState("");
    const [mailFilter, setMailFilter] = useState("");
    const [jobfairId, setJobfairId] = useState(null);

    const packages = ["", "base", "head", "late"];
    const packagesText = ["Alles", "Standaard", "Hoofdsponsor", "Laatkomers"];

    useEffect(() => {
        load();
    }, []);

    function filter(filterItems) {
        let filteredData = [];

        filteredData = filterItems.filter((x) => x.package == packages[packageFilter] || !packageFilter);
        filteredData = filteredData.filter((x) => x.company.company_name.toLowerCase().includes(companyFilter.toLowerCase()));
        if (standFilter) {
            if (standFilter == 0) {
                filteredData = filteredData.filter((x) => !x.booth);
            } else {
                filteredData = filteredData.filter((x) => x.booth?.number == standFilter);
            }
        }
        filteredData = filteredData.filter((x) => x.contact_mail.toLowerCase().includes(mailFilter.toLowerCase()));

        filteredData.sort((a, b) => {
            if (!a.booth) return 1;
            if (!b.booth) return -1;
            return a.booth?.number - b.booth?.number;
        });

        setFiltered(filteredData);
    }

    useEffect(() => {
        filter(items);
    }, [packageFilter, companyFilter, standFilter, mailFilter]);

    function load() {
        setLoading(true);
        axios.get(config.api + "/jobfair?year=" + props.match.params.jaar).then((response) => {
            setJobfairId(response.data[0].id);
        });
        axios
            .get(config.api + "/participants?year=" + props.match.params.jaar)
            .then((res) => {
                let data = res.data;

                let pushdata = [];
                let promises = [];

                for (let deelnemer of data) {
                    promises.push(
                        axios
                            .get(deelnemer.company.contact_information)
                            .then((result) => {
                                deelnemer = { ...deelnemer, contact_mail: result.data.contact_mail };
                                pushdata.push(deelnemer);
                            })
                            .catch(() => {})
                    );
                }
                Promise.all(promises).then(() => {
                    setItems(pushdata);
                    filter(pushdata);
                    setLoading(false);
                });
            })
            .catch(() => {});
    }

    function copy(text) {
        navigator?.clipboard
            ?.writeText(text)
            .then(() => {
                store.addNotification({
                    title: "Gekopieerd!",
                    message: "Link gekopieerd!",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            })
            .catch(() => {
                store.addNotification({
                    title: "Kopieren mislukt!",
                    message: "Kopieer handmatig!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            });
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <>
                <Helmet>
                    <title>HR - Paneel - J&S Deelnemers</title>
                </Helmet>
                <Navigatie type="other" />

                <section className="mr-b-30 mr-t-35">
                    <div className="container w-65 xl-w-80 m-w-90">
                        <div className="row">
                            <div className="col-12">
                                <Modal openButtonValue="Deelnemers toevoegen" openButtonClasses="button button-m b-blue-400 c-white fw-700">
                                    <M_JS_Deelnemers_Toevoegen jobfairId={jobfairId} year={props.match.params.jaar} load={() => load()} />
                                </Modal>
                                <button
                                    onClick={() => {
                                        setCompanyFilter("");
                                        setMailFilter("");
                                        setStandFilter("");
                                        setPackagefilter(0);
                                    }}
                                    className="button button-m b-black c-white fw-700 mr-l-2">
                                    Reset filters
                                </button>
                            </div>
                            <h2 className="mr-t-2">
                                Standen gekozen: <span className="c-blue-400">{filtered.filter((x) => x.booth).length}</span>/{filtered.length}
                            </h2>
                            <div className="col-12 m-pd-t-10">
                                <div className="t-12 mr-v-5 c-gray-700-80"></div>
                                <table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
                                    <thead className="c-gray-700-80">
                                        <tr>
                                            <th>
                                                <input className="filter-input" onChange={(e) => setCompanyFilter(e.target.value)} value={companyFilter} placeholder="Bedrijfnaam"></input>
                                            </th>
                                            <th>
                                                <input min="0" type="number" className="filter-input" onChange={(e) => setStandFilter(e.target.value)} value={standFilter} placeholder="Stand"></input>
                                            </th>
                                            <th>
                                                <input className="filter-input" onChange={(e) => setMailFilter(e.target.value)} value={mailFilter} placeholder="Contactmail"></input>
                                            </th>
                                            <th>
                                                Standplaats keuzelink <span className="t-6 c-gray-500 fw-300 t-italic">Klik om te kopieren</span>
                                            </th>
                                            <th>
                                                {loading ? (
                                                    packagesText[packageFilter]
                                                ) : (
                                                    <button className="button link c-blue-400 fw-700 b-none t-8" onClick={() => setPackagefilter((packageFilter + 1) % packages.length)}>
                                                        {packagesText[packageFilter]}
                                                    </button>
                                                )}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading ? (
                                            <>
                                                {filtered.length > 0 ? (
                                                    filtered.map((item, index) => (
                                                        <tr key={item.id} className={"tr-" + (index % 2)}>
                                                            <Modal openButtonValue={<td>{item.company.company_name}</td>} openButtonClasses="button link b-none c-blue-400 fw-700 t-left">
                                                                <M_JS_Deelnemers_Bewerken year={props.match.params.jaar} uuid={item.id} load={() => load()} />
                                                            </Modal>
                                                            <td>{!item.booth ? "Geen standplaats" : item.booth.number}</td>
                                                            <td className="c-yellow-700 t-italic link b-none" onClick={() => copy(item.contact_mail)}>
                                                                {item.contact_mail}
                                                            </td>
                                                            <td className="c-indigo t-italic link b-none" onClick={() => copy(config.base + "/j&s/inschrijven/standplaats/" + item.id)}>
                                                                {config.base + "/j&s/inschrijven/standplaats/" + item.id}
                                                            </td>
                                                            <td>{item.package == "base" ? "Standaard" : item.package == "head" ? "Hoofdsponsor" : "Laatkomer"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <td colSpan="100%" className="c-gray-500 t-italic t-9">
                                                        Geen bedrijf gevonden met deze filter.
                                                    </td>
                                                )}
                                            </>
                                        ) : (
                                            <tr>
                                                <td className="loading loading_form"></td>
                                                <td className="loading loading_form"></td>
                                                <td className="loading loading_form"></td>
                                                <td className="loading loading_form"></td>
                                                <td className="loading loading_form"></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <Footers type="main" />
            </>
        );
}

export default P_Paneel_JS_Deelnemers;
