import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import JobsLijst from "../../../components/student/jobs_lijst";
import React, {useState} from 'react'
import { Helmet } from "react-helmet";


function P_Paneel_Jobs(props) {

  let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

  if(claims === null || claims.recruitment_privilege === false){
    window.location.replace("/");
  }else
  return (
    <div>
      <Helmet>
        <title>HR - Paneel - Jobs</title>
      </Helmet>
      <Navigatie type="other" />
    

      <div className="mr-t-30"></div>
      <JobsLijst isAdmin/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Paneel_Jobs;
