import React from 'react';
import { config } from '../config'
function BreadCrumb(props) {
  return (

    <div className="box pd-3">
        {
            props.prev.map(
                p => 
                <span key={p[0]}>
                    <a href={config.base + p[0]} className="button link c-blue-400 hover hover-2 hover-c-blue-400-50 pd-l-1">{p[1]}</a> /
                </span>
                
            )
            
        }
        <span>
            <span className="c-gray-700-70 pd-l-1">{props.now}</span>
        </span>
    </div>
  );
}


export default BreadCrumb;
