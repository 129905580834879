import axios from 'axios';
import React, { useState, useEffect } from "react";
import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../../../config"
import { useForm } from 'react-hook-form'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';


function P_Paneel_Bedrijf_Bewerk_Info(props) {

    let history = useHistory();
    
    const [bedrijf, setBedrijf] = useState({'interests': []})
    const [bedrijfInfo, setBedrijfInfo] = useState({})


    var link = config.api + "/companies/" + props.match.params.id

    useEffect(() => {
        function bedrijfOphalen(getReq){
            axios.get(getReq)
            .then(res => {
                setBedrijf(res.data)
            })
            .catch(err => {
                //console.log(err)
            })
            
        }

        bedrijfOphalen(link)
        // eslint-disable-next-line
    }, [link])

    useEffect(() => {
        function bedrijfInfoOphalen(getReq){
            axios.get(getReq)
            .then(res => {
                setBedrijfInfo(res.data)
                //console.log(bedrijfInfo)
            })
            .catch(err => {
                //console.log(err)
            })
            
        }

        bedrijfInfoOphalen(bedrijf.contact_information)
        // eslint-disable-next-line
    }, [bedrijf.contact_information])





    const { register, handleSubmit } = useForm();

    // company name
    // company desc

    function onSubmit(values) {
        Object.keys(values).forEach(key => {
            if (values[key] === '' || values[key] === null) {
                delete values[key];
            }
        });
        //console.log(values)

        
        axios.patch(config.api + '/companies/' + bedrijf.id + "/contact/", values, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            store.addNotification({
                title: "Bewerken gelukt!",
                message: "Het bewerken van de bedrijfs info is gelukt!",
                type: "default",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
        })
        .catch(function (error) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: "De pagina wordt herladen!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            });
        });
    }


    let [claims] = useState(localStorage.getItem('claims') !== null ? JSON.parse(localStorage.getItem('claims')) : null);

    if(claims === null || claims.recruitment_privilege === false){
        window.location.replace("/");
    }else
    return (
        <div>
            <Helmet>
                <title>HR - Paneel - Bedrijf info bewerken</title>
            </Helmet>
            <Navigatie type="other" />

            <div className="mr-v-30 container w-65 xl-w-80 m-w-90">
                <div className="box pd-5">
                    <button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black"><AiOutlineArrowLeft /></button>
                    <div className="t-center">
                        <img src={bedrijf.company_logo} alt={bedrijf.company_name} width="250px" />
                    </div>
                    <h2 className="t-center c-gray-700-80 mr-v-5">{bedrijf.company_name} - info bewerken</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className="container w-85 xl-w-90 m-w-100">
                    
                    <h2 className="c-gray-700-60 mr-b-5">Facturatie gegevens</h2>

                    <label>Facturatie bedrijfsnaam</label>
                    <input defaultValue={bedrijfInfo.contact_company_name} {...register("contact_company_name")} name="contact_company_name" type="text" className="text-input pd-2 t-8" placeholder="Facturatie bedrijfsnaam" />
      
                    <label>Facturatie email</label>
                    <input defaultValue={bedrijfInfo.contact_billingmail} {...register("contact_billingmail")} name="contact_billingmail" type="email" className="text-input pd-2 t-8" placeholder="Facturatie email" />
                    
                    <label>BTW nummer</label>
                    <input defaultValue={bedrijfInfo.contact_btw} {...register("contact_btw")} name="contact_btw" type="text" className="text-input pd-2 t-8" placeholder="BTW nummer" />

                    <label>Adres gegevens</label>
                    <div className="row">
                        <div className="col-10">
                            <input defaultValue={bedrijfInfo.contact_street} {...register("contact_street")} name="contact_street" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Straat" />        
                        </div>
                        <div className="col-2 pd-l-1">
                            <input defaultValue={bedrijfInfo.contact_number} {...register("contact_number")} name="contact_number" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Nr. (Bus)" />        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 s-col-12">
                            <input  defaultValue={bedrijfInfo.contact_city} {...register("contact_city")} name="contact_city" type="text" className="text-input pd-2 t-8" placeholder="Stad/Gemeente" />
                        </div>
                        <div className="col-2 s-col-12 pd-h-1 s-pd-h-0">        
                            <input defaultValue={bedrijfInfo.contact_zipcode} {...register("contact_zipcode")} name="contact_zipcode" type="number" className="text-input pd-2 t-8" placeholder="Postcode" /> 
                        </div>
                        <div className="col-3 s-col-12">
                            <input defaultValue={bedrijfInfo.contact_country} {...register("contact_country")} name="contact_country" type="text" className="text-input pd-2 t-8" placeholder="Land" />   
                        </div>   
                    </div>   

                    <h2 className="c-gray-700-60 mr-v-5">Contactpersoon gegevens</h2>  

                    <label>Voornaam</label>
                    <input defaultValue={bedrijfInfo.contact_firstname} {...register("contact_firstname")} name="contact_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />    

                     <label>Achternaam</label>
                    <input defaultValue={bedrijfInfo.contact_lastname} {...register("contact_lastname")} name="contact_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />    

                     <label>Email</label>
                    <input defaultValue={bedrijfInfo.contact_mail} {...register("contact_mail")} name="contact_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />    

                    <label>Telefoonnummer</label>
                    <input defaultValue={bedrijfInfo.contact_telephonenumber} {...register("contact_telephonenumber")} name="contact_telephonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 0 000 00 00" /> 
  
                    <label>Gsmnummer</label>
                    <input defaultValue={bedrijfInfo.contact_cellphonenumber} {...register("contact_cellphonenumber")} name="contact_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                    <label>Functie binnen bedrijf</label>
                    <input defaultValue={bedrijfInfo.contact_person_function} {...register("contact_person_function")} name="contact_person_function" type="tel" className="text-input pd-2 t-8" placeholder="Functie" />                            

                    <label>Extra informatie:</label>
                    <textarea {...register("contact_extra")} className="text-input pd-2 t-8" rows="10" defaultValue={bedrijfInfo.contact_extra}></textarea>
                        
                        <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" />
                    
                    
                    </form>

                    
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Paneel_Bedrijf_Bewerk_Info;
