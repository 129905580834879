import axios from "axios";
import { config } from "../config";
import React, { useState, useEffect } from "react";
import Footers from "../components/footers";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { store } from "react-notifications-component";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal";
import M_WachtwoordVergeten from "../components/modals/wachtwoordVergeten";
function P_Login() {
	let history = useHistory();
	const [showpass, setShowPass] = useState(false);

	const [data, setData] = useState({
		email: "",
		pass: "",
	});

	useEffect(() => {
		if (localStorage.getItem("claims")) {
			window.location.assign(config.base);
		}
	}, []);

	function handle(e) {
		const newdata = { ...data };
		newdata[e.target.id] = e.target.value;
		setData(newdata);
	}

	function showError(msg) {
		store.addNotification({
			title: "Oops! er ging iets mis!",
			message: msg,
			type: "danger",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
			},
		});
	}

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	function submit(e) {
		e.preventDefault();
		if (!validateEmail(data.email)) {
			showError("Ingevoerd E-mailadress is niet geldig, of je hebt het veld niet ingevuld.");
			return;
		}

		if (data.pass.length <= 5) {
			showError("Het ingegeven wachtwoord is te kort of niet ingevuld!");
			return;
		}
		let fd = new FormData();
		fd.append("email", data.email);
		fd.append("password", data.pass);
		axios
			.post(config.api + "/auth/token/", fd)
			.then((res) => {
				if (res.status === 200) {
					let token = res.data.access;
					let refresh = res.data.refresh;
					localStorage.setItem("token", token);
					localStorage.setItem("refresh", refresh);
					let claim = token.split(".")[1];
					let atobed = atob(claim);
					localStorage.setItem("claims", atobed); // Niet deprecated
					let parsed = JSON.parse(atobed);
					axios.defaults.headers.common["Authorization"] = "Bearer " + token;
					if (parsed.verified) window.location.assign(config.base + "/mijn-account");
					else {
						new Promise((resolve, reject) => {
							if (localStorage.getItem("token") === null || localStorage.getItem("refresh") === null || localStorage.getItem("claims") === null) {
								throw new Error("loggedin");
							}
							localStorage.clear();
							window.location.assign(config.base + "/valideer");
						}).catch((error) => {
							window.location.assign(config.base + "/valideer");
						});
					}
				}
			})
			.catch(function (error) {
				store.addNotification({
					title: "Aanmelden mislukt!",
					message: "Uw wachtwoord en email komen niet overeen of bestaat niet!",
					type: "danger",
					insert: "top",
					container: "top-center",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
					},
				});
			});

		//console.log("Axios can do its thing")
		//axios
	}

	function togglePassVisible(e, now) {
		e.preventDefault();
		setShowPass(!now);
	}

	return (
		<div className="login h-100-vh">
			<Helmet>
				<title>HR - Login</title>
			</Helmet>

			<div className="row">
				<div className="box mr-v-15 container w-45 m-w-70 s-w-90 xs-w-95 pd-v-5 border-r-20">
					<div className="container w-85 m-w-90 mr-t-8">
						<button onClick={() => history.goBack()} className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
							<AiOutlineArrowLeft />
						</button>
					</div>
					<div className="t-center mr-b-10 mr-t-5">
						<img src={config.base + "/images/hermes_rec.png"} alt="Hermes Recruitment" className="hr-login-logo" />
						<br />
					</div>

					<form>
						<div className="container w-85 m-w-90">
							<label htmlFor="email">E-mailadres:</label>
							<input onChange={(e) => handle(e)} type="text" name="email" id="email" value={data.email} className="text-input" placeholder="E-mailadres" />
							<label htmlFor="pass">Wachtwoord:</label> <br />
							<div className="w-100 relative">
								<span data-title={!showpass ? "Toon wachtwoord" : "Verstop wachtwoord"} className="button link b-none input-icon t-12 c-gray-300" onClick={(e) => togglePassVisible(e, showpass)}>
									{!showpass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
								</span>
							</div>
							<input onChange={(e) => handle(e)} type={showpass ? "text" : "password"} name="pass" id="pass" value={data.pass} className="text-input relative" placeholder="Wachtwoord" />
							<div className="row mr-b-8 mr-t-5">
								<div className="col-6">
									<button onClick={(e) => submit(e)} className="button button-m b-hr-blue pd-h-8 c-white fw-700">
										LOGIN
									</button>
								</div>
								<div className="col-6 t-right">
									<a href={config.base + "/registreer"} className="button link c-hr-yellow pd-t-2">
										Nog geen account?
									</a>
								</div>
							</div>
							<Modal boxClasses="container w-50 m-w-70 s-w-98 mr-v-30 mr-b-10 m-mr-v-15" openButtonValue="Wachtwoord Vergeten?" openButtonClasses="button link b-none c-gray-500-60 t-7 fw-400 hover hover-2 hover-c-black mr-b-5">
								<M_WachtwoordVergeten />
							</Modal>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default P_Login;
