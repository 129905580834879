import axios from "axios";
import { Promise } from "es6-promise";

var config = {
    base: "https://recruitment.hermesgent.be", //zonder slash op einde
    api: "https://hrbackend.hermesgent.be" //zonder slash op einde.
};

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        if (error.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        // The client thinks he is logged in
        if (localStorage.getItem("claims") !== null) {
            // Token has failed to refresh
            if (error.config.url === config.api + "/auth/token/refresh/") {
                //console.log('Clearing');
                localStorage.clear();
                //window.location = "/login";

                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // Refresh the token
            let fd = new FormData();
            fd.append("refresh", localStorage.getItem("refresh"));
            return axios
                .post(config.api + "/auth/token/refresh/", fd)
                .then((res) => {
                    // New request with new token
                    const config = error.config;
                    if (res.status === 200) {
                        let token = res.data.access;
                        localStorage.setItem("token", token);
                        let claim = token.split(".")[1];
                        localStorage.setItem("claims", atob(claim)); // Niet deprecated
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    }

                    return new Promise((resolve, reject) => {
                        axios
                            .request(config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                })
                .catch((error) => {
                    Promise.reject(error);
                });
        }
    }
);

export { config };
