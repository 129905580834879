import React, { useState } from "react";
import { config } from "../../../config";
import axios from "axios";
import { store } from "react-notifications-component";
function M_GegevensBewerken(props) {

	const [item, setItem] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

	function handleChange(e) {
		const name = e.target.getAttribute("name");
		const value = e.target.value;
		setItem({ ...item, [name]: value });
	}

	function submit(e){
		e.preventDefault();

		let id = item.user_id

		let data = {
			first_name: item.first_name,
			last_name: item.last_name
		}

		if(!item.first_name || !item.last_name){
			store.addNotification({
				title: "Aanpassen mislukt!",
				message: "Beide velden moeten ingevuld zijn!",
				type: "danger",
				insert: "top",
				container: "top-center",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
				},
			});
			return
		}

		axios
			.patch(config.api + "/users/" + id, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(function (response) {
				store.addNotification({
					title: "Bewerken gelukt!",
					message: "U wordt automatisch uitgelogd voor succesvol de aanpassingen te kunnen doorvoeren.",
					type: "default",
					insert: "top",
					container: "top-center",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
					},
				});
				setTimeout(() => {
					window.location.replace(config.base + "/logout");
				  }, 3000)
			})
	}

	let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);
	if (claims === null) {
		window.location.replace(config.base + "/login");
	} else
		return (
			<div className="container w-85 xl-w-90 m-w-100 mr-v-10">
				<h2 className="c-gray-700-60 fw-300">Account gegevens aanpassen</h2>
				<form className="mr-t-5">
					<label for="first_name">Voornaam</label>
					<input onChange={handleChange} name="first_name" type="text" id="first_name" value={item.first_name} placeholder="Voornaam" className="text-input pd-2 t-8"/>
					<label for="last_name">Achternaam</label>
					<input onChange={handleChange} name="last_name" type="text" id="last_name" value={item.last_name} placeholder="Achternaam" className="text-input pd-2 t-8"/>
					
					<input type="submit" onClick={submit} className="button button-m b-black c-white fw-700 mr-t-3" value="OPSLAAN"/>
				</form>
			</div>
		);
}

export default M_GegevensBewerken;
