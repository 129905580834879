import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/breadcrumb";
import Footers from "../components/footers";
import Navigatie from "../components/navigaties";
import { config } from "../config";
import { HiOutlineEye } from "react-icons/hi";
import { AiOutlineCloudDownload } from "react-icons/ai";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { store } from "react-notifications-component";

const animatedComponents = makeAnimated();

function P_CVBundel(props) {
    const [permission, setPermission] = useState(false);
    const [bundel, setBundel] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const options = [
        { value: "elektromech", label: "Elektromechanica" },
        { value: "bouw", label: "Bouwkunde" },
        { value: "chem", label: "Chemie" },
        { value: "infor", label: "Informatica" },
        { value: "eict", label: "Elektronica-ICT" },
        { value: "auto", label: "Automatisering" },
        { value: "land", label: "Landmeten" },
        { value: "etech", label: "Elektrotechniek" }
    ];

    let interesses = {
        elektromech: "Elektromechanica",
        bouw: "Bouwkunde",
        chem: "Chemie",
        infor: "Informatica",
        eict: "Elektronica-ICT",
        auto: "Automatisering",
        land: "Landmeten",
        etech: "Elektrotechniek"
    };

    const [intresses, setIntresses] = useState([]);

    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="bedrijven" />;
        }
    }

    useEffect(() => {
        /*let user_id = claims.user_id;
		axios.get(config.api + "/participants/").then((result) => {
			
		}).catch(() => {})*/

        if (claims.account_type === "company" || claims.account_type === "recruit") {
            setPermission(true);
        }

        axios
            .get(config.api + "/students/")
            .then((res) => {
                let data = res.data;
                data = data.filter((x) => !!x.resume);
                setBundel(data);
                filter(data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        filter(bundel);
    }, [intresses]);

    function filter(data) {
        //console.log(intresses);
        let pushData = [];
        if (intresses.length !== 0) {
            for (let x of data) {
                let found = false;
                //console.log(x.interests);
                for (let y of intresses) {
                    if (x.interests.includes(y.value) && !found) {
                        pushData.push(x);
                        found = true;
                    }
                }
            }
        } else {
            pushData = data;
        }
        setFiltered(pushData);
    }

    function downloadAll() {
        store.addNotification({
            title: "Downloaden gestart!",
            message: "Even geduld, de cv's worden gedownload!",
            type: "default",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

        var zip = new JSZip();
        let x = 1;
        let promises = [];
        for (let item of filtered) {
            promises.push(
                fetch(item.resume)
                    .then((response) => response.blob())
                    .then((response) => {
                        zip.file("CV #" + x + ".pdf", response);
                        x++;
                    })
            );
        }
        Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, "CV Bundel - 2022.zip");
            });
        });
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace(config.base + "/login");
    } else
        return (
            <div className="About mr-t-30">
                <Helmet>
                    <title>HR - CV Bundel</title>
                </Helmet>
                {renderNav()}
                <div className="container w-65 xl-w-75 m-w-90 mr-b-30">
                    {permission ? (
                        <div>
                            <BreadCrumb prev={[["/", "Home"]]} now="Cv Bundel" />
                            <div className="mr-v-10">
                                <p className="mr-b-1 c-gray-500-50 t-italic">Let op, er kunnen nog nieuwe CV's bijkomen.</p>
                                <div className="flex mr-b-1">
                                    <button onClick={() => downloadAll()} className="button button-m b-black c-white fw-700 hover hover-2 hover-b-black-80">
                                        Download alle CV's ({filtered.length})
                                    </button>
                                    <Select className="flex-grow mr-l-3" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} placeholder="Op welke vakgebieden zoekt u een CV..." />
                                </div>
                                {filtered.map((item) => {
                                    return (
                                        <div className="w-100 b-white box-shadow-2 border-r-10 mr-t-2 row flex horizontal-center">
                                            <div className="flex-grow pd-3">
                                                {item.interests.map((x, i) => {
                                                    return (
                                                        <span className="t-7 c-gray-500-80">
                                                            {interesses[x]}
                                                            {i + 1 < item.interests.length ? ", " : ""}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                            <div className="flex horizontal-center b-blue-400-80 pd-3 border-r-r-10">
                                                <a target="_blank" className="link c-white t-11 horizontal-center  hover hover-2 hover-c-white-50" href={item.resume}>
                                                    <HiOutlineEye />
                                                </a>
                                                <Link className="link t-11 c-white mr-l-2 horizontal-center hover hover-2 hover-c-white-50" to={item.resume} target="_blank" download>
                                                    <AiOutlineCloudDownload />
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            <h1 className="c-red t-center">U heeft geen toegang tot de CV Bundel</h1>
                        </div>
                    )}
                </div>
                <Footers type="main" />
            </div>
        );
}

export default P_CVBundel;
