import axios from "axios";
import { config } from "../config";
import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useEffect } from "react";

function P_ValideerEmail(props) {
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if (!!props.match.params.key) {
            let data = { token: props.match.params.key };
            axios
                .patch(config.api + "/auth/verificate", data)
                .then(() => {
                    setVerified(true);
                    new Promise((resolve, reject) => {
                        if (localStorage.getItem("token") === null || localStorage.getItem("refresh") === null || localStorage.getItem("claims") === null) {
                            throw new Error("loggedin");
                        }
                        localStorage.clear();
                    }).catch(() => {});

                    setTimeout(() => {
                        window.location.assign(config.base + "/login");
                    }, 4000);
                })
                .catch(() => {
                    window.location.assign(config.base);
                });
        }
    }, []);

    return (
        <div className="login h-100-vh">
            <Helmet>
                <title>HR - Valideer Email</title>
            </Helmet>

            <div className="container w-40 l-w-60 m-w-80 s-w-98 pd-v-25 m-pd-v-10">
                {!!props.match.params.key ? (
                    <>{verified ? <h1 className="t-center c-white mr-b-10">ACCOUNT SUCCESVOL GEVERIFIËERD, U WORDT DOORGELEID NAAR DE LOGIN PAGINA</h1> : <></>}</>
                ) : (
                    <>
                        <h1 className="t-center c-white mr-b-10">VALIDEER UW E-MAILADRES</h1>
                        <div className="box border-r-20 pd-10 m-pd-5">
                            <p className="c-gray-500">Er is naar uw geregistreerd e-mailadres een mail gestuurd met een unieke link om uw account te valideren.</p>
                            <p className="c-red">Let op! soms kan de mail in spam terecht komen.</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default P_ValideerEmail;
