import axios from "axios";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { config } from "../../../config";
import Switch from "@material-ui/core/Switch/Switch";
import { store } from "react-notifications-component";
import { Editor } from "@tinymce/tinymce-react";
import { AiOutlineDelete } from "react-icons/ai";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function M_MijnBedrijf_Bewerk(props) {
    const [valid, setValid] = useState(false);
    var link = config.api + "/companies/" + props.BedrijfID;

    const options = [
        { value: "elektromech", label: "Elektromechanica" },
        { value: "bouw", label: "Bouwkunde" },
        { value: "chem", label: "Chemie" },
        { value: "infor", label: "Informatica" },
        { value: "eict", label: "Elektronica-ICT" },
        { value: "auto", label: "Automatisering" },
        { value: "land", label: "Landmeten" },
        { value: "etech", label: "Elektrotechniek" }
    ];

    var optionsIncluded = useMemo(() => {
        return [];
    }, []);

    const editorRef = useRef(null);

    const [state, setState] = React.useState({
        company_logo: ""
    });

    const handleSwitch = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [intresses, setIntresses] = useState();

    useEffect(() => {
        function bedrijfOphalen(getReq) {
            axios
                .get(getReq)
                .then((res) => {
                    if (res.data.company_owner?.id !== claims.user_id) {
                        window.location.reload();
                    } else {
                        setValid(true);
                        for (var i = 0; i < options.length; i++) {
                            var obj = options[i].value;

                            if (res.data.interests.includes(obj)) {
                                optionsIncluded.push(options[i]);
                            }
                            res.data["defaultInterests"] = optionsIncluded;
                            setIntresses(optionsIncluded);
                        }
                        setState({
                            ...state,
                            company_name: res.data.company_name,
                            company_url: res.data.company_url,
                            company_short_desc: res.data.company_short_desc,
                            company_long_desc: res.data.company_long_desc,
                            company_logo: res.data.company_logo
                        });
                    }
                })
                .catch((err) => {
                    //console.log(err)
                });
        }

        bedrijfOphalen(link);
        // eslint-disable-next-line
    }, [link]);

    // company name
    // company desc

    function submit(e) {
        e.preventDefault();
        var PushIntr = [];
        if (intresses.length !== 0) {
            for (let i of intresses) {
                PushIntr.push(i.value);
            }
        }

        let values = {
            ...state,
            interests: PushIntr,
            company_long_desc: editorRef.current.getContent()
        };
        delete values.company_logo;

        let fd = new FormData();
        if (state.company_logo instanceof File) {
            fd.append("company_logo", state.company_logo);
        }

        if (!(values.company_url.includes("https://") || values.company_url.includes("http://"))) values.company_url = "https://" + values.company_url;

        if (values.company_name.includes("/")) {
            store.addNotification({
                title: "Bewerken mislukt!",
                message: 'Uw bedrijfsnaam mag geen "/" bevatten!',
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }

        axios
            .patch(config.api + "/companies/" + props.BedrijfID, values, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(() => {
                axios
                    .patch(config.api + "/companies/" + props.BedrijfID, fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(function (response) {
                        store.addNotification({
                            title: "Bewerken gelukt!",
                            message: "Bewerken succesvol gelukt!",
                            type: "default",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                        });
                        props.reload();
                    })
                    .catch(function (error) {
                        throw Error(error);
                    });
            })
            .catch(function (error) {
                store.addNotification({
                    title: "Bewerken mislukt!",
                    message: "Heeft u alles correct ingevuld?",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1000,
                        onScreen: true
                    }
                });
            });
    }

    function handleChange(e) {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setState({ ...state, [name]: value });
    }

    function verwijderBedrijf() {
        alert("Als u uw bedrijf wilt verwijderen moet u contact opnemen met de recruitments recruitment@hermesgent.be");
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null) {
        window.location.replace("/");
    } else
        return (
            <>
                <div className="container w-85 xl-w-90 m-w-100 mr-v-10">
                    <div className="t-center">
                        <img src={state.company_logo instanceof File ? URL.createObjectURL(state.company_logo) : state.company_logo} alt={state.company_name} width="250px" />
                    </div>
                    <h2 className="t-center c-gray-700-90 mr-v-5">{state.company_name} - bewerken</h2>

                    <form>
                        <label>Bedrijf naam</label>
                        <input value={state.company_name} onChange={handleChange} name="company_name" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf naam" />
                        <label>Bedrijf logo</label>
                        <br />
                        <input
                            id="file-1"
                            type="file"
                            onChange={(event) => {
                                // eslint-disable-next-line
                                setState({ ...state, ["company_logo"]: event.target.files[0] });
                            }}
                            className="inputfile inputfile-1 hidden"
                        />
                        <label for="file-1" class="mr-t-2 mr-b-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                            </svg>{" "}
                            <span>Upload logo</span>
                        </label>
                        <br />
                        <label>Bedrijf website</label>
                        <input value={state.company_url} onChange={handleChange} name="company_url" type="text" className="text-input pd-2 t-8" placeholder="Bedrijf website" />
                        <label>Bedrijf intresses</label>
                        <Select defaultValue={optionsIncluded} className="mr-t-2" onChange={setIntresses} options={options} isMulti isSearchable components={animatedComponents} />
                        <br />
                        <label>Korte beschrijving</label>
                        <textarea onChange={handleChange} name="company_short_desc" maxlength="250" className="text-input pd-2 t-8 textaraea" rows="10" value={state.company_short_desc}></textarea>

                        <label>Lange beschrijving</label>
                        <br />
                        <br />
                        <Editor
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={state.company_long_desc}
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                                toolbar: "undo redo | formatselect | " + "bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | link image media | help",
                                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                toolbar_mode: "floating"
                            }}
                        />

                        {valid ? <input onClick={submit} type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="BEWERKEN" /> : ""}
                    </form>
                    {valid ? (
                        <div className="w-100 t-right">
                            <button onClick={verwijderBedrijf} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white">
                                <AiOutlineDelete />
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
}

export default M_MijnBedrijf_Bewerk;
