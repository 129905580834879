import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { store } from "react-notifications-component";
import { AiOutlineDelete } from "react-icons/ai";
function M_JS_Deelnemers_Bewerken(props) {
    const [deelnemer, setDeelnemer] = useState({});
    const [company, setCompany] = useState({});
    const [booths, setBooths] = useState([]);

    function handleChange(e) {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setDeelnemer({ ...deelnemer, [name]: value });
    }

    useEffect(() => {
        axios.get(config.api + "/participants/" + props.uuid).then((res) => {
            //console.log(res.data);
            let data = {
                booth: res.data.booth ? res.data.booth.id : "geen",
                package: res.data.package
            };
            setCompany(res.data.company);
            setDeelnemer(data);
        });

        axios.get(config.api + "/booths?year=" + props.year).then((res) => {
            setBooths(
                res.data.sort((a, b) => {
                    return a?.number - b?.number;
                })
            );
        });
    }, []);

    function bewerk() {
        const data = deelnemer;
        delete data["name"];
        if (data["booth"] === "geen") data["booth"] = null;

        axios
            .patch(config.api + "/participants/" + props.uuid, data)
            .then((res) => {
                props.load();
                store.addNotification({
                    title: "Bewerken succesvol!",
                    message: "Bewerken van deelnemer succesvol",
                    type: "default",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
            })
            .catch((err) => {
                store.addNotification({
                    title: "Fout!",
                    message: "Er is al een deelnemer met deze standplaats!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
            });
    }

    function deleteDeelnemer() {
        if (window.confirm("Bevestig het verwijderen van deelnemer: " + company.company_name + ".")) {
            axios
                .delete(config.api + "/participants/" + props.uuid)
                .then(() => {
                    props.load();
                })
                .catch(() => {});
        }
    }

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-80 m-w-100 mr-v-10 c-gray-500">
                <h2>{company.company_name}'s deelname bewerken</h2>
                <p>Package:</p>
                <select className="text-input" name="package" onChange={(e) => handleChange(e)}>
                    <option value="base" selected={deelnemer.package === "base"}>
                        Standaard
                    </option>
                    <option value="head" selected={deelnemer.package === "head"}>
                        Hoofdsponsor
                    </option>
                    <option value="late" selected={deelnemer.package === "late"}>
                        Laatkomer
                    </option>
                </select>

                <p>Standplaats:</p>
                <select className="text-input" name="booth" onChange={(e) => handleChange(e)}>
                    <option value="geen" selected={deelnemer.booth === "geen"}>
                        Geen
                    </option>
                    {booths.map((booth) => {
                        return (
                            <option value={booth.id} selected={deelnemer.booth === booth.id}>
                                {booth.number}
                            </option>
                        );
                    })}
                </select>

                <button onClick={() => bewerk()} className="button button-m fw-700 c-white b-blue-400">
                    Bewerk
                </button>

                <div className="w-100 t-right">
                    <button onClick={() => deleteDeelnemer()} className="button button-m b-red-10 c-red t-12 border-r-10 hover hover-1 hover-b-red hover-c-white">
                        <AiOutlineDelete />
                    </button>
                </div>
            </div>
        );
}

export default M_JS_Deelnemers_Bewerken;
