import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import StageLijst from "../../components/student/stage_lijst";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../components/breadcrumb";

function P_Stages(props) {


  return (
    <div>
      <Helmet>
        <title>HR - Stages</title>
      </Helmet>
      <Navigatie type="studenten" />
    
      <div className="mr-t-30 container w-65 xl-w-80 m-w-90">
        <BreadCrumb prev={[["/student", "Home"]]} now="Stage"/>
      </div>
      <StageLijst/>
      
      <Footers type="main" />
    </div>
  );
}

export default P_Stages;
