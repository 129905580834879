import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import { useForm } from "react-hook-form";
import Modal from "../../modal";
import M_Stages_Bewerk from "./bewerk";
import { store } from "react-notifications-component";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
function M_Stages_Nieuw(props) {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [company, setCompany] = useState({});

    useEffect(() => {
        axios
            .get(config.api + "/companies")
            .then((res) => {
                let data = res.data;
                let useropt = [];
                for (let x of data) {
                    useropt.push({ value: x.id, label: x.company_name });
                }
                setCompanyOptions(useropt);
            })
            .catch((err) => {
                //console.log(err)
            });
    }, []);

    const [message, setMessage] = useState(null);

    function onSubmit(values) {
        values["type"] = "I";
        values["owner"] = company?.value;

        if (values.title === "") {
            store.addNotification({
                title: "Aanmaken mislukt!",
                message: "Titel mag niet leeg zijn",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        if (!values.owner) {
            store.addNotification({
                title: "Aanmaken mislukt!",
                message: "Bedrijf mag niet leeg zijn!",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        if (values.title.includes("/")) {
            store.addNotification({
                title: "Aanmaken mislukt!",
                message: 'Titel mag geen "/" bevatten!',
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }
        axios
            .post(config.api + "/vacancies", values)
            .then(function (response) {
                props.search();
                setMessage(
                    <>
                        <p className="c-gray-700-70">
                            Aanmaken succesvol gelukt! bewerk de stage
                            <Modal openButtonValue={"hier"} openButtonClasses="button link fw-700 c-blue-400 b-none pd-l-2">
                                <M_Stages_Bewerk search={props.search} StageID={response.data.id} />
                            </Modal>
                            .
                        </p>
                    </>
                );
            })
            .catch(function (error) {
                setMessage(<p className="c-red">Aanmaken is helaas mislukt, zorg dat alles correct is ingevuld.</p>);
            });
    }

    const { register, handleSubmit } = useForm();

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div className="container w-90 m-w-100 mr-v-10">
                {message}
                <h2 className="t-center c-gray-700-90 mr-v-5">STAGE TOEVOEGEN</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Stage titel</label>
                    <input {...register("title")} name="title" type="text" className="text-input pd-2 t-8" placeholder="Job titel" />
                    <label>Bedrijf</label>
                    <Select options={companyOptions} isSearchable components={animatedComponents} onChange={setCompany} placeholder="Kies bedrijf" className="mr-b-4 mr-t-1" />
                    <label>Stage beschrijving</label>
                    <textarea {...register("advertisement_short_desc")} className="text-input pd-2 t-8" rows="10"></textarea>
                    <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-500 mr-t-10 mr-b-2" value="AANMAKEN" />
                </form>
            </div>
        );
}

export default M_Stages_Nieuw;
