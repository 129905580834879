import React, {useRef, useState} from 'react';
import {AiOutlineClose} from 'react-icons/ai'

function Modal(props) {

    const modalRef = useRef()

    const [condition, setCondition] = useState(false);
    const closeModal = e => {
        if(modalRef.current === e.target){
            updateConditions()

        }
    }


    const updateConditions = () =>{
        setCondition(!condition);
    }

  return (
    <>
        {condition ? (
            <>
                <button onClick={() => updateConditions()} className={props.openButtonClasses}>{props.openButtonValue}</button>
                <div className="modal-main" ref={modalRef} onClick={closeModal}>
                    <div className={props.boxClasses ? props.boxClasses : "container w-65 m-w-90 s-w-98 mr-b-10 m-mr-v-10"} >
                        <div id="modal" className="box t-left modal-box border-r-20">
                            <button className="modal-close mr-4 c-gray-700-60 border-r-circle hover hover-2 hover-b-gray-300-20" onClick={() => updateConditions()}><AiOutlineClose/></button>
                            <div className="modal-content">
                                {props.children}
                                {props.hideCancel ? "" : <button onClick={() => updateConditions()} className="modal-cancel">Cancel</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <button onClick={() => updateConditions()} className={props.openButtonClasses}>{props.openButtonValue}</button>
        )}
    </>
    
  );
}


export default Modal;
