import React, { useState, useEffect } from "react";
import { config } from "../../../config";
import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Modal from "../../../components/modal";
import M_JS_Inschrijving_Bewerk from "../../../components/modals/j&s/bewerk";
import M_JS_Bedrijf_Aanmaken from "../../../components/modals/j&s/bedrijfAanmaken";
import M_Bedrijf_Bewerk from "../../../components/modals/bedrijf/bewerk";

function P_Paneel_JSInschrijvingen(props) {
	const [titel, setTitel] = useState("");
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(0);

	const [acceptedView, setAcceptedView] = useState(0);

	const [counters, setCounters] = useState({
		all: 0,
		confirmation: 0,
	});

	function handleChange(event) {
		setTitel(event.target.value);
	}

	useEffect(() => {
		itemsOphalen(config.api + "/jobfair/participations?jobfair=" + props.match.params.jaar);
	}, []);

	function itemsOphalen(getReq) {
		axios
			.get(getReq)
			.then((res) => {
				let conCounter = 0;
				let allCounter = 0;
				// eslint-disable-next-line
				res.data.map((item) => {
					if (item.confirmation) {
						conCounter++;
					}
					allCounter++;
				});
				let x = res.data;
				x.sort((a, b) => {
					let dateA = new Date(a.application_date);
					let dateB = new Date(b.application_date);
					return dateA - dateB;
				});
				//console.log(x);
				setCounters({ all: allCounter, confirmation: conCounter });
				setItems(x);
				setLoading(1);
			})
			.catch((err) => {
				//console.log(err)
			});
	}

	function _handleKeyDown(e) {
		if (e.key === "Enter") {
			Search();
		}
	}

	function Search() {
		setLoading(0);
		itemsOphalen(config.api + "/jobfair/participations?jobfair=" + props.match.params.jaar + "&contact_company_name=" + titel);
	}

	function changeClasses(id, max) {
		for (let i = 1; i <= max; i++) {
			if (i === id) {
				document.getElementById("table-" + i).classList.remove("hidden");
				document.getElementById("text-" + i).classList.remove("hidden");
				document.getElementById(i).classList.add("b-blue-400");
				document.getElementById(i).classList.add("c-white");
				document.getElementById(i).classList.remove("b-gray-700-10");
				document.getElementById(i).classList.remove("c-gray-700-80");
			} else {
				document.getElementById("table-" + i).classList.add("hidden");
				document.getElementById("text-" + i).classList.add("hidden");
				document.getElementById(i).classList.remove("b-blue-400");
				document.getElementById(i).classList.remove("c-white");
				document.getElementById(i).classList.add("b-gray-700-10");
				document.getElementById(i).classList.add("c-gray-700-80");
			}
		}
	}

	function updateView(e) {
		var id = parseInt(e.target.id);
		changeClasses(id, 3);
	}

	let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

	if (claims === null || claims.recruitment_privilege === false) {
		window.location.replace("/");
	} else
		return (
			<>
				<Helmet>
					<title>HR - Paneel - J&S Inschrijvingen</title>
				</Helmet>
				<Navigatie type="other" />

				<section className="mr-b-30 mr-t-35">
					<div className="container w-65 xl-w-80 m-w-90">
						<div className="row">
							<div className="col-12 pd-b-5">
								<div className="box">
									<p className="c-gray-700 fw-700 pd-v-3">INSCHRIJVING ZOEKEN</p>
									<input onKeyDown={_handleKeyDown} onChange={handleChange} name="search" type="text" className="text-input border-gray-100 c-blue-400 mr-b-3" placeholder="Bedrijf naam..." />
									<button onClick={Search} className="button button-m b-blue-400 c-white mr-v-2 fw-700">
										Zoek
									</button>
								</div>
							</div>
							<div>
								<button id="1" className="button button-m b-blue-400 c-white fw-700 mr-r-2" onClick={updateView}>
									Contactpersoon gegevens
								</button>
								<button id="2" className="button button-m b-gray-700-10 c-gray-700-80 fw-700 mr-r-2" onClick={updateView}>
									Facturatie gegevens
								</button>
								<button id="3" className="button button-m b-gray-700-10 c-gray-700-80 fw-700" onClick={updateView}>
									Overige gegevens
								</button>

								<h1 id="text-1" className="t-14 c-gray-500 mr-v-5">
									CONTACTPERSOON GEGEVENS
								</h1>
								<h1 id="text-2" className="t-14 c-gray-500 mr-v-5 hidden">
									FACTURATIE GEGEVENS
								</h1>
								<h1 id="text-3" className="t-14 c-gray-500 mr-v-5 hidden">
									OVERIGE GEGEVENS
								</h1>
							</div>
							<div className="col-12 m-pd-t-10">
								<div className="t-12 mr-v-5 c-gray-700-80">
									<span className="fw-700">Totaal:</span> {counters.all} <span className="fw-700 pd-l-5">Aanvaard:</span> {counters.confirmation}
								</div>
								<table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
									<thead className="c-gray-700-80">
										<tr>
											<th>Bedrijf naam</th>
											<th>
												Contact persoon <span className="c-gray-500-70">(Functie)</span>
											</th>
											<th>Contact mail</th>
											<th>Telefoonnummer</th>
											<th>Gsmnummer</th>
											<th onClick={() => (acceptedView == 2 ? setAcceptedView(0) : setAcceptedView(acceptedView + 1))} className="c-blue-400 button link">
												{acceptedView == 0 ? "Alles" : acceptedView == 1 ? <AiOutlineClose className="t-10" /> : <AiOutlineCheck className="t-10" />}
											</th>
										</tr>
									</thead>
									<tbody>
										{loading !== 0 ? (
											items.map((item, index) => (
												<>
													{(!item.confirmation && acceptedView == 1) || (item.confirmation && acceptedView == 2) || acceptedView == 0 ? (
														<tr key={item.id} className={"tr-" + (index % 2)}>
															<Modal className="t-left" openButtonValue={<td>{item.contact_company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
																<M_JS_Inschrijving_Bewerk search={Search} InschrijvingID={item.id} />
															</Modal>
															<td>
																{item.contact_firstname} {item.contact_lastname} <span className="c-gray-500-70">({item.contact_person_function})</span>
															</td>
															<td>{item.contact_mail}</td>
															<td>{item.contact_telephonenumber}</td>
															<td>{item.contact_cellphonenumber}</td>
															<td className="fw-700 t-11 t-center">{item.confirmation ? <AiOutlineCheck className="c-mint" /> : <AiOutlineClose className="c-red" />}</td>
														</tr>
													) : (
														""
													)}
												</>
											))
										) : (
											<tr>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
											</tr>
										)}
									</tbody>
								</table>
								<table id="table-2" className="t-left bedrijf-info-table hidden box-shadow-2">
									<thead className="c-gray-700-80">
										<tr>
											<th>Bedrijf naam</th>
											<th>Facturatie mail</th>
											<th>Adres</th>
											<th>BTW nummer</th>
											<th onClick={() => (acceptedView == 2 ? setAcceptedView(0) : setAcceptedView(acceptedView + 1))} className="c-blue-400 button link">
												{acceptedView == 0 ? "Alles" : acceptedView == 1 ? <AiOutlineClose className="t-10" /> : <AiOutlineCheck className="t-10" />}
											</th>
										</tr>
									</thead>
									<tbody>
										{loading !== 0 ? (
											items.map((item, index) => (
												<>
													{(!item.confirmation && acceptedView == 1) || (item.confirmation && acceptedView == 2) || acceptedView == 0 ? (
														<tr key={item.id} className={"tr-" + (index % 2)}>
															<Modal className="t-left" openButtonValue={<td>{item.contact_company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
																<M_JS_Inschrijving_Bewerk search={Search} InschrijvingID={item.id} />
															</Modal>
															<td>{item.contact_billingmail}</td>
															<td>
																{item.contact_street} {item.contact_number}, {item.contact_zipcode} {item.contact_city} ({item.contact_country})
															</td>
															<td>{item.contact_btw}</td>
															<td className="fw-700 t-11 t-center">{item.confirmation ? <AiOutlineCheck className="c-mint" /> : <AiOutlineClose className="c-red" />}</td>
														</tr>
													) : (
														""
													)}
												</>
											))
										) : (
											<tr>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
											</tr>
										)}
									</tbody>
								</table>
								<table id="table-3" className="t-left bedrijf-info-table hidden box-shadow-2">
									<thead className="c-gray-700-80">
										<tr>
											<th>Bedrijf naam</th>
											<th>Website</th>
											<th>Bedrijf aanmaken</th>
											<th onClick={() => (acceptedView == 2 ? setAcceptedView(0) : setAcceptedView(acceptedView + 1))} className="c-blue-400 button link">
												{acceptedView == 0 ? "Alles" : acceptedView == 1 ? <AiOutlineClose className="t-10" /> : <AiOutlineCheck className="t-10" />}
											</th>
										</tr>
									</thead>
									<tbody>
										{loading !== 0 ? (
											items.map((item, index) => (
												<>
													{(!item.confirmation && acceptedView == 1) || (item.confirmation && acceptedView == 2) || acceptedView == 0 ? (
														<tr key={item.id} className={"tr-" + (index % 2)}>
															<Modal className="t-left" openButtonValue={<td>{item.contact_company_name}</td>} openButtonClasses="button link c-blue-400 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
																<M_JS_Inschrijving_Bewerk search={Search} InschrijvingID={item.id} />
															</Modal>
															<td>
																<a href={item.contact_website} className="button link pd-v-0 c-mint fw-500">
																	{item.contact_website}
																</a>
															</td>

															{!item.company ? (
																<Modal className="t-left" openButtonValue={<td>Aanmaken</td>} openButtonClasses="button link c-indigo fw-500 pd-v-0 b-none pd-0 t-8 t-left">
																	<M_JS_Bedrijf_Aanmaken naam={item.contact_company_name} search={Search} InschrijvingData={item} />
																</Modal>
															) : (
																<Modal className="t-left" openButtonValue={<td>Bewerken</td>} openButtonClasses="button link c-yellow-700 fw-500 pd-v-0 b-none pd-0 t-8 t-left">
																	<M_Bedrijf_Bewerk search={Search} BedrijfID={item.company.id} />
																</Modal>
															)}

															<td className="fw-700 t-11 t-center">{item.confirmation ? <AiOutlineCheck className="c-mint" /> : <AiOutlineClose className="c-red" />}</td>
														</tr>
													) : (
														""
													)}
												</>
											))
										) : (
											<tr>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
												<td className="loading loading_form"></td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
				<Footers type="main" />
			</>
		);
}

export default P_Paneel_JSInschrijvingen;
