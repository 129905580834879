import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function P_PrivacyPolicy(props) {
	useEffect(() => {}, []);


	return (
		<div className="login h-100-vh">
			<Helmet>
				<title>HR - Privacy Policy</title>
			</Helmet>

			<div className="container w-40 l-w-60 m-w-80 s-w-98 pd-v-25 m-pd-v-10">
				<h1 className="t-center c-white mr-b-10">Privacy Policy</h1>
				<div className="box border-r-20 pd-10 m-pd-5">

                </div>
			</div>
		</div>
	);
}

export default P_PrivacyPolicy;
