import React, { useState, useEffect } from "react";

import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import { Helmet } from "react-helmet";
import axios from "axios";
import { config } from "../../../config";
function P_Paneel_Accounts_Accounts(props) {
    let interesses = {
        elektromech: "Elektromechanica",
        bouw: "Bouwkunde",
        chem: "Chemie",
        infor: "Informatica",
        eict: "Elektronica-ICT",
        auto: "Automatisering",
        land: "Landmeten",
        etech: "Elektrotechniek"
    };

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [type, setType] = useState("student");
    const [naam, setNaam] = useState("");
    const [mail, setMail] = useState("");
    const [cvFilter, setCvFilter] = useState("all");
    const [intresseFilter, setIntresseFilter] = useState("");
    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        filter(items);
    }, [type, naam, mail, cvFilter, intresseFilter]);

    function filter(data) {
        data = data.filter((x) => x.account_type === type);
        data = data.filter((x) => (x.first_name.toLocaleLowerCase() + " " + x.last_name.toLocaleLowerCase()).includes(naam.toLocaleLowerCase()));
        data = data.filter((x) => x.email.toLocaleLowerCase().includes(mail.toLocaleLowerCase()));
        if (cvFilter === "yes") {
            data = data.filter((x) => !!x.student_info?.resume);
        }
        if (cvFilter === "no") {
            data = data.filter((x) => !x.student_info?.resume);
        }

        if (type === "student") {
            data = data.filter((x) => {
                let s = "";
                for (let i of x.student_info?.interests) {
                    s += interesses[i] + ",  ";
                }
                if (s.toLocaleLowerCase().includes(intresseFilter.toLocaleLowerCase())) return true;
            });
        }

        setFilteredItems(data);
    }

    function resetFilters() {
        setCvFilter("all");
        setIntresseFilter("");
    }

    function load() {
        setLoading(true);
        axios
            .get(config.api + "/users")
            .then((res) => {
                let data = res.data;

                let pushdata = [];
                let promises = [];

                for (let user of data) {
                    if (user.account_type === "student") {
                        promises.push(
                            axios
                                .get(config.api + "/students/" + user.id)
                                .then((result) => {
                                    user = { ...user, student_info: result.data };
                                    pushdata.push(user);
                                })
                                .catch(() => {})
                        );
                    } else {
                        pushdata.push(user);
                    }
                }
                Promise.all(promises).then(() => {
                    setItems(pushdata);
                    filter(pushdata);
                    setLoading(false);
                });
            })
            .catch((err) => {});
    }

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <>
                <Helmet>
                    <title>HR - Paneel - Accounts</title>
                </Helmet>
                <Navigatie type="other" />

                <section className="mr-b-30 mr-t-35">
                    <div className="container w-65 xl-w-80 m-w-90">
                        <div className="mr-b-2">
                            <button
                                onClick={() => {
                                    resetFilters();
                                    setType("student");
                                }}
                                className={"button button-m fw-700 " + (type === "student" ? "b-black c-white hover hover-2 hover-b-black-80" : "")}>
                                Studenten
                            </button>
                            <button
                                onClick={() => {
                                    resetFilters();
                                    setType("company");
                                }}
                                className={"button button-m fw-700 mr-l-3 " + (type === "company" ? "b-black c-white hover hover-2 hover-b-black-80" : "")}>
                                Bedrijf accounts
                            </button>
                            <button
                                onClick={() => {
                                    resetFilters();
                                    setType("recruit");
                                }}
                                className={"button button-m fw-700 mr-l-3 " + (type === "recruit" ? "b-black c-white hover hover-2 hover-b-black-80" : "")}>
                                Recruitment accounts
                            </button>
                        </div>
                        <div className="flex horizontal-center">
                            <div className="mr-b-5 c-gray-500-50 fw-700 flex-grow">#{filteredItems.length}</div>
                            <button className="button link c-gray-500-40 b-none t-8" onClick={() => load()}>
                                Reload
                            </button>
                        </div>

                        {type === "student" ? (
                            <table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
                                <thead className="c-gray-700-80">
                                    <tr>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setNaam(e.target.value)} value={naam} placeholder="Naam"></input>
                                        </th>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setMail(e.target.value)} value={mail} placeholder="E-mailadres"></input>
                                        </th>

                                        <th>
                                            <input className="filter-input" onChange={(e) => setIntresseFilter(e.target.value)} value={intresseFilter} placeholder="Interesses"></input>
                                        </th>
                                        <th>
                                            <button
                                                onClick={() => {
                                                    cvFilter === "all" && setCvFilter("yes");
                                                    cvFilter === "yes" && setCvFilter("no");
                                                    cvFilter === "no" && setCvFilter("all");
                                                }}
                                                className={"button button-m c-white fw-700 " + (cvFilter === "all" ? "b-black" : cvFilter === "yes" ? "b-mint" : "b-red")}>
                                                CV
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ? (
                                        <>
                                            {filteredItems.map((item, index) => (
                                                <tr key={item.id} className={"tr-" + (index % 2)}>
                                                    <td className={item.verified ? "c-mint" : "c-red"}>
                                                        {item.first_name} {item.last_name}
                                                    </td>
                                                    <td className="c-gray-500-80">{item.email}</td>

                                                    <td>
                                                        {item.student_info?.interests.map((interesse, index) => {
                                                            return (
                                                                <span key={interesse} className="c-gray-500-80 t-7">
                                                                    {interesses[interesse]}
                                                                    {index + 1 < item.student_info.interests.length ? ",  " : ""}
                                                                </span>
                                                            );
                                                        })}
                                                    </td>
                                                    <td>
                                                        {item.student_info?.resume ? (
                                                            <a href={item.student_info?.resume} target="_blank" className="button link c-blue-400">
                                                                Bekijk
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td className="loading loading_form"></td>
                                            <td className="loading loading_form"></td>
                                            <td className="loading loading_form"></td>
                                            <td className="loading loading_form"></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <></>
                        )}

                        {type === "company" ? (
                            <table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
                                <thead className="c-gray-700-80">
                                    <tr>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setNaam(e.target.value)} value={naam} placeholder="Naam"></input>
                                        </th>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setMail(e.target.value)} value={mail} placeholder="E-mailadres"></input>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ? (
                                        <>
                                            {filteredItems.map((item, index) => (
                                                <tr key={item.id} className={"tr-" + (index % 2)}>
                                                    <td className={item.verified ? "c-mint" : "c-red"}>
                                                        {item.first_name} {item.last_name}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td className="loading loading_form"></td>
                                            <td className="loading loading_form"></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <></>
                        )}
                        {type === "recruit" ? (
                            <table id="table-1" className="t-left bedrijf-info-table box-shadow-2">
                                <thead className="c-gray-700-80">
                                    <tr>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setNaam(e.target.value)} value={naam} placeholder="Naam"></input>
                                        </th>
                                        <th>
                                            <input className="filter-input" onChange={(e) => setMail(e.target.value)} value={mail} placeholder="E-mailadres"></input>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ? (
                                        <>
                                            {filteredItems.map((item, index) => (
                                                <tr key={item.id} className={"tr-" + (index % 2)}>
                                                    <td className={item.verified ? "c-mint" : "c-red"}>
                                                        {item.first_name} {item.last_name}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td className="loading loading_form"></td>
                                            <td className="loading loading_form"></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <></>
                        )}
                    </div>
                </section>
                <Footers type="main" />
            </>
        );
}

export default P_Paneel_Accounts_Accounts;
